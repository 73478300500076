import React, {Component} from 'react';
import axios from "axios";
import trash from "../../../../assets/logos/trash.svg";
import eye from "../../../../assets/logos/eye.svg";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

const trashStyle = {
    width: 'auto',
    height: '20px',
    cursor: "pointer",
};

export default class Videos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            placeId: this.props.placeId,
            user: this.props.user,
            fondateur: this.props.fondateur,
            videos: [],
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        let url = import.meta.env.VITE_BACKEND_URL + "/places/videos";
        axios.post(url, {
            places_id: this.state.placeId,
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            console.log(res.data);
            this.setState({videos: res.data});
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }


    render() {
        return (
            <div className="list_video">
                {
                    this.state.videos[0] ?
                        this.state.videos.map((value, index) => (
                            <div className="block_video" key={index}>
                                <div className="img">
                                    <a href={`/video/${value.token}`}>
                                        <img src={import.meta.env.VITE_BACKEND_URL_IMG + value.miniature}
                                             alt=""/>
                                        <p className="time">{value.time}</p>

                                    </a>
                                </div>
                                <div className="info_video">
                                    <p className="title">{value.title}</p>
                                    <p className="creator">{value.user.nom_artiste}</p>
                                    <p className="view"><img src={eye} alt=""/> 200</p>
                                </div>
                            </div>
                        ))
                        : <div className="loading-wave2">
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                        </div>
                }
            </div>
        )
    }
}