import React from 'react';
import axios from "axios";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import VideoPlayerFull from "../modules/VideoPlayerFull.jsx";
import VideoPlayer from "../modules/VideoPlayer.jsx";
import "video.js/dist/video-js.css";
import SmallModal from "../modules/SmallModal.jsx";

import en from "../../../locales/en/translation.json";
import fr from "../../../locales/fr/translation.json";

export default class Shuffle extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            stockVideo: null,
            videos: [],
            videosToBuy: [],
            videosBought: [],
            playVideoId: 0,
            user: null,
            page: 1,
            full: false,
            indexVideo: 0,
            currentTime: 0,
            openModalBug: false,
            openModalContenu: false,
            openModal: false,
            openModalCom: false,
            openModalBook: false,
            openModalFollow: false,
            currentIdVideo: null,
            flashMessage: "",
            send: null,
            lang: 'en',
            trad: en,
            name:'',
        }
    }
    componentDidMount(){

        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
                let id = location.pathname.split('/');
                let url = import.meta.env.VITE_BACKEND_URL + "/playlistvideo/shuffle/"+ id[2];
                let artistInfos = [];
                axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + this.state.user.token
                    }
                }).then(res => {
                    console.log(res.data);
                    this.setState({
                        name:res.data.user.name
                    });
                    res.data.videos.map((value, index) => {
                        let video = {};
                        let mini = value.miniature;
                        video.id = value.id;
                        video.isPlay = index == 0 ? true : false;
                        video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
                        video.type = "video/mp4";
                        if (value.miniature != null) {
                            video.poster = import.meta.env.VITE_BACKEND_URL_IMG + mini;
                        }
                        video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
                        video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
                        video.artistTitle = value.utilisateur.nom_artiste;
                        video.artistDescription = value.utilisateur.biographie;
                        video.artistMoreDetails = "Plus d’informations…";
                        video.worldArtiste = value.info && value.info.upload_artistWord ? value.info.upload_artistWord.replace(/\n/g, '<br />') : "";
                        video.otherInfo = value.info && value.info.upload_otherInfos ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
                        video.droit = value.droits ? value.droits : [];
                        video.hasLiked = value.hasLiked;
                        video.hasDisLiked = value.hasDisLiked;
                        video.isLiked = value.isLiked;
                        video.title = value.title;
                        video.isCommented = value.isCom;
                        video.isBoorkmarked = value.book;
                        video.isBooked = value.booked;
                        video.isFollowed = value.share;
                        video.genreIcon = [];
                        video.token = value.token;
                        video.userId = value.utilisateur_id;
                        //video.videosToBuy = []
                        video.videosToBuy = [
                            {startTime: 0, endTime: 5, label: this.state.trad.pDescriptionPlaces20},
                            {startTime: 20, endTime: 25, label: this.state.trad.pDescriptionPlaces20},
                            {startTime: 50, endTime: 55, label: this.state.trad.pDescriptionPlaces20},
                        ];
                        //video.videosBought = []
                        video.videosBought = [
                            {startTime: 40, endTime: 45, label: this.state.trad.pDescriptionPlaces21},
                            {startTime: 130, endTime: 135, label: this.state.trad.pDescriptionPlaces21},
                        ];
                        artistInfos.push(video);
                    });
                    this.setState({
                        videos: artistInfos,
                    },() => {
                        document.querySelector("body").classList.add("shuffle");
                        document.title = this.state.trad.shuffleText + " " + this.state.name + " | Wonderplace";
                    });
                }).catch(err => {
                    console.log(err);
                })
            });

        }else{
            location.assign('/');
        }

    }
    handleCallBackPlay = (id) => {
        const {videos} = this.state;
        const updatedVideos = videos.map((value, index) => ({
            ...value,
            isPlay: index === id,
        }));
        this.setState({videos: updatedVideos});
    };
    handleCallBackFullScreen = (value, indexVideo, time, type = null) => {
        console.log(type);
        if (value) {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)});
        } else {
            if (type === "like") {
                this.setState({openModal: true}, () => {
                    this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)}, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                });
            } else if (type === "comm") {
                this.setState({openModalCom: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentIdVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }else if (type === "book") {
                this.setState({openModalBook: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentIdVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }else if (type === "follow") {
                this.setState({openModalFollow: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentIdVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }
        }
    }
    handleFullScreenChangeVideo = (index) => {
        this.setState({indexVideo: index});
    }


    callBackFreshPage = () => {
        window.location.reload(true);
    }
    handleSendBug = (type, id) => {
        let send = [];
        if (type === "bug") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: false, openModalBug: true});
        } else if (type === "contenu") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: true, openModalBug: false});
        }
        if (type === "bug") {
            this.setState({send, currentIdVideo: id, openModalBug: true});
        } else {
            this.setState({send, currentIdVideo: id, openModalContenu: true});
        }
    }
    callbackSend = (message) => {
        const {send, user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/send";
        axios.post(url, {
            send,
            message: message,
            user
        }).then(res => {
            if (res.data) {
                this.handleCloseModal();
                this.setState({
                    typeFlash: 'success',
                    flashMessage: this.state.trad.messageCompte1,
                    send: null
                }, () => {
                    setTimeout(() => {
                        this.setState({flashMessage: ""})
                    }, 3000)
                });
            }
        }).catch(err => {
            this.setState({
                openModalContenu: false,
                openModalBug: false,
                typeFlash: 'warning',
                flashMessage: this.state.trad.messageCompte2
            }, () => {
                setTimeout(() => {
                    this.setState({flashMessage: ""})
                }, 3000)
            });
        })
    }
    handleCloseModal = () => {
        this.setState({send: null, currentIdVideo: null, openModalBug: false, openModalContenu: false});
    }
    callbackIndexVideo = (indexVideo) => {}
    render(){
        return(
            <>
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                <h1>{this.state.trad.shuffleText} {this.state.name}</h1>
                <div className="content">
                    <div className="img_cover"></div>
                    <div className={`home homepageGlobal ${this.state.videos[0] ? '' : 'empty'}`}>
                        {this.state.full ?
                            <div className="fullscreen">
                                <VideoPlayerFull
                                    indexVideo={this.state.indexVideo}
                                    id={this.state.indexVideo}
                                    key={this.state.indexVideo}
                                    video={this.state.videos[this.state.indexVideo]}
                                    infos={false}
                                    user={this.state.user}
                                    handleCallBackPlay={this.handleCallBackPlay}
                                    handleCallBackFullScreen={this.handleCallBackFullScreen}
                                    handleFullScreenChangeVideo={this.handleFullScreenChangeVideo}
                                    currentTime={this.state.currentTime}
                                />
                            </div>
                            :
                            this.state.videos[0] ?
                                this.state.videos.map((value, index) => (
                                    <VideoPlayer
                                        indexVideo={index}
                                        id={`video_${index}`}
                                        key={index}
                                        video={value}
                                        videosToBuy={value}
                                        videosBought={value}
                                        infos={false}
                                        handleCallBackPlay={this.handleCallBackPlay}
                                        handleCallBackFullScreen={this.handleCallBackFullScreen}
                                        currentTime={this.state.currentTime}
                                        callbackIndexVideo={this.callbackIndexVideo}
                                        callBackFreshPage={this.callBackFreshPage}
                                        handleSendBug={this.handleSendBug}
                                        user={this.state.user}
                                        full={this.state.full}
                                        trad={this.state.trad}
                                        openModal={this.state.openModal}
                                        openModalCom={
                                            this.state.currentIdVideo === null ?
                                                this.state.openModalCom
                                                :
                                                this.state.currentIdVideo === index ?
                                                    this.state.openModalCom : false
                                        }
                                        openModalBook={this.state.currentIdVideo === null ? this.state.openModalBook :
                                            this.state.currentIdVideo === index ?
                                                this.state.openModalBook : false}
                                        openModalFollow={this.state.currentIdVideo === null ? this.state.openModalFollow :
                                            this.state.currentIdVideo === index ?
                                                this.state.openModalFollow : false}
                                    />
                                ))
                                :
                                <Box sx={{display: 'flex'}}>
                                    <CircularProgress/>
                                </Box>
                        }
                    </div>
                </div>
                {
                    this.state.openModalBug &&
                    <SmallModal title={this.state.trad.pDescriptionUtilisateur3}
                                label={this.state.trad.pDescriptionUtilisateur9}
                                handleCloseModal={this.handleCloseModal}
                                callbackSend={this.callbackSend}/>
                }
                {
                    this.state.openModalContenu &&
                    <SmallModal title={this.state.trad.pDescriptionUtilisateur10}
                                label={this.state.trad.pDescriptionUtilisateur11}
                                handleCloseModal={this.handleCloseModal} callbackSend={this.callbackSend}/>
                }
            </>
        )
    }
}