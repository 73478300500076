import React from "react";
import continuerButton from "../../../../assets/logos/continuerButton.svg";
import continuerButtonen from "../../../../assets/logos/en/continuerButtonen.svg";
import axios from "axios";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';


export default class EmailForgotPasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            password: "",
            confirme: "",
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        let token = location.pathname.split('/');
        token = token[2];
        this.setState({token});
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleInscription = () => {
        const {password,confirme} = this.state;
        if (
            password === confirme &&
            password !== "" &&
            confirme !== ""
        ) {
            if (password.length > 7) {
                let url = import.meta.env.VITE_BACKEND_URL + "/simple/newPassword"
                axios.post(url,{
                    token_forgot:this.state.token,
                    password,
                }).then(res => {
                    this.setState({
                        messageModal: this.state.trad.messageForgetPassword2,
                        openModal: true,
                    });
                }).catch(err => {
                    this.setState({
                        messageModal: this.state.trad.messageForgetPassword3,
                        openModal: true,
                    });
                })
            }else{
                this.setState({
                    messageModal: this.state.trad.messageForgetPassword4,
                    openModal: true,
                });
            }
        }else{
            this.setState({
                messageModal: this.state.trad.messageForgetPassword5,
                openModal: true,
            });
        }
    }
    handleCloseModal = () => {
        location.assign('/?login=false');
    }
    render() {
        return (
            <>
                {this.state.openModal && (
                    <div className="modal">
                        <div className="block mini">
                            <p>{this.state.messageModal}</p>
                            <a href="" onClick={(e) => this.handleCloseModal(e)}>
                                OK
                            </a>
                        </div>
                    </div>
                )}

            <div className="inscritionNextParts">
                <div className="inscritionNextPart_1">
                    <h1>{this.state.trad.passwordForget3}</h1>
                    <br/>
                    <div className="nameBlock">
                        <label htmlFor="userName">{this.state.trad.passwordForget4}</label>
                        <input name="password" type="password"
                               onChange={(e) => this.setState({password: e.target.value})}/>
                    </div>
                    <div className="nameBlock">
                        <label htmlFor="userName">{this.state.trad.passwordForget5}</label>
                        <input name="confirme" type="password"
                               onChange={(e) => this.setState({confirme: e.target.value})}/>
                    </div>
                    <div className="form_submit">
                        <img
                            src={this.state.lang === "fr" ? continuerButton : continuerButtonen}
                            alt="continuer"
                            className="continuer"
                            onClick={this.handleInscription}
                        />
                    </div>
                </div>
            </div>
            </>
        )
    }

}