import React from 'react';
import crossIcon from "../../../assets/logos/crossIcon.svg";
import loupe from "../../../assets/logos/loupe.svg";
import axios from "axios";
import poster from "../../../assets/logos/ajouter_btn.svg";
import ajouter_btn from "../../../assets/logos/en/ajouter_btn.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
export default class Invite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user:this.props.user,
            inviteUser:null,
            selected:[],
            placeID:this.props.placeId,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        //invite
        let url = import.meta.env.VITE_BACKEND_URL + "/places/invite";
        axios.get(url,{
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            this.setState({inviteUser: res.data});
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleSearch = async (value,type) => {
        console.log(value,type);
        let tab = [];
        if(type === "name"){
            let select = document.getElementById('searchSelect');
            let creator = select.value;

            tab.push(value);
            tab.push(creator);
        }else{
            let text = document.getElementById('searchText');
            let name = text.value !== null ? text.value : "" ;
            tab.push(name);
            tab.push(value);
        }
        let url = import.meta.env.VITE_BACKEND_URL + "/places/searchInvite";
        axios.post(url,{
            name:tab[0],
            creator:tab[1],
        },{
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            this.setState({inviteUser: res.data});
        }).catch(err => {
            console.log(err);
        })
    }

    handlePost = () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/invite/place";
        axios.post(url,{
            selected:this.state.selected,
            places_id:this.state.placeID,
        },{
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            if(res.data){
                this.props.handleCloseMenu();
            }
        }).catch(err => {
            console.log(err);
        })
    }
    handleSelectInvite = (index,id) => {
        let {selected,inviteUser} = this.state;
        let test = selected.filter(sel => sel.id === id);
        if(test[0]){
            selected = selected.filter(sel => sel.id !== id);
            this.setState({selected});
        }else{
            selected.push(inviteUser[index]);
            this.setState({selected});
        }
    }

    render() {
        return (
            <div className="modal invite">
                <div className="block">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseMenu}
                        />
                    </div>
                    <h2>{this.state.trad.titleInvite}</h2>
                    <div className="form_connexion">
                        <div className="search">
                            <div className="form_control" onChange={(e) => this.handleSearch(e.target.value,'name') }>
                                <span className="at">@</span>
                                <input id="searchText" type="text"/>
                                <img src={loupe} alt=""/>
                            </div>
                            <div className="form_control">
                                <select name="" id="searchSelect" onChange={(e) => this.handleSearch(e.target.value,'creator') }>
                                    <option value="tous">{this.state.trad.labelFollow5}</option>
                                    <option value="auditor">{this.state.trad.pDescriptionUtilisateur7}</option>
                                    <option value="creator">{this.state.trad.creator}</option>
                                </select>
                            </div>
                        </div>
                        <div className="liste">
                            {
                                this.state.inviteUser !== null &&
                                this.state.inviteUser.map((value,index) => {
                                    let isSelected = this.state.selected.some(sel => sel.id === value.id);
                                    return (
                                        <div key={index} className={isSelected ? 'selectedClass line' : 'line'}
                                             onClick={() => this.handleSelectInvite(index, value.id)}>
                                            <div className="left">
                                                <img src={`${import.meta.env.VITE_BACKEND_URL_IMG}${value.perso.avatar}`}
                                                     alt=""/>
                                            </div>
                                            <div className="right">
                                                <p>{value.perso.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? poster : ajouter_btn}
                                alt="se connecter"
                                className="seconnecter"
                                onClick={this.handlePost}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}