import React from "react";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import axios from "axios";

import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "../modules/MuiSwitch";

import CropperAdv from "../modules/CropperAdv";

import poster from "../../../assets/logos/ajouter_btn.svg";
import ajouter_btn_en from "../../../assets/logos/en/ajouter_btn.svg";
import searchPlaces from '../../../assets/images/5.png';

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class AddPlaces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPlace:this.props.currentPlace,
            private_place:false,
            description:"",
            name:"",
            city:"",
            country:"",
            openCropp:false,
            imgPlaces:"",
            message:"",
            user:this.props.user,
            single:this.props.single ? this.props.single : false,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        const {currentPlace} = this.state;
        if(currentPlace !== null){
            this.setState({
                description:currentPlace.description,
                name:currentPlace.name,
                city:currentPlace.city,
                country:currentPlace.country,
                imgPlaces: currentPlace.image_place,
                private_place:currentPlace.private !== 0 ,
            })
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleChangePrivatePlace = () => {
        this.setState({private_place: !this.state.private_place})
    }
    closeModalCropp = () => {
        this.setState({openCropp:false});
    }
    handleOpenImgPlaces = () => {
        this.setState({openCropp:true});

    }
    callBackCropper = (base64Img, index) => {
        if (index === 2) {
            this.setState({imgPlaces: base64Img,}, () => {
                this.closeModalCropp();
            });
        }
    };
    handlePost = () => {
        const {private_place,description,name,country,city,imgPlaces,currentPlace,single} = this.state;
        if(description != ""){
            if(name !== ""){
                if(country !== ""){
                    if(city !== ""){
                        const url = import.meta.env.VITE_BACKEND_URL + "/places/add";
                        if(currentPlace === null){
                            axios.post(url,{
                                name:name,
                                description:description,
                                city:city,
                                country:country,
                                private:private_place,
                                image_place:imgPlaces,
                            },{
                                headers: {
                                    Authorization: "Bearer " + this.state.user.token,
                                },
                            }).then(res => {
                                this.props.callbackAddPlaces(res.data);
                            }).catch(err => {
                                console.log(err)
                            })
                        }else{
                            axios.post(url,{
                                name:name,
                                description:description,
                                city:city,
                                country:country,
                                private:private_place,
                                image_place:imgPlaces,
                                currentPlace:currentPlace,
                                single:single,
                            },{
                                headers: {
                                    Authorization: "Bearer " + this.state.user.token,
                                },
                            }).then(res => {
                                this.props.callbackAddPlaces(res.data);
                            }).catch(err => {
                                console.log(err)
                            })
                        }
                    }else{
                        this.setState({message:this.state.trad.placeMessage})
                    }
                }else{
                    this.setState({message:this.state.trad.placeMessage1})
                }
            }else{
                this.setState({message:this.state.trad.placeMessage2})
            }
        }else{
            this.setState({message:this.state.trad.placeMessage3})
        }
    }

    render() {
        return (
            <div className="modal heigth_large">
                <div className="block">
                    {this.state.openCropp && (
                        <div className="modal">
                            <CropperAdv
                                ratio={16/9}
                                index={2}
                                closeModalCropp={this.closeModalCropp}
                                callBackCropper={this.callBackCropper}
                            />
                        </div>
                    )}
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    {this.state.message !== "" && (
                        <div className={`message ${this.state.style}`}>
                            <p>{this.state.message}</p>
                        </div>
                    )}
                    <h2>Ajouter OU modifier une plaCE</h2>
                    <div className="form_connexion">
                        <div className="form_control">
                            <label htmlFor="name">{this.state.trad.namePlace}</label>
                            <input type="text" name="name" value={this.state.name} onChange={(e) => this.setState({name:e.target.value})}/>
                        </div>
                        <div className="form_control">
                            <label htmlFor="description">{this.state.trad.descriptionPlace}</label>
                            <textarea name="description" defaultValue={this.state.description} onChange={(e) => this.setState({description: e.target.value})}></textarea>
                        </div>
                        <div className="form_control switch_block">
                            <FormControlLabel
                                control={
                                    <MuiSwitch
                                        size="small"
                                        checked={this.state.private_place}
                                        onChange={this.handleChangePrivatePlace}
                                        name="private_account"
                                    />
                                }
                                label={"Mettre la place en privé"}
                            />
                        </div>
                        <div className="form_control empty"></div>
                        <div className="form_control">
                            <label htmlFor="country">{this.state.trad.labelCountry}</label>
                            <input type="text" name="country" value={this.state.country} onChange={(e) => this.setState({country:e.target.value})}/>
                        </div>
                        <div className="form_control">
                            <label htmlFor="country">{this.state.trad.labelCity}</label>
                            <input type="text" name="city" value={this.state.city} onChange={(e) => this.setState({city:e.target.value})}/>
                        </div>

                        <div className="form_control">
                            <div className="notif">

                                <div className="avatar">
                                    <div className="imgAvatar" onClick={this.handleOpenImgPlaces}>

                                        <img
                                            src={
                                                this.state.imgPlaces !== "" ? this.state.imgPlaces !== null ? import.meta.env.VITE_BACKEND_URL_IMG + this.state.imgPlaces :  searchPlaces:  searchPlaces
                                            }
                                            id="imgPreview2"
                                            alt=""
                                        />
                                        <p className="plusAvatar">+</p>

                                    </div>
                                    <p className="avatarText">{this.state.trad.pAddPlace} </p>
                                    <input
                                        type="hidden"
                                        id="imageBase2"
                                        value={this.state.imgPlaces || ""}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? poster : ajouter_btn_en}
                                alt="se connecter"
                                className="seconnecter"
                                onClick={this.handlePost}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}