import React from 'react';
import axios from "axios";

import pin from '../../../assets/logos/pin.svg';
import dot from '../../../assets/logos/dot_horizontal.svg';
import eye from '../../../assets/logos/eye.svg';
import vignette1 from "../../../assets/images/vignette1.jpg";
import vigentteCreator from "../../../assets/images/2.png";
import vigenttePlace from "../../../assets/images/1.png";
import vigenttePlay from "../../../assets/images/5.png";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
export default class EpingleUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            epingles: this.props.epingles,
            loaded: false,
            myPage: this.props.myPage !== undefined ? this.props.myPage : false,
            user:this.props.user,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.epingles !== this.props.epingles) {
            this.setState({
                epingles: this.props.epingles
            });
        }
    }

    handleOpenMenuDot = (index) => {
        let id = `menu_${index}`;
        let menu = document.getElementById(id);
        if (menu.style.display === "block") {
            menu.style.display = "none";
        } else {
            menu.style.display = "block";
        }
    }

    handleCallBackLaunch = (type, action, index) => {
        if (action === "detache") {
            this.detacheEpingle(index);
        }else if(action === "disliker"){
            this.dislikeEpingle(index);
        }else if(action === "liker"){
            this.likeEpingle(index);
        } else {
            this.props.callBackEpingle(type, action, index);
        }
    }
    dislikeEpingle = (index) => {
        let url = import.meta.env.VITE_BACKEND_URL + "/like/dislikevideo";
        let id = this.state.epingles[index].video.id;
        axios.post(url, {
            id,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            // PREVOIR UN RETOUR VISUEL
        }).catch(err => {
            console.log(err);
        })
    }
    likeEpingle = (index) => {
        let url = import.meta.env.VITE_BACKEND_URL + "/like/likevideo";
        let id = this.state.epingles[index].video.id;
        axios.post(url, {
            id,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            // PREVOIR UN RETOUR VISUEL
        }).catch(err => {
            console.log(err);
        })
    }
    detacheEpingle = (index) => {
        const url = import.meta.env.VITE_BACKEND_URL + "/epingle/remove";
        const epingle = this.state.epingles[index];
        axios.post(url, {
            id: epingle.id
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            this.setState({epingles: res.data}, () => {
                console.log(this.state.epingles);
            });
        }).catch(err => {
            console.log(err);
        })
    }


    render() {
        return (
            <div className="epingle">
                <h2><img src={pin} alt=""/>Épinglé</h2>
                <div className="line">
                    {
                        this.state.epingles.map((value, index) => (
                            value.type === "places" ?
                                <div className={`places`} key={index}>
                                    <div className="menu">
                                        <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                        <ul id={`menu_${index}`}>
                                            {
                                                this.state.myPage &&
                                                <li onClick={() => this.handleCallBackLaunch("places", "detache", index)}>Détacher
                                                    du profil
                                                </li>
                                            }
                                            <li onClick={() => this.handleCallBackLaunch("epingle", "bug", index)}>{this.state.trad.pDescriptionUtilisateur3}</li>
                                            <li onClick={() => this.handleCallBackLaunch("epingle", "contenu", index)}>{this.state.trad.pDescriptionUtilisateur4}</li>

                                            {
                                                !this.state.myPage &&
                                                <>
                                                    <li onClick={() => this.handleCallBackLaunch("epingle", "save", index)}>{this.state.trad.pDescriptionUtilisateur5}</li>
                                                </>
                                            }
                                            <li onClick={() => this.handleCallBackLaunch("epingle", "share", index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                        </ul>
                                    </div>
                                    <div className="img">
                                    <img
                                            src={value.places.image_place !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.places.image_place : vigenttePlace}
                                            alt=""/>
                                        <img className="mini_img" src={pin} alt=""/>
                                    </div>
                                    <div className="text">
                                        <a href={`/place/${value.places.id}`}>
                                            <p>{value.places.name} {value.places.city}, {value.places.country}</p>
                                            <p>{this.state.trad.pDescriptionPlaces18} {value.user.name}</p>
                                        </a>
                                    </div>
                                </div>
                                :
                                value.type === "playlists" ?
                                    <div className={`playlists`} key={index}>
                                        <div className="menu">
                                            <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                            <ul id={`menu_${index}`}>
                                                {
                                                    this.state.myPage &&
                                                    <li onClick={() => this.handleCallBackLaunch("playlists", "detache", index)}>Détacher
                                                        du profil
                                                    </li>
                                                }
                                                <li onClick={() => this.handleCallBackLaunch("bug", index)}>{this.state.trad.pDescriptionUtilisateur3}</li>
                                                <li onClick={() => this.handleCallBackLaunch("contenu", index)}>{this.state.trad.pDescriptionUtilisateur4}</li>

                                                {
                                                    !this.state.myPage &&
                                                    <>
                                                        <li onClick={() => this.handleEpinglePlace("save", index)}>{this.state.trad.pDescriptionUtilisateur5}</li>


                                                    </>
                                                }
                                                <li onClick={() => this.handleEpinglePlace("share", index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                            </ul>
                                        </div>
                                        <div className="img">
                                        <img
                                                src={value.playlists.image !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.playlists.image : vigenttePlay}
                                                alt=""/>
                                            <img className="mini_img" src={pin} alt=""/>
                                        </div>
                                        <div className="text">
                                            <a href={`/playlist/${value.playlists.id}`}>
                                                <p>{value.playlists.name}</p>
                                                <p>{this.state.trad.pDescriptionPlaces18} {value.user.name}</p>
                                            </a>
                                        </div>
                                    </div>
                                    :
                                    value.type === "creators" ?

                                        <div className={`creators`} key={index}>
                                            <div className="menu">
                                                <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                                <ul id={`menu_${index}`}>
                                                    {
                                                        this.state.myPage &&
                                                        <li onClick={() => this.handleCallBackLaunch("creators", "epingle", "detache", index)}>Détacher
                                                            du profil
                                                        </li>
                                                    }
                                                    <li onClick={() => this.handleCallBackLaunch("bug", index)}>{this.state.trad.pDescriptionUtilisateur3}</li>
                                                    <li onClick={() => this.handleCallBackLaunch("contenu", index)}>{this.state.trad.pDescriptionUtilisateur4}</li>
                                                    {
                                                        !this.state.myPage &&
                                                        <>
                                                            <li onClick={() => this.handleEpinglePlace("save", index)}>{this.state.trad.pDescriptionUtilisateur5}</li>
                                                        </>
                                                    }
                                                    <li onClick={() => this.handleEpinglePlace("share", index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                                </ul>
                                            </div>
                                            <div className="img">
                                                <img
                                                    src={value.user.personal.avatar !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.user.personal.avatar : vigentteCreator}
                                                    alt=""/>
                                                <img className="mini_img" src={pin} alt=""/>
                                            </div>
                                            <a href={`/page-de-profil/${value.user.id}`}>
                                                <div className="text">
                                                    <p>{value.user.nom_artiste}</p>
                                                </div>
                                            </a>
                                        </div>
                                        :
                                        value.type === "videos" &&
                                        <div className={`videos`} key={index}>
                                            <div className="menu">
                                                <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                                <ul id={`menu_${index}`}>
                                                    {
                                                        this.state.myPage &&
                                                        <li onClick={() => this.handleCallBackLaunch("videos", "detache", index)}>Détacher
                                                            du profil
                                                        </li>
                                                    }
                                                    <li onClick={() => this.handleCallBackLaunch("bug", index)}>{this.state.trad.pDescriptionUtilisateur3}</li>

                                                    <li onClick={() => this.handleCallBackLaunch("contenu", index)}>{this.state.trad.pDescriptionUtilisateur4}</li>
                                                    <li onClick={() => this.handleCallBackLaunch("videos", "liker", index)}>liker</li>
                                                    <li onClick={() => this.handleCallBackLaunch("videos", "disliker", index)}>Disliker</li>
                                                    {
                                                        !this.state.myPage &&
                                                        <>
                                                            <li onClick={() => this.handleEpinglePlace("save", index)}>{this.state.trad.pDescriptionUtilisateur5}</li>
                                                        </>
                                                    }
                                                    <li onClick={() => this.handleEpinglePlace("share", index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                                </ul>
                                            </div>
                                            <div className="img">
                                                <img
                                                    src={value.video.miniature !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.video.miniature : vignette1}
                                                    alt=""/>
                                                <img className="mini_img" src={pin} alt=""/>

                                                <p className="time">{value.video.time}</p>
                                            </div>
                                            <div className="text">
                                                <p>{value.video.title}</p>
                                                <p>{value.user.nom_artiste}</p>
                                                <div className="like">
                                                    <img src={eye} alt=""/><span>{value.like}</span>
                                                </div>
                                            </div>
                                        </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}