import React from "react";
import {Link} from "react-router-dom";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import seconnecter from "../../../assets/logos/seconnecter.svg";
import connexionButtonen from "../../../assets/logos/en/connexionButtonen.svg"
import axios from "axios";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class Connexion extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            email:"",
            password:"",
            reste:false,
            message: this.props.message ? this.props.message : "" ,
            style: this.props.styleMessage ? this.props.styleMessage : "",
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
        setTimeout(() =>  {
            this.setState({message:null,style:null});
        },2000)
    }

    handleConnexion = async () => {
        const {email, password, reste} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/connexion";
        axios
            .post(url, {
                email,
                password,
                reste,
            })
            .then((res) => {
                if (res.data.message) {
                    this.setState({
                        message: res.data.message,
                        style: "warning",
                    });
                } else {
                    this.setState({user: res.data}, () => {
                        localStorage.setItem("user", JSON.stringify(res.data));
                        this.props.handleCloseModal();
                        let tab = location.pathname.split('/');
                        if(tab[1] === "acceptation" && tab[2] === "video"){
                            location.reload();
                        }else{
                            let link = localStorage.getItem('link')
                            if(link !== null){
                                location.assign(link);
                                localStorage.removeItem("link");
                            }else{
                                location.assign("/");
                            }
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    handleForgotPassword = () => {
        this.props.handleCloseModal;
        location.assign("/mot-de-passe-oublie");
    }

    render(){
        return(
            <div className="modal">
                <div className="block">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    {this.state.message !== "" && (
                        <div className={`message ${this.state.style}`}>
                            <p>{this.state.message}</p>
                        </div>
                    )}
                    <h2>{this.state.trad.headerNavSub9}</h2>
                    <Link
                        to="/inscription"
                        className="inscrire"
                        onClick={this.props.handleCloseModal}
                    >
                        {this.state.trad.pInscription}
                    </Link>
                    <div className="form_connexion">
                        <div className="form_control">
                            <label htmlFor="email">{this.state.trad.passwordForget2}</label>
                            <input
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                            />
                        </div>
                        <div className="form_control">
                            <label htmlFor="password">{this.state.trad.passwordForget4}</label>
                            <input
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={(e) =>
                                    this.setState({password: e.target.value})
                                }
                            />
                        </div>
                        <div className="form_control">
                            <p onClick={this.handleForgotPassword}>{this.state.trad.forgotPassword}</p>
                        </div>
                        <div className="form_control">
                            <input
                                type="checkbox"
                                name="reste"
                                value={this.state.reste}
                                onChange={(e) => this.setState({reste: e.target.checked})}
                            />
                            <span>{this.state.trad.pStayConnected}</span>
                        </div>
                        {/*
                            <div className="wallet_connexion">
                                <p>Connectez votre portefeuille</p>
                            </div>
                        */}
                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? seconnecter : connexionButtonen}
                                alt="se connecter"
                                className="seconnecter"
                                onClick={this.handleConnexion}
                            />
                        </div>
                        <div className="passModal">
                            <p onClick={this.props.handleCloseModal}>{this.state.trad.pPassLogin}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}