import React from 'react';
import axios from "axios";

import addPlalist from "../../../../assets/logos/addPlaylist.svg";
import cadena from "../../../../assets/logos/cadena.svg";
import ajouter_btn from "../../../../assets/logos/ajouter_btn.svg";
import playlistDefault from "../../../../assets/images/5.png";
import playlistReposte from "../../../../assets/images/2.png";
import AddPlaylist from "../../modules/AddPlaylist.jsx";
import EditPlaylist from "../../modules/EditPlaylist.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class Playlist extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            openAddPlaylist:false,
            playlists:[],
            user:this.props.user,
            currentPlaylist:null,
            openEdit:false,
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
        if(this.state.user == null){
            location.assign('/');
        }else{
            const url = import.meta.env.VITE_BACKEND_URL + "/playlist/get";
            axios.get(url,{
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                },
            }).then(res => {
                this.setState({playlists: res.data});
            }).catch(err => {
                console.log(err);
            })
        }
    }
    handleOpenAddPlaylist = () => {
        this.setState({openAddPlaylist: true});
    }
    handleCloseModal = () =>{
        this.setState({openAddPlaylist: false,openEdit:false});
    }
    handleAddPlaylist = (playlists) => {
        this.setState({playlists:playlists,openEdit: false,openAddPlaylist: false});
    }
    handleOpenModifierPlaylist = (index) => {
        const {playlists} = this.state;
        this.setState({currentPlaylist:playlists[index],openEdit:true})
    }
    render(){
        return(
            <>
                {
                    this.state.openAddPlaylist &&
                    <AddPlaylist handleCloseModal={this.handleCloseModal} handleAddPlaylist={this.handleAddPlaylist} user={this.state.user}/>
                }
                {
                    this.state.openEdit &&
                    <EditPlaylist handleCloseModal={this.handleCloseModal} handleAddPlaylist={this.handleAddPlaylist} user={this.state.user} currentPlaylist={this.state.currentPlaylist.playlist}/>
                }
                <div className="addPlaylist">
                    <img src={this.state.lang === "fr" ? addPlalist : ajouter_btn} alt="" onClick={this.handleOpenAddPlaylist}/>
                </div>
                <div className="myPlaylist">
                    <div className="block_playlist">
                    <div className="left">
                            <a href={`/playlist/like/${this.state.user.user.id}`}>
                                <img src={playlistDefault} alt=""/>
                            </a>
                        </div>
                        <div className="right">
                            <h2>{this.state.trad.pDescriptionUtilisateur12}</h2>
                            <p>{this.state.trad.pDescriptionUtilisateur13}</p>
                        </div>
                    </div>
                    <div className="block_playlist">
                        <div className="left">
                            <a href={`/playlist/reposts/${this.state.user.user.id}`} >
                                <img src={playlistReposte} alt=""/>
                            </a>
                        </div>
                        <div className="right">
                            <h2>{this.state.trad.pDescriptionUtilisateur14}</h2>
                            <p>{this.state.trad.pDescriptionUtilisateur13}</p>
                        </div>
                    </div>
                    {this.state.playlists[0] ?
                        this.state.playlists.map((value, index) => (
                            <div key={index} className="block_playlist">
                                <div className="left">
                                    <a href={`/playlist/${value.playlist_id}`}>
                                        <img
                                            src={value.playlist.image !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.playlist.image : playlistDefault}
                                            alt=""/>
                                        {
                                            value.playlist.public === 1 &&
                                            <img className="cadena" src={cadena} alt=""/>
                                        }
                                    </a>
                                </div>
                                <div className="right">
                                    <h2>{value.playlist.name}</h2>
                                    {
                                        this.state.user.user.id == value.user.id ?
                                            <p>{this.state.trad.pDescriptionUtilisateur15}</p>
                                            :
                                            <p>{this.state.trad.pDescriptionPlaces18} {value.user.name}</p>
                                    }
                                    {
                                        this.state.user.user.id == value.user.id &&
                                        <p className="playlistModif"
                                           onClick={() => this.handleOpenModifierPlaylist(index)}>{this.state.trad.pDescriptionPlaces15}</p>
                                    }
                                </div>
                            </div>
                        ))
                        : <div className="loading-wave2">
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                            <div className="loading-bar"></div>
                        </div>
                    }
                </div>
            </>
        )
    }

}