import React from "react";
import { Link } from "react-router-dom";
import NavigateButton from "../../modules/NavigateButton";

import compteGeneral from "../../../../assets/logos/compteGeneral.svg";
import comptePassword from "../../../../assets/logos/comptePassword.svg";
import compteNotif from "../../../../assets/logos/compteNotif.svg";
import compteSecurity from "../../../../assets/logos/compteSecurity.svg";
import compteConfidentialite from "../../../../assets/logos/compteConfidentialite.svg";
// import compteContact from "../../../../assets/logos/compteContact.svg";
import connectWallet_Black from "../../../../assets/logos/connectWallet_Black.svg";
import connectWalleten from "../../../../assets/logos/en/connectWalleten.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class Compte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      load:false,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    document.title = "Compte | Wonderplace";
    let user = localStorage.getItem('user');
    if(user === null){
      location.assign('/');
    }else {
      this.setState({load:true},() => {
        let bg = document.querySelector('.img_cover');
        bg.style.backgroundImage = "none";
        bg.style.height = "0";
      })
      if (JSON.parse(localStorage.getItem("user"))) {
        this.setState({
          user: JSON.parse(localStorage.getItem("user")),
        }, () => {
          let lang = this.state.user.user.language;
          if(lang === "fr"){
            this.setState({lang:"fr",trad:fr});
          }
        });
      }
    }
  }


  render() {
    return (
      <>
      {
        this.state.load &&
      
      <div className="compteGlobal">
        <div className="compteHeader">
          <NavigateButton trad={this.state.trad}/>
          <h1>{this.state.trad.settingsHeading}</h1>
        </div>

        <div className="compteCategories">
          <div className="compteBlock">
            <Link to="/compte/general">
              <img
                src={compteGeneral}
                className="generalLogo"
                alt="generalLogo"
              />
            </Link>
            <label htmlFor="">{this.state.trad.settingsGeneralLabel}</label>
          </div>

          <div className="compteBlock">
            <Link to="/compte/password">
              <img
                src={comptePassword}
                className="passwordLogo"
                alt="passwordLogo"
              />
            </Link>
            <label htmlFor="">{this.state.trad.settingsPasswordLabel}</label>
          </div>
          <div className="compteBlock">
            <Link to="/compte/notifications">
              <img src={compteNotif} className="notifLogo" alt="notifLogo" />
            </Link>
            <label htmlFor="">{this.state.trad.settingsNotificationsLabel}</label>
          </div>
          <div className="compteBlock">
            <Link to="/compte/security">
              <img
                src={compteSecurity}
                className="securityLogo"
                alt="securityLogo"
              />
            </Link>
            <label htmlFor="">{this.state.trad.settingsSecurityLabel}</label>
          </div>
          <div className="compteBlock">
            <Link to="/compte/confidentialite">
              <img
                src={compteConfidentialite}
                className="confidentialiteLogo"
                alt="confidentialiteLogo"
              />
            </Link>
            <label htmlFor="">{this.state.trad.settingsPrivacyLabel}</label>
          </div>

          {/* <div className="compteBlock">
            <Link to="/compte/contacts">
              <img
                src={compteContact}
                className="contactLogo"
                alt="contactLogo"
              />
            </Link>
            <label htmlFor="">Gérer les contacts</label>
          </div> */}
        </div>

        <div className="connectWallet">
          <Link to="/">
            <img
                src={this.state.lang === "fr" ? connectWallet_Black : connectWalleten}
              //src={connectWallet_Black}
              className="connectWalletButton"
              alt="connectWalletButton"
            />
          </Link>
        </div>
      </div>
    }
    </>
    );
  }
}
