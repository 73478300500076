import React, {Component} from 'react';
import axios from "axios";

export default class Contact extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){
        return(
            <h1>test</h1>
        )
    }
}