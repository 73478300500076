import React, {Component} from 'react';
import axios from "axios";
import {bannerSelect} from "../../modules/Helper.jsx";
import {Link} from "react-router-dom";

import play_compte from "../../../../assets/logos/play_compte.png";
import edit from "../../../../assets/logos/edit.svg";

import repac from "../../../../assets/logos/repac.svg";
import reposts from "../../../../assets/logos/reposts.svg";
import playlist from "../../../../assets/logos/playlist.svg";
import instant from "../../../../assets/logos/instant.svg";
import connectWallet from "../../../../assets/logos/connectWallet.svg";
import Playlist from "./Playlist.jsx";
import playlistDefault from "../../../../assets/images/5.png";
import background from '../../../../assets/backgrounds/backgroundCompte.jpg';
import Reposts from "./reposts.jsx";

import EpingleUser from "../../modules/EpingleUser";
import SmallModal from "../../modules/SmallModal.jsx";
import PlaylistOther from "./PlaylistOther.jsx";
import LastLike from "./LastLike.jsx";
import RecapPlaylist from "./RecapPlaylist.jsx";

import RepostsLine from "./RepostsLine.jsx";
import LastPlaces from "./LastPlaces.jsx";
import Creators from "./Creators.jsx";
import ModalList from "./ModalList.jsx";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import connectWalleten from "../../../../assets/logos/en/connectWalleten.svg";

const trashStyle = {
    width: 'auto',
    height: '20px',
    cursor: "pointer",
};

export default class Mapage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: "",
            cover: "",
            userName: "",
            artisteName: "",
            biographie: "",
            selectedOption: "",
            creator_mod: false,
            creator_verif: false,
            styleImageBackGround: null,
            height: screen.height + 2,
            user: null,

            followers: 0,
            suivis: 0,
            places: 0,
            reposts: 0,
            instants: 0,

            playlists: null,
            shares: null,
            epingles: null,
            likevideo: null,
            suivie: false,

            type: "recap",
            //type: "reposts",
            videos: null,
            flashMessage: "",
            typeFlash: "",
            currentVideo:null,

            //MODAL
            openModalBug: false,
            openModalContenu: false,
            send: null,
            currentEpingle:null,
            currentPlaylist: null,
            modalListFollow:false,
            modalType:"",

            lang:'en',
            trad:en,
        }
    }

    async componentDidMount() {
        document.querySelector("body").classList.add("pageother");
        document.title = "Ma page | Wonderplace";
        if (localStorage.getItem('storageType')) {
            if (localStorage.getItem('message')) {
                let message = localStorage.getItem('message');
                message = message.split(':');
                this.setState({
                    flashMessage: message[1],
                    typeFlash: message[0],
                }, () => {
                    localStorage.removeItem('message');
                        setTimeout(() => {
                            this.setState({flashMessage: ""})
                        },3000)
                })
            }
            if (JSON.parse(localStorage.getItem("user"))) {
                this.setState({
                    user: JSON.parse(localStorage.getItem("user")),
                }, () => {
                    this.getData();
                    let lang = this.state.user.user.language;
                    if(lang === "fr"){
                        this.setState({lang:"fr",trad:fr});
                    }
                });
            } else {
                location.assign('/');
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize = () => {
        this.setState({
            height: screen.height + 2
        })
    }
    getData = () => {
        let {user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/compte/compteUserLoad";
        axios.post(url, {
            data: "load",
        }, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(res => {
            if(res.data.personalisation !== null){
            this.setState({
                avatar: res.data.personalisation.avatar,
                cover: res.data.personalisation.cover,
                userName: res.data.name,
                biographie: res.data.personalisation.biographie,
                selectedOption: res.data.personalisation.lang,
                name: res.data.name,
                creator_mod: res.data.creator === 1 ? true : false,
                creator_verif: res.data.creator_verif === 1 ? true : false,
                artisteName: res.data.nom_artiste,
                playlists: res.data.playlists,
                shares: res.data.share,
                epingles: res.data.epingle,
                likevideo: res.data.likeVideo,
                suivie: res.data.folow !== null ? true : false,
                suivis: res.data.suivis,
                followers: res.data.follows,
                places: res.data.places,
                reposts: res.data.reposts,
                lastPlaces: res.data.lastPlaces,
                lastCreators: res.data.lastCreators,
                instants: 0,
                listSuivi:res.data.listSuivi,
                listFollow:res.data.listFollow,
                listFullPlaces:res.data.listFullPlaces,
            }, () => {
                    let urlImage = "";
                    if(res.data.personalisation.cover !== null){
                        urlImage = `url(${import.meta.env.VITE_BACKEND_URL_IMG + res.data.personalisation.cover})`;
                    }else{
                        urlImage = `url(${background})`;
                    }
                    let content = document.querySelector('.content');
                    bannerSelect(urlImage);
                    content.classList.add('pageCreateur');
                    user.user.creator = res.data.creator;
                    user.user.creator_verif = res.data.creator_verif;
                    this.setState({
                        user,
                        styleImageBackGround:urlImage
                    }, () => {
                        localStorage.setItem('user', JSON.stringify(user));
                    })
                
            })
            }else{
                location.assign('/inscription/information')
            }
        }).catch(error => {
            console.error(this.state.trad.messageCompte, error);
        })
    }
    handleOpenBoard = (type) => {
        this.setState({type}, () => {
            if (type === "video") {
                const url = import.meta.env.VITE_BACKEND_URL + "/creator/video";
                axios.get(url, {
                    headers: {
                        Authorization: "Bearer " + this.state.user.token,
                    },
                }).then(res => {
                    this.setState({videos: res.data});
                }).catch(err => {
                    console.log(err);
                })
            }
        });
    }
    handleEditVideo = async (index) => {
        let video = this.state.videos[index];
        await localStorage.setItem('currentVideo', JSON.stringify(video));
        await localStorage.setItem('editvideo', 'editvideo');
        location.assign('/gestion-de-ma-page/upload-video');
    }
    handleOpenModalDelete = (index) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        const {videos} = this.state;
        this.setState({currentVideo:videos[index]});
    }
    handleCloseModalDelete = () =>{
        this.setState({currentVideo:null,});
    }
    handleDeleteVideo = () => {
        const {currentVideo} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/creator/delete";
        axios.post(url, {
            id: currentVideo.id
            }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: 'Bearer ' + this.state.user.token
                },
        }).then(res => {
            this.setState({videos: res.data,currentVideo:null,});
        }).catch(err => {
            console.log(err)
        })
    }
    handleCloseModal = () => {
        this.setState({modalListFollow:false,openModalBug:false});
    }
    handleOpenList = (type) => {
        if (type === "follow" || type === "suivis" || type === "place") {
            this.setState({modalListFollow:true,modalType:type});
        }else{
            this.setState({type});
        }
    }
    callBackEpingle = (type,action,index) => {
        console.log(type,action,index);
        if(action === "bug"){
            this.handleFunctionEpingleMenu(type,action,index);
        }else if(action === "contenu"){
            this.handleFunctionEpingleMenu(type,action,index);
        }
    }
    handleFunctionEpingleMenu = (type,action,index) => {
        const {epingles} = this.state;
        this.setState({openModalBug:true,currentEpingle:epingles[index],send:action});
    }
    callbackSend = (message) => {
        const {send, user,currentEpingle} = this.state;
        let data = [];
        data[0] = send;
        data[1] = message;
        data[2] = user;
        data[3] = currentEpingle;
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/send";
        axios.post(url, {
            data
        }).then(res => {
            if (res.data) {
                this.handleCloseModal();
                this.setState({
                    typeFlash: 'success',
                    flashMessage: this.state.trad.messageCompte1,
                    send: null
                },() =>{
                    setTimeout(() => {
                        this.setState({flashMessage: ""})
                    },3000)
                });
            }
        }).catch(err => {
            this.setState({
                openModalContenu: false,
                openModalBug: false,
                typeFlash: 'warning',
                flashMessage: this.state.trad.messageCompte2
            },() =>{
                setTimeout(() => {
                    this.setState({flashMessage: ""})
                },3000)
            });
        })
    }
    render() {
        return (
            <>
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                {
                    this.state.styleImageBackGround !== null &&
                    <>
                        <div className="block_presentation">
                            <div className="block_header_creator">
                                <div className="block_img">
                                    {
                                        this.state.user.user.avatar !== null ?
                                            <img className={`avatar_creator ${this.state.creator_verif ? "square" : ""}`} src={import.meta.env.VITE_BACKEND_URL_IMG + this.state.user.user.avatar} alt=""/>
                                            :
                                            <img className={`avatar_creator ${this.state.creator_verif ? "square" : ""}`} src={playlistDefault} alt=""/>

                                    }
                                    <div className="play_block">
                                        <a href={`/shuffle/${this.state.user.user.id}`}>
                                            <img src={play_compte} alt=""/>
                                            <p>{this.state.trad.pDescriptionPlaces11}</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="block_info">
                                    <div className="line_1">
                                        <div className="left">
                                            <h2>{this.state.name}</h2>
                                        </div>
                                        <div className="right">
                                            <a href="/mon-wallet">
                                                <img
                                                    src={this.state.lang === "fr" ? connectWallet : connectWalleten}
                                                    className="connectWalletLogo"
                                                    alt="connectWalletLogo"
                                                />
                                            </a>
                                            <Link to="/compte">
                                                <img src={edit} alt=""/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="line_2">
                                        <div className="social_wonder">
                                            <p onClick={() => this.handleOpenList('follow')}>{this.state.followers}<br/><span>{this.state.trad.creatorFollowers}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('suivis')}>{this.state.suivis}<br/><span>{this.state.trad.creatorFollow}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('place')}>{this.state.places}<br/><span>{this.state.trad.headerNavSub7}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('reposts')}>{this.state.reposts}<br/><span>{this.state.trad.creatorRepost}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('instant')}>{this.state.instants}<br/><span>{this.state.trad.creatorMoments}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="line_3">
                                        {
                                            this.state.creator_verif === 1 ?
                                                <h2>{this.state.trad.creator}</h2>
                                                :
                                                <h2>{this.state.trad.pDescriptionUtilisateur7}</h2>
                                        }
                                        <p>{this.state.biographie}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_content_page">
                            <nav>
                                <ul>
                                    <li className={this.state.type === "recap" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('recap')}><img src={repac} alt=""/>
                                        <p>{this.state.trad.creatorText1}</p></li>
                                    <li className={this.state.type === "reposts" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('reposts')}><img src={reposts} alt=""/>
                                        <p>{this.state.trad.creatorRepost}</p></li>
                                    <li className={this.state.type === "playlist" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('playlist')}><img src={playlist} alt=""/>
                                        <p>{this.state.trad.creatorText3}</p></li>
                                    <li className={this.state.type === "instant" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('instant')}><img src={instant} alt=""/>
                                        <p>{this.state.trad.creatorMoments}</p></li>
                                </ul>
                            </nav>
                        </div>
                        <div className={`board ${this.state.type}`}>
                            {
                                this.state.type === 'recap' &&
                                <>
                                    {
                                        this.state.epingles[0] &&
                                        <EpingleUser callBackEpingle={this.callBackEpingle} epingles={this.state.epingles}
                                                     user={this.state.user} myPage={true}/>
                                    }
                                    {
                                        this.state.likevideo[0] &&
                                        <LastLike id={this.state.id} videos={this.state.likevideo}/>
                                    }
                                    {
                                        this.state.playlists[0] &&
                                        <RecapPlaylist callBackPlaylist={this.callBackPlaylist}
                                                       playlists={this.state.playlists}/>
                                    }
                                    {
                                        this.state.shares[0] &&
                                        <RepostsLine shares={this.state.shares}/>
                                    }
                                    {
                                        this.state.lastPlaces[0] &&
                                        <LastPlaces lastPlaces={this.state.lastPlaces}/>
                                    }
                                    {
                                        this.state.lastCreators[0] &&
                                        <Creators creators={this.state.lastCreators}/>
                                    }
                                </>
                            }
                            {
                                this.state.type == "playlist" &&
                                <Playlist user={this.state.user}/>
                            }
                            {
                                this.state.type == "reposts" &&
                                    <Reposts user={this.state.user} id={this.state.user.user.id}/>
                            }

                            {
                                this.state.type == "instant" &&
                                <p>{this.state.trad.creatorText5}</p>
                            }

                        </div>
                        {
                            this.state.openModalBug &&
                            <SmallModal title={this.state.trad.pDescriptionUtilisateur3} label={this.state.trad.pDescriptionUtilisateur9} handleCloseModal={this.handleCloseModal}
                                        callbackSend={this.callbackSend}/>
                        }
                        {
                            this.state.openModalContenu &&
                            <SmallModal title={this.state.trad.pDescriptionUtilisateur10} label={this.state.trad.pDescriptionUtilisateur11}
                                        handleCloseModal={this.handleCloseModal} callbackSend={this.callbackSend}/>
                        }
                        {
                            this.state.modalListFollow &&
                            <ModalList
                                handleCloseModal={this.handleCloseModal}
                                followers={this.state.followers}
                                listFollow={this.state.listFollow}
                                listFullPlaces={this.state.listFullPlaces}
                                places={this.state.places}
                                listSuivi={this.state.listSuivi}
                                suivis={this.state.suivis}
                                modalType={this.state.modalType}
                            />
                        }
                    </>
                }
            </>
        );
    }
}

