import { Link } from "react-router-dom";

export default function CompteNavMenu({trad}) {
  return (
      <nav className="compteNavMenu">
        <ul>
          <div className="highlight">
            <li>
              <Link to="/compte/general">{trad.settingsGeneralLabel}</Link>
            </li>
          </div>

          <div className="highlight">
            <li>
              <Link to="/compte/password">{trad.settingsPasswordLabel}</Link>
            </li>
          </div>

          <div className="highlight">
            <li>
              <Link to="/compte/notifications">{trad.settingsNotificationsLabel}</Link>
            </li>
          </div>

          <div className="highlight">
            <li>
              <Link to="/compte/security">{trad.settingsSecurityLabel}</Link>
            </li>
          </div>

          <div className="highlight">
            <li>
              <Link to="/compte/confidentialite">{trad.settingsPrivacyLabel}</Link>
            </li>
          </div>
        </ul>
      </nav>
  );
}
