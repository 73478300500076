import React from 'react';
import dot from "../../../../assets/logos/dot_horizontal.svg";

import playlist from '../../../../assets/images/2.png'
import chevron from "../../../../assets/logos/chevron.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class RecapPlaylist extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            playlists:this.props.playlists,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleOpenMenuDot = (index) => {
        let id = `menu_playlist_${index}`;
        let menu = document.getElementById(id);
        if (menu.style.display === "block") {
            menu.style.display = "none";
        } else {
            menu.style.display = "block";
        }
    }
    handleLink = (type,index) => {
        this.props.callBackPlaylist(type,index);
    }
    handleEpinglePlace = (type,index) => {

    }

    render(){
        return(
            <div className="playlists">
                <h2>{this.state.trad.creatorText3} <img style={{marginLeft: "8px"}} src={chevron} alt=""/></h2>
                {
                    this.state.playlists.map((value, index) => (
                        value.public === 0 &&
                        <div className={`playlist`} key={index}>
                            {
                                value.public === 0 &&
                                    <div className="menu">
                                        <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                        <ul id={`menu_playlist_${index}`}>
                                            <li onClick={() => this.handleLink("bug", index)}>{this.state.trad.pDescriptionUtilisateur3}</li>
                                            <li onClick={() => this.handleLink("contenu", index)}>{this.state.trad.pDescriptionUtilisateur4}</li>
                                            <li onClick={() => this.handleLink("save", index)}>{this.state.trad.pDescriptionUtilisateur5}</li>
                                            <li onClick={() => this.handleLink("share", index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                        </ul>
                                    </div>
                            }
                            <div className="img">
                                <img src={value.image !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image : playlist }
                                     alt=""/>
                            </div>
                            <div className="text">
                                <a href={`/playlist/${value.id}`}>
                                    <p>{value.name}</p>
                                    <p>{this.state.trad.pDescriptionPlaces18} {value.nameUser}</p>
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

}