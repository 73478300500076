import React from "react";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import axios from "axios";
import replace from "../../../assets/images/5.png";
import cadena from "../../../assets/logos/cadena.svg";
import add from "../../../assets/logos/ajouter_btn.svg";
import partager from "../../../assets/logos/partager.svg";
import partageren from "../../../assets/logos/en/partageren.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
export default class Follow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: this.props.video,
            idvideo: this.props.video.id,
            user: this.props.user,
            token:null,
            myPlaces: [],
            link: "",
            legend:"",
            timeStart:'00:00',
            timeEnd:'00:00',
            instantPartage:false,
            place:null,
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        let link = location.href + 'video/'+this.state.video.token;
        this.setState({link:link,token:this.state.video.token});
        if (this.state.user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/places/allplacesuser/";
            axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + this.state.user.token
                }
            }).then(res => {
                this.setState({myPlaces: res.data});
            }).catch(err => {
                console.log(err);
            })
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }
    handleCopieLink = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error(this.state.trad.followMessage, err);
        }
    }
    handlePlacesSelect = (value) => {
        this.setState({place:value});
    }
    handlePost = () => {
        const {place,timeStart,timeEnd,instantPartage,legend,idvideo,token} = this.state;
        if(place !== null){
            let url = import.meta.env.VITE_BACKEND_URL + "/places/partage";
            axios.post(url,{
                place,
                token,
                timeStart,
                timeEnd,
                instantPartage,
                legend,
                idvideo
            },{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + this.state.user.token
                }
            }).then(res =>{
                if(res.data){
                    this.props.handleCloseModal();
                }
            }).catch(err => {
                console.log(err);
            })
        }

    }

    render() {
        return (
            <div className="modal">
                <div className="block">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    {this.state.message !== "" && (
                        <div className={`message ${this.state.style}`}>
                            <p>{this.state.message}</p>
                        </div>
                    )}
                    <h2>{this.state.trad.titleFollow}</h2>
                    <div className="form_connexion commentaireBlock">
                        <div className="img">
                            <img width='250' src={this.state.video.poster} alt="" />
                            <p><input type="checkbox" onChange={(e) => this.setState({instantPartage:e.target.checked})}/><label htmlFor="">{this.state.trad.labelFollow}</label>
                                {
                                    this.state.instantPartage ?
                                        <input type="time" name="time_debut" value={this.state.timeStart} onChange={(e) => this.setState({timeStart:e.target.value})}/>
                                        :
                                        <input disabled={true} type="time" name="time_debut" value={this.state.timeStart} onChange={(e) => this.setState({timeStart:e.target.value})}/>
                                }
                                à
                                {
                                    this.state.instantPartage ?
                                        <input type="time" name="time_fin" value={this.state.timeEnd} onChange={(e) => this.setState({timeEnd:e.target.value})}/>
                                        :
                                        <input disabled={true} type="time" name="time_fin" value={this.state.timeEnd} onChange={(e) => this.setState({timeEnd:e.target.value})}/>

                                }
                            </p>
                        </div>
                        <div className="form_control">
                            <label htmlFor="">{this.state.trad.labelFollow1}</label>
                            <select name="places" onChange={(e) => this.handlePlacesSelect(e.target.value)}>
                                <option value="default">{this.state.trad.optionPlace}</option>
                                {
                                    this.state.myPlaces.map((value, index) => (
                                        <option key={index} value={value.id}>{value.name} #{value.city}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form_control">
                            <label htmlFor="">{this.state.trad.labelFollow2}</label>
                            <input type="text" defaultValue={this.state.link}/><span
                            onClick={() => this.handleCopieLink(this.state.link)}>{this.state.trad.labelFollow3}</span>
                        </div>
                        <div className="form_control">
                            <label htmlFor="">{this.state.trad.labelFollow4}</label>
                            <textarea name="" id="" cols="30" rows="10" value={this.state.legend} onChange={(e) => this.setState({legend:e.target.value})}></textarea>
                        </div>
                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? partager : partageren}
                                alt="se connecter"
                                className="seconnecter"
                                onClick={this.handlePost}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}