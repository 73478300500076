import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import continuerButton from "../../../../assets/logos/continuerButton.svg";
import continuerButtonen from "../../../../assets/logos/en/continuerButtonen.svg";
import uploadVideoIcon from "../../../../assets/logos/noun-upload-3778990.svg";

import "video.js/dist/video-js.css";

import LinearDeterminate from "../../modules/LinearDeterminate.jsx";
import VideoUpload from "../../modules/VideoUpload.jsx";
import CropperAdv from "../../modules/CropperAdv";
import {bannerHeight} from "../../modules/Helper.jsx";
import MenuUpload from "../../modules/MenuUpload.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class UploadVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendLauch: false,
            user: null,
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            fileInfos: null,
            video: null,
            currentVideo:null,
            timeCode: 0,
            miniature: null,
            cropperCover: false,
            cover: "",
            coverInput:"",
            loaderImg:false,

            lang:'en',
            trad:en,
        };
    }

    componentDidMount() {
        document.title = "Upload Video | Wonderplace";
        let user = localStorage.getItem("user");
        /*
        if (user !== null) {
            this.setState({
                user: JSON.parse(user),
            },() => {
                bannerHeight();
            });
        } else {
            location.assign("/");
        }*/
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                bannerHeight();
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }else {
            location.assign("/");
        }
        if(localStorage.getItem('editvideo') ){
            this.setState({currentVideo:JSON.parse(localStorage.getItem('currentVideo'))},() => {
                let video = {
                    id: this.state.currentVideo.id,
                    src: import.meta.env.VITE_BACKEND_URL_IMG + this.state.currentVideo.path_nocompres,
                    type: "video/mp4",
                }
                let miniature = import.meta.env.VITE_BACKEND_URL_IMG + this.state.currentVideo.miniature
                this.setState({video,miniature,edit:true});
            })
        }
        if(sessionStorage.getItem('editvideo')){
            this.setState({currentVideo:JSON.parse(sessionStorage.getItem('currentVideo'))})
        }
    }
    upload = (event) => {
        let currentFile = event.target.files;
        this.setState({
            progress: 0,
            currentFile: currentFile[0],
        });
        let formData = new FormData();
        formData.append('file', currentFile[0]);

        let url = import.meta.env.VITE_BACKEND_URL + "/upload/video";
        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + this.state.user.token
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                this.setState({progress});
            },
            onDownloadProgress: (progressEvent) => {
                const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                this.setState({progress});
            },
        }).then((res) => {
            let video = {
                id: res.data.id,
                src: import.meta.env.VITE_BACKEND_URL_IMG + res.data.path_nocompres,
                type: "video/mp4",
            }
            this.setState({
                video: video,
                currentFile: null,
                progress: 0,
            });
        }).catch((err) => {
            console.log(err);
        });


    }
    handleTimeCode = (e, player) => {
        e.preventDefault();
        this.setState({loaderImg:true},() => {
            let currentTime = player.current.cache_.currentTime;
            let hours = Math.floor(currentTime / 3600); // Obtient les heures entières
            let minutes = Math.floor((currentTime % 3600) / 60); // Obtient les minutes entières
            let seconds = Math.floor(currentTime % 60); // Obtient les secondes entières
            let milliseconds = Math.floor((currentTime - Math.floor(currentTime)) * 1000);
            let timeCode = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;

            this.setState({timeCode: timeCode, refresh: false}, () => {
                let url = import.meta.env.VITE_BACKEND_URL + "/upload/video/timecode";
                axios.post(url, {
                    id: this.state.video.id,
                    timeCode: timeCode,
                }, {
                    headers: {
                        Authorization: 'Bearer ' + this.state.user.token
                    }
                }).then(res => {
                    let video = {
                        id: res.data.id,
                        src: import.meta.env.VITE_BACKEND_URL_IMG + res.data.path_nocompres,
                        type: "video/mp4",
                        poster: import.meta.env.VITE_BACKEND_URL_IMG + res.data.miniature,
                    }
                    this.setState({
                        loaderImg:false,
                        video: video,
                        miniature: import.meta.env.VITE_BACKEND_URL_IMG + res.data.miniature,
                    })
                }).catch(err => {
                    console.log(err);
                })
            });
        });
    }
    handleOpenCover = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        document.querySelector('body').style.overflow = "hidden";
        this.setState({cropperCover: true});
    };
    closeModalCropp = () => {
        let elImg = document.querySelector(`#imageBase2`);
        if (elImg.value !== "") {
            this.setState({cover: elImg.value})
        }
        document.querySelector('body').style.overflow = "auto";
        this.setState({
            cropperCover: false,
        });
    };
    callBackCropper = (base64Img,index) => {
        console.log(index);
        if(index === 2){
            this.setState({
                cover: base64Img,
                coverInput: base64Img,
            },() => {
                const url = import.meta.env.VITE_BACKEND_URL + "/upload/miniaturecropper";
                axios.post(url,{
                    id:this.state.video.id,
                    miniature: this.state.cover,
                },{
                    headers: {
                        Authorization: "Bearer " + this.state.user.token,
                    },
                }).then(res => {

                    this.closeModalCropp();
                }).catch(err => {
                    console.log(err);
                })

            })
        }
    }
    handleUploadContinue = async (e) => {
        e.preventDefault();
        if(localStorage.getItem('storageType')){
            if(localStorage.getItem('currentVideo')){
                await localStorage.setItem('currentVideo',JSON.stringify(this.state.currentVideo));
            }else{
                await localStorage.setItem('currentVideo',JSON.stringify(this.state.video));
            }
        }else{
            if(sessionStorage.getItem('currentVideo')){
                await sessionStorage.setItem('currentVideo',JSON.stringify(this.state.currentVideo));
            }else{
                await sessionStorage.setItem('currentVideo',JSON.stringify(this.state.video));
            }
        }
        location.assign('/gestion-de-ma-page/upload-video-part2');
    };
    callbackIndexVideo = () => {

    }

    render() {
        return (
            <>
            <div className="uploadVideoGlobal">
                <div className="compteHeader">
                    <h1>{this.state.trad.creatorUploadVideo}</h1>
                    {
                        this.state.edit &&
                            <MenuUpload active={1} />
                    }
                    <p>{this.state.trad.creatorUploadVideo1}</p>
                    <div className="video_upload_content">

                        {
                            this.state.video === null ?
                                <>
                                    <div className="uploadVideoContainer">
                                        <input type="file" className="uploadVideoInput"
                                               onChange={(e) => this.upload(e)}/>

                                        <div className="uploadVideoSection">
                                            <img src={uploadVideoIcon} alt="upload vidéo"/>
                                            <label htmlFor="Uploader une vidéo">{this.state.trad.creatorUploadVideo2}</label>
                                        </div>
                                    </div>
                                    {
                                        this.state.progress !== 0 && this.state.progress !== "NaN" &&
                                        <LinearDeterminate progress={this.state.progress}/>
                                    }
                                </>
                                :
                                <>
                                    <VideoUpload callbackIndexVideo={this.callbackIndexVideo} video={this.state.video} upload={true} handleTimeCode={this.handleTimeCode}/>

                                </>
                        }


                        {
                            this.state.video !== null &&
                            <>
                                <div className="uploadVideoVignetteSection">
                                    {/*
                                    <p>Vous pouvez utiliser le lecteur vidéo et le bouton "Extraire une image de la vidéo comme miniature" ci-dessus pour sélectionner une miniature.</p>
                                    <p className="center_text">OU</p>
                                    */}
                                </div>
                                <div className="uploadVignetteText">
                                    <input type="hidden" id="imageBase2" value={this.state.coverInput}/>
                                    <img src={this.state.coverInput || ''} style={{display: this.state.coverInput ? 'block' : 'none'}} alt="upload vidéo"
                                         id="imgPreview2"/>
                                    <img src={uploadVideoIcon} alt="upload vidéo"/>
                                    <p className="small"
                                       onClick={(e) => this.handleOpenCover(e)}>{this.state.trad.uploadVignetteVideo}</p>

                                </div>
                                {
                                    this.state.miniature !== null &&
                                    <div className="vignetteSelection">
                                                <img src={this.state.miniature}
                                                     alt="" className="vignette2"/>
                                        </div>
                                    }
                                    {
                                        this.state.loaderImg &&
                                        <Box sx={{ display: 'flex', justifyContent:"center",alignItems:"center",width:"100%"}}>
                                            <CircularProgress />
                                        </Box>
                                    }
                                <div className="form_submit_container">
                                    {this.state.sendLauch ? (
                                        <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : (
                                        !this.state.loaderImg &&
                                        <div className="form_submit">
                                            <Link to="/ma-page/upload-video-part2">
                                                <img
                                                    src={this.state.lang === "fr" ? continuerButton : continuerButtonen}
                                                    //src={continuerButton}
                                                    alt="continuer"
                                                    className="continuer"
                                                    onClick={this.handleUploadContinue}
                                                />
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        {this.state.cropperCover && (
            <div className="modal">
                <CropperAdv
                    ratio={16 / 9}
                    index={2}
                    closeModalCropp={this.closeModalCropp}
                    callBackCropper={this.callBackCropper}
                />
            </div>
        )}
        </>
        );
    }
}
