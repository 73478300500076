import React from "react";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import axios from "axios";

import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "../modules/MuiSwitch";

import CropperAdv from "../modules/CropperAdv";

import poster from "../../../assets/logos/ajouter_btn.svg";
import ajouter_btn_en from "../../../assets/logos/en/ajouter_btn.svg";
import searchPlaces from '../../../assets/images/5.png';

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class AddPlaylist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            places: this.props.places ? this.props.places : null,
            name: "",
            description: "",
            openCropp: false,
            private_place: false,
            imgPlaces: "",
            user: this.props.user,
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleChangePrivatePlace = () => {
        this.setState({private_place: !this.state.private_place})
    }
    closeModalCropp = () => {
        this.setState({openCropp: false});
    }
    handleOpenImgPlaces = () => {
        this.setState({openCropp: true});

    }
    callBackCropper = (base64Img, index) => {
        if (index === 2) {
            this.setState({imgPlaces: base64Img,}, () => {
                this.closeModalCropp();
            });
        }
    };
    handlePost = () => {
        const {private_place, description, name, imgPlaces, places} = this.state;
        if (name !== "") {
            if (description != "") {
                if (places === null) {
                    const url = import.meta.env.VITE_BACKEND_URL + "/playlist/save";
                    axios.post(url, {
                        name: name,
                        description: description,
                        private: private_place,
                        image_place: imgPlaces,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.props.handleAddPlaylist(res.data);
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    const url = import.meta.env.VITE_BACKEND_URL + "/places/playlist/save";
                    axios.post(url, {
                        name: name,
                        description: description,
                        private: private_place,
                        image_place: imgPlaces,
                        places_id:places,
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.props.handleAddPlaylist(res.data);
                    }).catch(err => {
                        console.log(err);
                    })
                }
            } else {
                this.setState({message: this.state.trad.placeMessage3})
            }
        } else {
            this.setState({message: this.state.trad.placeMessage2})
        }
    }

    render() {
        return (
            <div className="modal playlist">
                <div className="block">
                    {this.state.openCropp && (
                        <div className="modal">
                            <CropperAdv
                                ratio={16 / 9}
                                index={2}
                                closeModalCropp={this.closeModalCropp}
                                callBackCropper={this.callBackCropper}
                            />
                        </div>
                    )}
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    {this.state.message !== "" && (
                        <div className={`message ${this.state.style}`}>
                            <p>{this.state.message}</p>
                        </div>
                    )}
                    <h2>{this.state.trad.pAddPlaylist}</h2>
                    <div className="form_connexion">
                        <div className="form_control">
                            <label htmlFor="email">{this.state.trad.labelNamePlaylist}</label>
                            <input
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={(e) => this.setState({name: e.target.value})}
                            />
                        </div>
                        <div className="form_control">
                            <label htmlFor="description">{this.state.trad.labelDescriptionPlaylist}</label>
                            <textarea name="description" value={this.state.description}
                                      onChange={(e) => this.setState({description: e.target.value})}></textarea>
                        </div>
                        {
                            this.state.places === null &&
                            <div className="form_control switch_block">
                                <FormControlLabel
                                    control={
                                        <MuiSwitch
                                            size="small"
                                            checked={this.state.private_place}
                                            onChange={this.handleChangePrivatePlace}
                                            name="private_account"
                                        />
                                    }
                                    label={this.state.trad.labelPlaylistPrivate}
                                />
                            </div>
                        }

                        <div className="form_control">
                            <div className="notif">

                                <div className="avatar">
                                    <div className="imgAvatar" onClick={this.handleOpenImgPlaces}>

                                        <img
                                            src={
                                                this.state.imgPlaces !== "" ? this.state.imgPlaces : searchPlaces
                                            }
                                            id="imgPreview2"
                                            alt=""
                                        />
                                        <p className="plusAvatar">+</p>

                                    </div>
                                    <p className="avatarText">{this.state.trad.pAddPlace}</p>
                                    <input
                                        type="hidden"
                                        id="imageBase2"
                                        value={this.state.imgPlaces || ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? poster : ajouter_btn_en}
                                alt="se connecter"
                                className="seconnecter"
                                onClick={this.handlePost}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}