import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const MuiSwitch = styled(Switch)(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 16,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(17px)",
    },
  },

  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(255,255,255,1)",
        // border: "none",
      },
      "& .MuiSwitch-thumb": {
        color: "#000",
      },
    },
  },

  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    height: 14,
    borderRadius: 10,
    color: "#fff",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },

  "& .MuiSwitch-track": {
    border: "1px solid #fff",
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: "rgba(55,55,55,.0)",
    boxSizing: "border-box",
  },
}));

export default MuiSwitch;
