import React, {useEffect, useRef, useState} from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import VideoJS from "../modules/VideoJS";

const VideoUpload = (props) => {
    const playerRef = useRef(null);
    const [video, setVideo] = useState(props.video);
    const [refresh, setRefresh] = useState(false);

    const [videoJsOptions,setVideoJsOptions] = useState({
        autoplay: false,
        controls: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        muted: true,
        aspectRatio: "16:9",

        controlBar: {
            remainingTimeDisplay: false,
            disablePictureInPicture: true,
            timeDivider: true,
            children: [
                "playToggle",
                "progressControl",
                "currentTimeDisplay",
                "timeDivider",
                "durationDisplay",
                "fullscreenToggle",
                "volumePanel",
                // "restartButton",  //// RestartButton is custom, the order is done in VideoJS.jsx with the index
                //  "nextVideoButton",  //// NextVideoButton is custom, the order is done in VideoJS.jsx with the index
            ],
        },
        sources: [video],
        poster: video.poster,
    });

    useEffect(() => {
        setVideoJsOptions((prevOptions) => {
            return { ...prevOptions, sources: [props.video] };
        });
        //setVideo(props.video);
        //videoJsOptions.sources = [props.video];
        //setRefresh(true);
        //if (props.video.isPlay) {
        //    handlePlay();
        //} else {
        //    handlePause();
        //}
    }, [props.video]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        player.on("waiting", () => {
            videojs.log("player is waiting");
        });

        player.on("dispose", () => {
            videojs.log("player will dispose");
        });
    };

    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play().catch(function(erreur){

            });
        }
    };

    const handlePause = () => {
        if (playerRef.current) {
            playerRef.current.pause();
        }
    };


    return (
        <div className="videoPlayerGlobal notMarginRight small">
            <div className="videoPlayerContainer">
                <VideoJS
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                    callbackIndexVideo={props.callbackIndexVideo}
                />
            </div>
        </div>
    );
};

export default VideoUpload;