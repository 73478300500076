import React from "react";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import axios from "axios";

import accepter from "../../../assets/logos/accepter_btn.svg";
import poster from "../../../assets/logos/poster_fr.svg";
import posteren from "../../../assets/logos/en/posteren.svg"
import accept from "../../../assets/logos/en/accept.svg"

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';


export default class Commentaire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentaire: "",
            commentaires: [],
            IdVideo: this.props.idvideo,
            user: this.props.user,
            message:"",
            style:"",
            openModalRemove:false,
            index:0,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if (this.state.user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/com/commentaire/" + this.props.idvideo;
            axios.get(url, {
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                }
            }).then(res => {
                this.setState({commentaires: res.data});
            }).catch(err => {
                console.log(err);
            })
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handlePost = () => {
        const {commentaire,IdVideo} = this.state;
        if(commentaire !== ""){
            let url = import.meta.env.VITE_BACKEND_URL + "/com/addcom";
            axios.post(url, {
                commentaire:commentaire,
                videos_id:IdVideo,
            }, {
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                }
            }).then(res => {
                this.setState({commentaires:res.data,commentaire:""});
            }).catch(err => {
                console.log(err);
            })
        }else{
            this.setState({
                message: this.state.trad.commentaireMessage,
                style:"warning",
            })
        }
    }
    handleRemoveCom = () => {
        const {commentaires,IdVideo} = this.state;
        let commentaire = commentaires[this.state.index];
        let url = import.meta.env.VITE_BACKEND_URL + "/com/removecom";
        axios.post(url, {
            commentaire:commentaire.id,
            videos_id:IdVideo,
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            }
        }).then(res => {
            this.setState({commentaires:res.data,openModalRemove:false,index:0});
        }).catch(err => {
            console.log(err);
        })
    }
    handleCloseModal = () => {
        this.setState({openModalRemove:false,index:0});
    }
    handleOpenModal = (index) => {
        this.setState({openModalRemove:true,index:index});
    }

    render() {
        return (
            <>
                {
                    this.state.openModalRemove &&
                    <div className="modal smallCom">
                        <div className="block">
                            <div className="closeModal">
                                <img
                                    className="crossIcon"
                                    src={crossIcon}
                                    alt="crossIcon"
                                    onClick={this.handleCloseModal}
                                />
                            </div>

                            <div className="form_connexion commentaireBlock">
                                <div className="form_control">
                                    <label htmlFor="commentaire">{this.state.trad.labelComments} <br/><br/> {this.state.commentaires[this.state.index].commentaire}</label>
                                </div>
                                <div className="form_submit">
                                    <img
                                        src={this.state.lang === "fr" ? accepter : accept}
                                        alt="se connecter"
                                        className="seconnecter"
                                        onClick={this.handleRemoveCom}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="modal">
                    <div className="block">
                        <div className="closeModal">
                            <img
                                className="crossIcon"
                                src={crossIcon}
                                alt="crossIcon"
                                onClick={this.props.handleCloseModal}
                            />
                        </div>
                        {this.state.message !== "" && (
                            <div className={`message ${this.state.style}`}>
                                <p>{this.state.message}</p>
                            </div>
                        )}
                        <h2>{this.state.trad.titleComments}</h2>
                        <div className="comm">
                            {
                                this.state.commentaires.map((value, index) => (
                                    <div key={index} className="lineCom">
                                        <div className="avatar">
                                            <img src={import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar} alt=""/>
                                        </div>
                                        <div className="user">
                                            <p className="name">{value.utilisateur.name} <span className="date">{value.depuis}</span></p>
                                            <p className="commentaire">{value.commentaire}</p>
                                        </div>
                                        {
                                            value.utilisateurs_id == this.state.user.user.id &&
                                            <div className="removeCom" onClick={() => this.handleOpenModal(index)}>
                                                <p className="date">{this.state.trad.creatorText9}</p>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="form_connexion commentaireBlock">
                            <div className="form_control">
                                <label htmlFor="commentaire">{this.state.trad.labelAdComments}</label>
                                <textarea name="commentaire" value={this.state.commentaire} onChange={(e) => this.setState({commentaire: e.target.value})}></textarea>
                            </div>
                            <div className="form_submit">
                                <img
                                    src={this.state.lang === "fr" ? poster : posteren}
                                    alt="se connecter"
                                    className="seconnecter"
                                    onClick={this.handlePost}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}