import React from 'react';
import dot from "../../../../assets/logos/dot_horizontal.svg";
import playlist from "../../../../assets/images/1.png";
import chevron from "../../../../assets/logos/chevron.svg";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';



export default class LastPlaces extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            lastPlaces:this.props.lastPlaces,
            lang:'en',
            trad:en,
        }
    }

    render(){
        return(
            <div className="playlists">
                <h2>{this.state.trad.pDescriptionUtilisateur2}  <img style={{marginLeft: "8px"}} src={chevron} alt=""/></h2>
                {
                    this.state.lastPlaces[0] &&
                    this.state.lastPlaces.map((value,index) => (
                        <div className={`playlist`} key={index}>
                            <div className="menu">
                                <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                <ul id={`menu_playlist_${index}`}>
                                    <li onClick={() => this.handleLink("bug",index)}>{this.state.trad.pDescriptionUtilisateur3}</li>
                                    <li onClick={() => this.handleLink("contenu",index)}>{this.state.trad.pDescriptionUtilisateur4}</li>
                                    <li onClick={() => this.handleLink("save",index)}>{this.state.trad.pDescriptionUtilisateur5}</li>
                                    <li onClick={() => this.handleLink("share",index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                </ul>
                            </div>
                            <div className="img">
                                <img src={value.image_place !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image_place : playlist }
                                     alt=""/>
                            </div>
                            <div className="text">
                                <a href={`/place/${value.id}`}>
                                    <p>{value.name}</p>
                                    <p>{this.state.trad.pDescriptionPlaces18} {value.nameUser}</p>
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

}