import React from "react";
import axios from "axios";

import NavigateButton from "../../modules/NavigateButton";
import CompteNavMenu from "../../modules/CompteNavMenu";
import changeValidButton from "../../../../assets/logos/changeValidButton.svg";
import eye from "../../../../assets/logos/eye.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";
export default class ComptePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirm_password: "",
      oldPassword: "",
      user: null,
      message: null,
      message2: null,
      messageFlash: null,
      sendLauch: false,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    document.title = "Compte | Password | Wonderplace";
    let user = localStorage.getItem("user");
    let userConnect = JSON.parse(user);
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }
    this.setState({
      user: userConnect,
    });
  }

  handleInscription = () => {
    const { password, confirm_password, oldPassword, user } = this.state;
    let url = import.meta.env.VITE_BACKEND_URL + "/compte/password/check";
    this.setState(
      {
        sendLauch: true,
      },
      () => {
        axios
          .post(
            url,
            {
              oldPassword,
            },
            {
              headers: {
                Authorization: "Bearer " + user.token,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              let url =
                import.meta.env.VITE_BACKEND_URL + "/compte/password/update";

              if (password === confirm_password) {
                axios
                  .post(
                    url,
                    {
                      password,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + user.token,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("user");
                    this.setState(
                      {
                        messageFlash: this.state.trad.messagePasswordChange,
                        password: "",
                        confirm_password: "",
                        oldPassword: "",
                      },
                      () => {
                        location.assign("/");
                      }
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                this.setState({
                  message2: this.state.trad.messagePasswordChange2,
                  sendLauch: false,
                });
              }
            } else {
              this.setState({
                message: this.state.trad.messagePasswordChange3,
                sendLauch: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };
  showHidePassword = (element) => {
    let el = document.getElementById(element);
    if (el.type === "password") {
      el.setAttribute("type", "text");
    } else {
      el.setAttribute("type", "password");
    }
  };
  handlePasswordHidden = () => {
    const { user } = this.state;
    let url = import.meta.env.VITE_BACKEND_URL + "/compte/password/lost";
    axios
      .post(
        url,
        {
          message: "password lost",
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            messageFlash:this.state.trad.messagePasswordChange4,
          });
          /*************************
           ** FAIRE LA PAGE DE REINITIALISATION
           *************************/
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="comptePasswordGlobal">
        <div className="compteHeader">
          <NavigateButton trad={this.state.trad}/>
          <h1>{this.state.trad.settingsHeading}</h1>
          {this.state.messageFlash !== null && (
            <div className="message_password_success">
              <p>{this.state.messageFlash}</p>
            </div>
          )}
        </div>

        <div className="comptePasswordContainer">
          <CompteNavMenu trad={this.state.trad}/>

          <div className="reset_password">
            <h2>{this.state.trad.settingsPasswordLabel}</h2>

            <div className="form_control">
              <label htmlFor="password_old">{this.state.trad.labelPasswordOld}</label>
              <input
                type="password"
                id="password_old"
                name="password_old"
                value={this.state.oldPassword}
                onChange={(e) => this.setState({ oldPassword: e.target.value })}
              />
              <img
                src={eye}
                alt="showPasswordButton"
                className="showPasswordButton"
                onClick={() => this.showHidePassword("password_old")}
              />
              {/* <button onClick={() => this.showHidePassword('password_old')}>Voir</button> */}

              <div className="message_password_warning">
                {this.state.message !== null && <p>{this.state.message}</p>}
              </div>
            </div>
            <div className="form_control">
              <label htmlFor="password_new">{this.state.trad.labelPasswordNew}</label>
              <input
                type="password"
                id="password_new"
                name="password_new"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <img
                src={eye}
                alt="showPasswordButton"
                className="showPasswordButton"
                onClick={() => this.showHidePassword("password_new")}
              />
              {/* <button onClick={() => this.showHidePassword("password_new")}>
                Voir
              </button> */}
              <div className="message_password_warning">
                {this.state.message2 !== null && <p>{this.state.message2}</p>}
              </div>
            </div>
            <div className="form_control">
              <label htmlFor="password_confirmation">{this.state.trad.labelPasswordConfirmation}</label>
              <input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                value={this.state.confirm_password}
                onChange={(e) =>
                  this.setState({ confirm_password: e.target.value })
                }
              />
              <img
                src={eye}
                alt="showPasswordButton"
                className="showPasswordButton"
                onClick={() => this.showHidePassword("password_confirmation")}
              />
              {/* <button
                onClick={() => this.showHidePassword("password_confirmation")}
              >
                Voir
              </button> */}
            </div>

            <p className="password_forgot" onClick={this.handlePasswordHidden}>{this.state.trad.forgotPassword}</p>

            <div className="form_submit">
              {" "}
              {this.state.sendLauch ? (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <img
                    src={this.state.lang === "fr" ? changeValidButton : changeValidButtonen}
                    alt="changeValidButton"
                    className="changeValidButton"
                    onClick={this.handleInscription}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
