import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";

import uploadVideoIcon from "../../../../assets/logos/noun-upload-3778990.svg";
import vignette1 from "../../../../assets/images/vignette1.jpg";
import {bannerHeight} from "../../modules/Helper.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class UploadVideo_part6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marginTopValue: "18rem", // Valeur initiale de margin-top de la div "left" pour que l'element image soit respnsive
      sendLauch: false,
      user: null,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    document.title = "Upload Video - PUBLICATION | Wonderplace";
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    let user = localStorage.getItem("user");
    bannerHeight();
    /*
    if (user !== null) {
      this.setState({
        user: JSON.parse(user),
      });
    } else {
      location.assign("/");
    }
     */
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }else {
      location.assign("/");
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  // Adapte "MarginTop" de la div "left"  afin de descendre en mobile
  handleResize = () => {
    const resolution = window.innerWidth;

    // Vérifier si la résolution est inférieure ou égale à 1280 pixels
    if (resolution < 1280) {
      // Calculer la nouvelle valeur "top" en fonction de la résolution
      const newMarginTopValue = 18 + resolution / 100; // Par exemple

      // Mettre à jour la valeur "MarginTop" dans le state
      this.setState({
        marginTopValue: newMarginTopValue + "rem",
      });
    } else {
      this.setState({
        marginTopValue: 0 + "rem",
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };

  handleUploadContinue = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="uploadVideoGlobal_Part5">
        <div className="uploadHeader">
          <h1>{this.state.trad.uploadHeader}</h1>
          <h2>{this.state.trad.uploadHeader2}</h2>
        </div>

        <div className="uploadContainer">
          <section
            className="left"
            style={{ marginTop: this.state.marginTopValue }}
          >
            <div className=""></div>
          </section>

          <section className="right">
            <div className="upload_VignetteSection">
              <img src={vignette1} alt="vignette" className="vignette" />
              {/* <div className="upload_VignetteText">
                <img src={uploadVideoIcon} alt="upload video" />
                <p>Vidéo en cours de chargement</p>
              </div> */}
            </div>
            <div className="upload_VignetteText">
              <p>Morose</p>
              <p>Damso</p>
            </div>
          </section>
        </div>

        <p>{this.state.trad.uploadTitle1}</p>
        <div className="navButtons">
          <NavigateBackButton />

          <div className="form_submit_container">
            {this.state.sendLauch ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <div className="form_submit">
                <Link to="/ma-page/upload-video-part7">
                  <img
                      src={this.state.lang === "fr" ? continuerButton : changeValidButtonen}
                    alt="continuer"
                    className="continuer"
                    // onClick={this.handleUploadContinue}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
