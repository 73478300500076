import React from "react";
import axios from "axios";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import MuiSwitch from "../../modules/MuiSwitch";
import NavigateButton from "../../modules/NavigateButton";
import CompteNavMenu from "../../modules/CompteNavMenu";
import changeValidButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";
import unlock from "../../../../assets/logos/cadena_open.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class CompteConfidentialite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      private_account: false,
      show_reposts: true,
      show_likes: true,
      show_public_playlists: true,
      show_collect_moments: true,
      show_followers_detail: true,
      show_follow_ups_detail: true,
      show_follow_places: true,
      show_fav_creators: true,
      show_only_followers_posts: true,
      user:null,
      blocked:[],
      lang:'en',
      trad:en,
    };
  }

  async componentDidMount() {
    document.title = "Compte | Confidentialite | Wonderplace";

    let user = localStorage.getItem('user');
    if (user === null) {
      location.assign('/');
    } else {
      let userConnect = JSON.parse(user);
      if (JSON.parse(localStorage.getItem("user"))) {
        this.setState({
          user: JSON.parse(localStorage.getItem("user")),
        }, () => {
          let lang = this.state.user.user.language;
          if(lang === "fr"){
            this.setState({lang:"fr",trad:fr});
          }
        });
      }
      await this.setState({
        user: userConnect
      }, () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/compte/compteUserLoad";
        axios.post(url, {
          data: "load"
        }, {
          headers: {
            Authorization: 'Bearer ' + userConnect.token
          }
        }).then(res => {
          this.setState({
            private_account: res.data.confidencial.privateAccount,
            show_reposts: res.data.confidencial.repostsAccount,
            show_likes: res.data.confidencial.likesAccount,
            show_public_playlists: res.data.confidencial.playlistAccount,
            show_collect_moments: res.data.confidencial.instantAccount,
            show_followers_detail: res.data.confidencial.followersAccount,
            show_follow_ups_detail: res.data.confidencial.suivisAccount,
            show_follow_places: res.data.confidencial.placesAccount,
            show_fav_creators: res.data.confidencial.creatorsFovorisAccount,
            show_only_followers_posts: res.data.confidencial.postsToFollowersAccount,
          })
        }).catch(error => {
          console.log(error);
        })
        let url2 = import.meta.env.VITE_BACKEND_URL + "/compte/blocked";
        axios.get(url2,{
          headers: {
            Authorization: 'Bearer ' + userConnect.token
          }
        }).then(res => {
          this.setState({blocked:res.data});
        }).catch(err => {
          console.log(err);
        })
      })
    }
  }
  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };
  handleInscription = () => {
    const {private_account,show_reposts,show_likes,show_public_playlists,show_collect_moments,show_followers_detail,show_follow_ups_detail,show_follow_places,show_fav_creators,show_only_followers_posts,user} = this.state;
    let url = import.meta.env.VITE_BACKEND_URL + "/compte/confidential/update";
    axios.post(url,{
      privateAccount:private_account,
      repostsAccount:show_reposts,
      likesAccount:show_likes,
      playlistAccount:show_public_playlists,
      instantAccount:show_collect_moments,
      followersAccount:show_followers_detail,
      suivisAccount:show_follow_ups_detail,
      placesAccount:show_follow_places,
      creatorsFovorisAccount:show_fav_creators,
      postsToFollowersAccount:show_only_followers_posts,
    },{
      headers: {
        Authorization: 'Bearer ' + user.token
      }
    }).then(res => {
      this.setState({
        private_account: res.data.privateAccount,
        show_reposts: res.data.repostsAccount,
        show_likes: res.data.likesAccount,
        show_public_playlists: res.data.playlistAccount,
        show_collect_moments: res.data.instantAccount,
        show_followers_detail: res.data.followersAccount,
        show_follow_ups_detail: res.data.suivisAccount,
        show_follow_places: res.data.placesAccount,
        show_fav_creators: res.data.creatorsFovorisAccount,
        show_only_followers_posts: res.data.postsToFollowersAccount,
      })
    }).catch(error => {
      console.log(error)
    })
  }
  handleRemoveBlocked = (index) => {
    let {blocked} = this.state;
    let url = import.meta.env.VITE_BACKEND_URL + "/compte/blockedSave";
    axios.post(url,{
      blocked:blocked[index],
    },{
      headers: {
        Authorization: 'Bearer ' + this.state.user.token
      }
    }).then(res =>{
      this.setState({blocked:res.data})
    }).catch(err => {
      console.log(err)
    })
  }
  render() {
    return (
      <div className="compteConfidentialiteGlobal">
        <div className="compteHeader">
          <NavigateButton trad={this.state.trad}/>
          <h1>{this.state.trad.settingsHeading}</h1>
        </div>

        <div className="compteConfidentialiteContainer">
          <CompteNavMenu trad={this.state.trad}/>

          <div className="Confidentialite_notif">
            <h2>{this.state.trad.settingsPrivacyHeading}</h2>

            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.private_account}
                        onChange={this.handleChange}
                        name="private_account"
                      />
                    }
                    label={`${
                      this.state.private_account
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText2}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_reposts}
                        onChange={this.handleChange}
                        name="show_reposts"
                      />
                    }
                    label={`${
                      this.state.show_reposts
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText3}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_likes}
                        onChange={this.handleChange}
                        name="show_likes"
                      />
                    }
                    label={`${
                      this.state.show_likes
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText4}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_public_playlists}
                        onChange={this.handleChange}
                        name="show_public_playlists"
                      />
                    }
                    label={`${
                      this.state.show_public_playlists
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText5}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_collect_moments}
                        onChange={this.handleChange}
                        name="show_collect_moments"
                      />
                    }
                    label={`${
                      this.state.show_collect_moments
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText6} </p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_followers_detail}
                        onChange={this.handleChange}
                        name="show_followers_detail"
                      />
                    }
                    label={`${
                      this.state.show_followers_detail
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText7}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_follow_ups_detail}
                        onChange={this.handleChange}
                        name="show_follow_ups_detail"
                      />
                    }
                    label={`${
                      this.state.show_follow_ups_detail
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText8}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_follow_places}
                        onChange={this.handleChange}
                        name="show_follow_places"
                      />
                    }
                    label={`${
                      this.state.show_follow_places
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText9}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_fav_creators}
                        onChange={this.handleChange}
                        name="show_fav_creators"
                      />
                    }
                    label={`${
                      this.state.show_fav_creators
                          ? this.state.trad.labelNewInformation
                          : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>

                <div className="notif">
                  <p className="description_text">{this.state.trad.condientialitéDescriptionText10}</p>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        size="small"
                        checked={this.state.show_only_followers_posts}
                        onChange={this.handleChange}
                        name="show_only_followers_posts"
                      />
                    }
                    /*
                    label={`${
                      this.state.show_only_followers_posts
                        ? "Désactiver"
                        : "Activer"
                    } la notification`}
                    */
                    label={`${
                        this.state.show_only_followers_posts
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                    }`}
                  />
                </div>
              </FormGroup>
            </FormControl>

            <div className="form_submit">
              <img
                  src={this.state.lang === "fr" ? changeValidButton : changeValidButtonen}
                  alt="changeValidButton"
                  className="changeValidButton"
                  onClick={this.handleInscription}
              />
            </div>
            <div className="blocked_user">
              <h2>{this.state.trad.settingsBlockedAccounts}</h2>
              <div className="blockline">
                {
                  this.state.blocked.length != 0 ?
                    this.state.blocked.map((value,index) =>(
                        <div className="line" key={index}>
                          <p>{value.utilisateur.name}</p>
                          <span className="btn" onClick={() => this.handleRemoveBlocked(index)}><img src={unlock} alt=""/></span>
                        </div>
                    ))
                      :
                      <div className="line">
                      <p>{this.state.trad.settingsBlockedLine}</p>
                      </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
