import React from "react";
import CropperAdv from "../../modules/CropperAdv";
import CalendarPicker from "./../CalendarPicker";
import moment from "moment";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import MuiSwitch from "../../modules/MuiSwitch";
import NavigateButton from "../../modules/NavigateButton";
import CompteNavMenu from "../../modules/CompteNavMenu";

import changeValidButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";
import axios from "axios";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class CompteGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            cropperAvatar: false,
            cropperCover: false,
            avatar: "",
            cover: "",
            avatarInput: "",
            coverInput: "",

            creator_mod: false,
            creator_verif: false,

            artisteName:null,
            userName: "",
            firstname: "",
            lastname: "",
            biographie: "",
            cara: 0,

            birthday: "",
            currentDate:"",

            email: "",
            phone_number: "",

            dark_mod: false,
            selectedOption: "en",
            trad:en,

            sendLauch: false,
            message: "",
        };
    }

    async componentDidMount() {
        document.title = "Compte | General | Wonderplace";
        let date = new Date();
        let formattedDate = date.toISOString().split('T')[0];
        this.setState({currentDate: formattedDate});
        let user = localStorage.getItem("user");
        if (user === null) {
            location.assign("/");
        } else {

            let userConnect = JSON.parse(user);
            await this.setState({user: userConnect,}, () => {
                    let lang = this.state.user.user.language;
                    if(lang === "fr"){
                        this.setState({lang:"fr",trad:fr});
                    }
                    let url = import.meta.env.VITE_BACKEND_URL + "/compte/compteUserLoad";
                    axios
                        .post(
                            url,
                            {
                                data: "load",
                            },
                            {
                                headers: {
                                    Authorization: "Bearer " + userConnect.token,
                                },
                            }
                        )
                        .then((res) => {
                            this.setState(
                                {
                                    artisteName:res.data.nom_artiste,
                                    avatar: res.data.personalisation.avatar !== null ? import.meta.env.VITE_BACKEND_URL_IMG + res.data.personalisation.avatar : null,
                                    cover: res.data.personalisation.cover !== null ? import.meta.env.VITE_BACKEND_URL_IMG + res.data.personalisation.cover : null,
                                    userName: res.data.name,
                                    firstname: res.data.personalisation.firstname,
                                    lastname: res.data.personalisation.lastname,
                                    biographie: res.data.personalisation.biographie,
                                    birthday: res.data.personalisation.birthday,
                                    email: res.data.email,
                                    phone_number:
                                        res.data.personalisation.phone === null
                                            ? ""
                                            : res.data.personalisation.phone,
                                    selectedOption: res.data.personalisation.language,
                                    name: res.data.name,
                                    creator_mod: res.data.creator === 1 ? true : false,
                                    creator_verif: res.data.creator_verif === 1 ? true : false,

                                }

                                // () => {
                                //   console.log(this.state);
                                // }
                            );
                        });
                }
            );
        }
    }

    closeModalCropp = () => {
        let elImg1 = document.querySelector(`#imageBase1`);
        let elImg2 = document.querySelector(`#imageBase2`);
        if (elImg1.value !== "") {
            document.querySelector(".plusAvatar").style.display = "none";
        }
        if (elImg2.value !== "") {
            document.querySelector(".plusCover").style.display = "none";
        }

        this.setState({
            cropperAvatar: false,
            cropperCover: false,
        });
    };
    handleOpenAvatar = () => {
        this.setState({cropperAvatar: true});
    };
    handleOpenCover = () => {
        this.setState({cropperCover: true});
    };
    handleChange = (event) => {
        this.setState({
            creator_mod: event.target.checked,
        });
    };
    handleChangeBio = (value) => {
        let cara = value.length;
        if (cara <= 100) {
            this.setState({
                cara,
                biographie: value,
            });
        } else {
            console.log("helllo");
        }
    };

    handleChangeDate = (day, month, year) => {
        let newDay = moment(day).date();
        let newMonth = moment(month).month();
        let newYear = moment(year).year();
        this.setState({
            jour: newDay,
            mois: newMonth + 1,
            annee: newYear,
        });
    };

    handleOptionChange = (event) => {
        this.setState({selectedOption: event.target.value});
    };
    options = [
        // { value: "", text: "-- Choisissez une langue svp --" },
        {value: "fr", text: "Français"},
        {value: "en", text: "Anglais"},
    ];

    handleInscription = async () => {
        this.setState(
            {
                sendLauch: true,
            },
            () => {
                const {
                    avatar,
                    cover,
                    avatarInput,
                    coverInput,
                    userName,
                    firstname,
                    lastname,
                    biographie,
                    email,
                    phone_number,
                    selectedOption,
                    artisteName,
                    birthday,
                } = this.state;
                let urlCheck =
                    import.meta.env.VITE_BACKEND_URL + "/utilisateur/checkUserName";
                axios
                    .post(urlCheck, {
                        id: this.state.user.id,
                        name: userName,
                    })
                    .then((res) => {
                        if (res.data === true) {
                            this.setState({
                                message: this.state.trad.messageUsername,
                            });
                        } else {
                            const url =
                                import.meta.env.VITE_BACKEND_URL + "/compte/general/update";
                            let data = {};
                            if (avatarInput !== "" && coverInput !== "") {
                                data = {
                                    artisteName,
                                    avatar,
                                    cover,
                                    firstname,
                                    lastname,
                                    biographie,
                                    email,
                                    birthday,
                                    phone: phone_number,
                                    selectedOption,
                                    name: userName,
                                    creator: this.state.creator_mod === true ? 1 : 0,
                                    lang:this.state.selectedOption

                                };
                            } else if (avatarInput !== "" && coverInput === "") {
                                data = {
                                    artisteName,
                                    avatar,
                                    firstname,
                                    lastname,
                                    biographie,
                                    email,
                                    birthday,
                                    phone: phone_number,
                                    selectedOption,
                                    name: userName,
                                    creator: this.state.creator_mod === true ? 1 : 0,
                                    lang:this.state.selectedOption

                                };
                            } else if (avatarInput === "" && coverInput !== "") {
                                data = {
                                    artisteName,
                                    cover,
                                    firstname,
                                    lastname,
                                    biographie,
                                    email,
                                    birthday,
                                    phone: phone_number,
                                    selectedOption,
                                    name: userName,
                                    creator: this.state.creator_mod === true ? 1 : 0,
                                    lang:this.state.selectedOption

                                };
                            } else {
                                data = {
                                    artisteName,
                                    firstname,
                                    lastname,
                                    biographie,
                                    email,
                                    birthday,
                                    phone: phone_number,
                                    selectedOption,
                                    name: userName,
                                    creator: this.state.creator_mod === true ? 1 : 0,
                                    lang:this.state.selectedOption
                                };
                            }
                            if (this.state.user !== null) {
                                axios
                                    .post(url, data, {
                                        headers: {
                                            Authorization: "Bearer " + this.state.user.token,
                                        },
                                    })
                                    .then((res) => {
                                        console.log(res.data);
                                         let lang = res.data.perso.language;
                                         this.setState({selectedOption:lang});
                                         let user = localStorage.getItem("user");
                                         user = JSON.parse(user);
                                         user.user.creator = res.data.creator;
                                         user.user.avatar = res.data.perso.avatar;
                                         user.user.lang = lang;
                                         user.user.language = lang;
                                         localStorage.setItem("user", JSON.stringify(user));
                                            if (this.state.creator_verif === false) {
                                                location.reload();
                                            } else {
                                                user.user.creator_verif = res.data.creator_verif;
                                                localStorage.setItem("user", JSON.stringify(user));
                                                location.reload();
                                            }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        }
                    });
            }
        );
    };
    callBackCropper = (base64Img, index) => {
        let user = localStorage.getItem("user");
        user = JSON.parse(user);
        if (index === 1) {
            this.setState(
                {
                    avatar: base64Img,
                    avatarInput: base64Img,
                },
                () => {
                    this.closeModalCropp();
                }
            );
        } else {
            this.setState(
                {
                    cover: base64Img,
                    coverInput: base64Img,
                },
                () => {
                    this.closeModalCropp();
                }
            );
        }
    };

    render() {
        return (
            <div className="compteGeneralGlobal">
                {this.state.cropperAvatar && (
                    <div className="modal">
                        <CropperAdv
                            ratio={1}
                            index={1}
                            closeModalCropp={this.closeModalCropp}
                            callBackCropper={this.callBackCropper}
                        />
                    </div>
                )}
                {this.state.cropperCover && (
                    <div className="modal">
                        <CropperAdv
                            ratio={16 / 9}
                            index={2}
                            closeModalCropp={this.closeModalCropp}
                            callBackCropper={this.callBackCropper}
                        />
                    </div>
                )}

                <div className="compteHeader">
                    <NavigateButton trad={this.state.trad}/>
                    <h1>{this.state.trad.settingsHeading}</h1>
                </div>

                <div className="compteGeneralContainer">
                    <CompteNavMenu trad={this.state.trad}/>

                    <div className="general">
                        <div className="general_infos">
                            <h2>{this.state.trad.generalInfos}</h2>

                            <div className="imageBlock">
                                <div className="avatar">
                                    <p className="avatarText">{this.state.trad.avatarText}</p>
                                    <div className="imgAvatar" onClick={this.handleOpenAvatar}>
                                        <p className="plusAvatar">+</p>
                                        {
                                            this.state.avatar !== "" &&
                                                <img
                                                    src={
                                                         this.state.avatar
                                                    }
                                                    id="imgPreview1"
                                                    alt=""
                                                />
                                        }
                                    </div>
                                    <input
                                        type="hidden"
                                        id="imageBase1"
                                        value={this.state.avatarInput || ""}
                                    />
                                </div>
                                <div className="cover">
                                    <p className="coverText">{this.state.trad.coverText}</p>
                                    <div className="imgCover" onClick={this.handleOpenCover}>
                                        <p className="plusCover">+</p>
                                        {
                                            this.state.cover !== "" &&
                                                <img
                                                    src={
                                                         this.state.cover
                                                    }
                                                    id="imgPreview2"
                                                    alt=""
                                                />
                                        }
                                    </div>
                                    <input
                                        type="hidden"
                                        id="imageBase2"
                                        value={this.state.coverInput || ""}
                                    />
                                </div>
                            </div>

                            <div className="notif">
                                <p className="description_text">{this.state.trad.changeMode}</p>
                                <FormControlLabel
                                    control={
                                        <MuiSwitch
                                            size="small"
                                            checked={this.state.creator_mod}
                                            onChange={this.handleChange}
                                            name="creator_mod"
                                        />
                                    }
                                    label={`${
                                        this.state.creator_mod 
                                            ? this.state.trad.removeCreator 
                                            : this.state.trad.becomeCreator
                                    }`}
                                />
                                {this.state.creator_mod === true &&
                                this.state.creator_verif === false ? (
                                    <p className="description_text creator_text">{this.state.trad.messageAwaitingValidationModerator}</p>
                                ) : this.state.creator_mod === true &&
                                this.state.creator_verif === true ? (
                                    <p className="description_text creator_text">{this.state.trad.youAreCreator}</p>
                                ) : null}
                            </div>
                            {
                                this.state.creator_mod === true &&
                                this.state.creator_verif === true ?
                                    <div className="nameBlock">
                                        <label htmlFor="userName">{this.state.trad.labelFirtsNameArtist}</label>
                                        <input
                                            type="text"
                                            id="userName"
                                            name="userName"
                                            value={this.state.artisteName || ""}
                                            onChange={(e) => this.setState({artisteName: e.target.value})}
                                        />
                                        {this.state.message !== "" && (
                                            <p className="warning">{this.state.message}</p>
                                        )}
                                    </div>
                                    : null
                            }
                            <div className="nameBlock">
                                <label htmlFor="userName">{this.state.trad.labelLastNameArtist}</label>
                                <input
                                    type="text"
                                    id="userName"
                                    name="userName"
                                    value={this.state.userName || ""}
                                    onChange={(e) => this.setState({userName: e.target.value})}
                                />
                                <p className="obligatoire">*{this.state.trad.pObligatoire}</p>
                                {this.state.message !== "" && (
                                    <p className="warning">{this.state.message}</p>
                                )}
                            </div>

                            <div className="nameBlock">
                                <label htmlFor="firstName">{this.state.trad.labelFirstName}</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={this.state.firstname || ""}
                                    onChange={(e) => this.setState({firstname: e.target.value})}
                                />
                            </div>

                            <div className="nameBlock">
                                <label htmlFor="lastName">{this.state.labelLastName}</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={this.state.lastname || ""}
                                    onChange={(e) => this.setState({lastname: e.target.value})}
                                />
                            </div>

                            <div className="biographie">
                                <label htmlFor="biographieArea">{this.state.trad.labelBiographieArea}</label>
                                <textarea
                                    name="biographieArea"
                                    id="biographieArea"
                                    cols="30"
                                    rows="5"
                                    maxLength="100"
                                    onChange={(e) => this.handleChangeBio(e.target.value)}
                                    value={this.state.biographie || ""}
                                />
                                <p className="obligatoire">{this.state.trad.charactersMax}( {this.state.cara} /100 )</p>
                            </div>
                        </div>

                        <div className="other_infos">
                            <h2>{this.state.trad.uploadOtherInfosArea}</h2>

                            <div className="calendar">
                                <label htmlFor="name">{this.state.trad.labelAnniversaire}*</label>
                                <input type="date" max={this.state.currentDate} value={this.state.birthday} onChange={(e) => this.setState({birthday:e.target.value})}/>
                                <p className="obligatoire">*{this.state.trad.pObligatoire}</p>
                            </div>

                            <div className="form_control">
                                <label htmlFor="email">{this.state.trad.labelEmail}</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={this.state.email || ""} /////// A FAIRE  //////
                                    onChange={(e) => this.setState({email: e.target.value})} /////// A FAIRE  //////
                                />
                            </div>

                            <div className="form_control">
                                <label htmlFor="phone_number">{this.state.trad.labelPhoneNumber}</label>
                                <input
                                    type="tel"
                                    id="phone_number"
                                    name="phone_number"
                                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    value={this.state.phone_number || ""} /////// A FAIRE  //////
                                    onChange={
                                        (e) => this.setState({phone_number: e.target.value}) /////// A FAIRE  //////
                                    }
                                />
                            </div>
                        </div>

                        <div className="other_infos_plus">
                            <h2>{this.state.trad.uploadOtherInfosArea}</h2>
                            <div className="languageSelect">
                                <label htmlFor="languageSelect_select">{this.state.trad.languageSelect}</label>
                                <select
                                    id="languageSelect_select"
                                    name="languageSelect_select"
                                    value={this.state.selectedOption || ""}
                                    onChange={this.handleOptionChange}
                                >
                                    {this.options.map((option) => (
                                        <option
                                            id="languageSelect_option"
                                            name="languageSelect_option"
                                            key={option.value}
                                            value={option.value || ""}
                                        >
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="form_submit">
                            {this.state.sendLauch ? (
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (
                                <img
                                    src={this.state.lang === "fr" ? changeValidButton : changeValidButtonen}
                                    alt="changeValidButton"
                                    className="changeValidButton"
                                    onClick={this.handleInscription}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
