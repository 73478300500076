import React, {useState, useEffect } from "react";
import {Link} from "react-router-dom";
import mobileNavToggleLogo from "../../../assets/logos/mobileNavToggleLogo.svg";
import connexionButton from "../../../assets/logos/connexionButton.svg";
import inscriptionButton from "../../../assets/logos/inscriptionButton.svg";
import uploadVideo from "../../../assets/logos/uploadVideo.svg";
import connectWallet from "../../../assets/logos/connectWallet.svg";
import axios from "axios";
import maison from "../../../assets/logos/maison.svg";
import world from "../../../assets/logos/world.svg";
import people from "../../../assets/logos/people.svg";
import cloche from "../../../assets/logos/cloche.svg";
import panier from "../../../assets/logos/panier.svg";
import loupe from "../../../assets/logos/loupe.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
import uploaden from "../../../assets/logos/en/uploaden.svg";
import connectWalleten from "../../../assets/logos/en/connectWalleten.svg";
import connexionButtonen from "../../../assets/logos/en/connexionButtonen.svg";
import inscriptionButtonen from "../../../assets/logos/en/inscriptionButtonen.svg";

const HeaderMobileNav = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [user,setUser] = useState();
    const [search,setSearch] = useState();
    const [trad,setTrad] = useState(props.trad);
    const [lang,setLang] = useState(props.lang);

    useEffect(() => {

        setUser(props.user);

    },[user,lang,trad]);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleLogout = (e) => {
        e.preventDefault();
        let url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/deconnexion";
        axios.post(url,{
            message:"logout"
        },{
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(res => {
            if(res.data.message === "Deconnexion"){
                localStorage.removeItem('user');
                location.assign('/');
            }
        }).catch(error => {
            console.log(error)
        })
    }
    const handleOpenConnexion = () => {
        props.handleOpenConnexion();
        setIsOpen(false);
    }
    const handleGotoInscription = () => {
        setIsOpen(false);
        location.assign('/inscription');
    }
    const searchResult = () => {

    }
    return (
        <>
            <button className="mobileNavToggle" onClick={handleToggle}>
                <img
                    src={mobileNavToggleLogo}
                    className="mobileNavToggleLogo"
                    alt="mobileNavToggleLogo"
                />
            </button>

            {isOpen && (
                <div className="mobileNavContainer">
                    {user !== null &&
                        <div className="barreUser">
                            <Link to="/compte">
                                <img src={import.meta.env.VITE_BACKEND_URL_IMG +user.user.avatar} className="iconUser" alt=""/>
                            </Link>
                            <a onClick={(e) => handleLogout(e)}>{trad.headerNavSub3}</a>
                        </div>
                    }
                    <div className="linkIconText">
                        <Link to="/">
                            <p className="acceuil"><img src={maison} className="maisonLogo" alt="maisonLogo"/>{trad.headerNavSub5}</p>
                        </Link>
                        <Link to="/">
                            <p className="explorer"><img src={world} className="worldLogo" alt="worldLogo"/> {trad.headerNavSub6}</p>
                        </Link>
                        <Link to="/">
                            <p className="places"><img src={people} className="peopleLogo" alt="peopleLogo"/> {trad.headerNavSub7}</p>
                        </Link>
                        <Link to="/">
                            <p className="notif"><img src={cloche} className="clocheLogo" alt="clocheLogo"/> {trad.settingsNotificationsLabel}</p>
                        </Link>
                        <Link to="/">
                            <p className="panier"><img src={panier} className="panierLogo" alt="panierLogo"/> {trad.headerNavSub8}</p>
                        </Link>
                    </div>


                    {user !== null ?
                        <>
                            {
                                user.user.creator ?
                                    <img
                                        src={lang === "fr" ? uploadVideo : uploaden}
                                        className="uploadVideoLogo"
                                        alt="uploadVideoLogo"
                                    />
                                    :
                                    <img
                                        src={lang === "fr" ? connectWallet : connectWalleten}
                                        className="connectWalletLogo"
                                        alt="connectWalletLogo"
                                    />
                            }
                        </>
                        :
                        <>
                            <img
                                src={lang === "fr" ? connexionButton : connexionButtonen}
                                className="connexionButtonLogo"
                                alt="connexionButtonLogo"
                                onClick={handleOpenConnexion}
                            />

                            <img
                                src={lang === "fr" ? inscriptionButton : inscriptionButtonen}
                                className="connexionButtonLogo"
                                alt="inscriptionButtonLogo"
                                onClick={handleGotoInscription}
                            />
                        </>
                    }
                    <div className="headerSearchbar">
                        <input
                            className="searchbar"
                            type="text"
                            placeholder="Search here"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <img
                            src={loupe}
                            onClick={searchResult}
                            className="loupeLogo"
                            alt="loupeLogo"
                        />
                    </div>
                </div>
            )}
        </>
    );
};
export default HeaderMobileNav;
