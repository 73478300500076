import React from "react";
import "video.js/dist/video-js.css";

import axios from "axios";
import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockVideo:null,
      videos: [],
      videosToBuy: [],
      videosBought: [],
      playVideoId: 0,
      user: null,
      currentTime: 30,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }
    document.querySelector("body").classList.add("home");
    document.querySelector(".img_cover").style.backgroundImage = "none";

    let url = import.meta.env.VITE_BACKEND_URL + "/videos/all";
    let artistInfos = [];
    axios.get(url).then((res) => {
      res.data.map((value, index) => {
        console.log(value);
        let video = {};
        let mini = value.miniature != "" ? value.miniature : "";
        video.isPlay = index == 0 ? true : false;
        video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
        video.type = "video/mp4";
        video.poster = import.meta.env.VITE_BACKEND_URL_IMG + mini;
        video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
        video.artistName =
        value.utilisateur.lastname + " " + value.utilisateur.firstname;
        video.artistTitle = value.utilisateur.name;
        video.artistDescription = value.utilisateur.biographie;
        video.artistMoreDetails = "Plus d’informations…";
        video.hasLiked = false;
        video.isLiked = 0;
        video.isCommented = 0;
        video.isBoorkmarked = 0;
        video.isFollowed = 0;
        video.genreIcon = [];
        video.videosToBuy = []
        //video.videosToBuy = [
        //  { startTime: 0, endTime: 5, label: "Disponible" },
        //  { startTime: 20, endTime: 25, label: "Disponible" },
        //  { startTime: 50, endTime: 55, label: "Disponible" },
        //];
        video.videosBought = []
        //video.videosBought = [
        //  { startTime: 40, endTime: 45, label: "Indisponible" },
        //  { startTime: 130, endTime: 135, label: "Indisponible" },
        //];
        artistInfos.push(video);
      });
      this.setState({
        videos: artistInfos,
      });
    });

    /*
        let artistInfos = [
          {
            isPlay: true,
            src: silhouette_naruto_shippuden_guitar_cover,
            type: "video/mp4",
            poster: "",

            artistPhoto: photoDamso,
            artistName: "Γ. Mosaïque Solitaire",
            artistTitle: "Damso",
            artistDescription: "Lorem ipsum dolor etsi",
            artistMoreDetails: "Plus d’informations…",

            hasLiked: false,
            isLiked: 0,
            isCommented: 0,
            isBoorkmarked: 0,
            isFollowed: 0,
            genreIcon: [],
          },
          {
            isPlay: false,
            src: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
            type: "video/mp4",
            poster: "https://picsum.photos/800/600",

            artistPhoto: photoDamso,
            artistName: "douhwap",
            artistTitle: "bipbopbap",
            artistDescription: "je suis une description",
            artistMoreDetails: "envoi les infos",

            hasLiked: false,
            isLiked: 1,
            isCommented: 2,
            isBoorkmarked: 3,
            isFollowed: 4,
            genreIcon: [],
          },
          {
            isPlay: false,
            src: "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4",
            type: "video/mp4",
            poster: "",

            artistPhoto: photoDamso,
            artistName: "Artist name",
            artistTitle: "Artist title",
            artistDescription: "description autre",
            artistMoreDetails: "des details",

            hasLiked: false,
            isLiked: 6,
            isCommented: 2,
            isBoorkmarked: 4,
            isFollowed: 3,
            genreIcon: [],
          },
        ];
        */
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      });
    }
  }


  handleCallBackPlay = (id) => {
    const { videos } = this.state;
    const updatedVideos = videos.map((value, index) => ({
      ...value,
      isPlay: index === id,
    }));
    this.setState({ videos: updatedVideos });
  };

  render() {
    return (
        <div className="wallet">
          <h1>{this.state.trad.creatorText5}</h1>
        </div>
    );
  }
}
