import React, {Component} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";

import createPlaces from '../../../../assets/images/1.png';
import searchPlaces from '../../../../assets/images/5.png';
import AddPlaces from "../../modules/AddPlaces.jsx";
import play_compte from "../../../../assets/logos/play_compte.png";
import cadena from "../../../../assets/logos/cadena.svg";
import dot from '../../../../assets/logos/dot_horizontal.svg'

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class Myplaces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            places: [],
            currentPlace:null,
            openModalPlaces: false,
            user: null,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        document.title = "Places | Wonderplace";
        document.querySelector("body").classList.add("myplace");
        document.querySelector("body").classList.remove("home");
        document.querySelector(".img_cover").style.display = "block";
        document.querySelector(".img_cover").style.backgroundImage = "linear-gradient(rgba(255, 255, 255, 0.2), rgb(0, 0, 0)), url(src/assets/backgrounds/backgroundCompte.jpg)";

        let user = localStorage.getItem('user');
        if (user === null) {
            location.assign('/');
        } else {
            this.setState({user: JSON.parse(user)}, () => {
                let lang = this.state.user.user.language;
                const url = import.meta.env.VITE_BACKEND_URL + "/places/allplacesuser";
                axios.get(url, {
                    headers: {
                        Authorization: "Bearer " + this.state.user.token,
                    },
                }).then(res => {
                    this.setState({places: res.data});
                }).catch(err => {
                    console.log(err);
                })
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }

    }

    handleAddPlaces = () => {
        this.setState({openModalPlaces: true})
    }
    handleCloseModal = () => {
        this.setState({openModalPlaces: false,currentPlace: null})
    }
    handleSearchPlaces = () => {
        location.assign('/nouvelles-places');
    }
    callbackAddPlaces = (places) => {
        this.setState({places: places, openModalPlaces: false});
    }

    handleEpinglePlace = (index) => {
        const {places} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/epingle/add";
        axios.post(url,{
            id:places[index].id,
            type:"places",
        },{
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            let id = `menu_${index}`;
            let menu = document.getElementById(id);
            menu.style.display = "none";
        }).catch(err => {
            console.log(err);
        })
    }

    handleOpenMenuDot = (index) => {
        let id = `menu_${index}`;
        let menu = document.getElementById(id);
        if(menu.style.display === "block"){
            menu.style.display = "none";
        }else{
            menu.style.display = "block";
        }
    }

    handleModifierPlace = (index) => {
        const {places} = this.state;
        let currentPlace = places[index];
        this.setState({currentPlace,openModalPlaces: true})
    }

    render() {
        return (
            <>
                {
                    this.state.openModalPlaces &&
                    <AddPlaces handleCloseModal={this.handleCloseModal} callbackAddPlaces={this.callbackAddPlaces}
                               user={this.state.user} currentPlace={this.state.currentPlace}/>

                }
                <h1>{this.state.trad.footerFourthColumn5}</h1>
                <div className="placesHeader">
                    <div className="block_places_actions" onClick={this.handleAddPlaces}>
                        <div className="imgBtn">
                            <img src={searchPlaces} alt=""/>
                            <p className="plusIcon">+</p>
                        </div>
                        <div className="textAction">
                            <p>{this.state.trad.pDescriptionPlaces6}</p>
                        </div>
                    </div>
                    <div className="block_places_actions" onClick={this.handleSearchPlaces}>
                        <div className="imgBtn">
                            <img src={createPlaces} alt=""/>
                            <p className="plusIcon">+</p>
                        </div>
                        <div className="textAction">
                            <p>{this.state.trad.pDescriptionPlaces7}</p>
                        </div>
                    </div>
                </div>
                <div className="mycommunautes">
                    <h2>{this.state.trad.pDescriptionPlaces8}</h2>
                    {
                        this.state.places[0] &&
                        this.state.places.map((value, index) => (
                            <div key={index} className="block_my_commu">
                                <div className="up_block_commu">
                                    <div className="left">
                                        <div className="menu">
                                            <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                            <ul id={`menu_${index}`}>
                                                <li onClick={() => this.handleEpinglePlace(index)}>{this.state.trad.pDescriptionPlaces9}</li>
                                                {
                                                    value.utilisateur_id === this.state.user.user.id &&
                                                        <li onClick={() => this.handleModifierPlace(index)}>{this.state.trad.pDescriptionPlaces10}</li>
                                                }
                                            </ul>
                                        </div>
                                        <img
                                            src={value.image_place !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image_place : searchPlaces}
                                            alt=""/>
                                        {
                                            value.private == 1 &&
                                            <div className="cadena">
                                                <img className="cadena" src={cadena} alt=""/>
                                            </div>
                                        }
                                    </div>
                                    <Link to={`/place/${value.id}`}>
                                        <div className="right">
                                            <h3>{value.name} <span>{value.city}, {value.country}</span></h3>
                                            <p><span className="number"></span>  {this.state.trad.pDescriptionPlaces12}  <span className="avatars_liste"></span></p>
                                        </div>
                                    </Link>

                                    <div className="play_block">
                                        <img src={play_compte} alt=""/>
                                        <p>{this.state.trad.pDescriptionPlaces11}</p>
                                    </div>

                                </div>
                                <div className="block_down_commu">
                                    {
                                        value.actus !== null &&
                                        <>
                                            <div className="left">
                                                <img src={import.meta.env.VITE_BACKEND_URL_IMG + value.actus.user.avatar}
                                                     alt=""/>
                                            </div>
                                            <div className="right">
                                                <p className="name_user">
                                                    {value.actus.user.name}
                                                </p>
                                                <p className="messagePlace">
                                                    {value.actus.message}
                                                </p>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }

}
