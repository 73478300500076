import React, { useState } from "react";
import {Link} from "react-router-dom";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
export default function StorageSelection({trad}) {
    // Récupérer la valeur du type de stockage précédemment sélectionné, s'il existe
    const storedStorageType =
        localStorage.getItem("storageType") ||
        sessionStorage.getItem("storageType");

    // Définir l'état initial du type de stockage avec la valeur précédente ou une chaîne vide
    const [storageType, setStorageType] = useState(storedStorageType || "");

    // Gérer la sélection du type de stockage
    const handleStorageSelection = (type) => {
        if (type === "localStorage") {
            setStorageType("localStorage");
            localStorage.setItem("storageType", "localStorage");
        }
    };


    if (!storageType) {
        return (
            <div className="storage_selection_container">
                <div className="message_container">
                    <p className="warning_message">
                        {trad.labelValidCondition2} <Link to="/">{trad.labelValidCondition3}</Link>
                    </p>
                </div>

                <button onClick={() => handleStorageSelection("localStorage")}>
                    OK {/* localStorage */}
                </button>
            </div>
        );
    }
}
