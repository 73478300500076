import React, {Component} from 'react';
import axios from "axios";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

import recap from '../../../../assets/logos/recap.svg';
import head from '../../../../assets/logos/videoLikeIcon.svg';
import tens from '../../../../assets/logos/tendance.svg';
import news from '../../../../assets/logos/new.svg';


import background from "../../../../assets/backgrounds/backgroundCompte.jpg"
import {parseInt} from "lodash";
import vignette4 from "../../../../assets/images/vignette4.jpg";
import eye from "../../../../assets/logos/eye.svg";
import playlistDefault from "../../../../assets/images/5.png";
import cadena from "../../../../assets/logos/cadena.svg";

import tendance1 from "../../../../assets/images/tendances/1.png"
import tendance2 from "../../../../assets/images/tendances/1_2.png"
import tendance3 from "../../../../assets/images/tendances/2.png"
import tendance4 from "../../../../assets/images/tendances/2_2.png"
import tendance5 from "../../../../assets/images/tendances/5.png"

export default class Explorer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weekImage: null,
            header: null,
            lang: 'en',
            trad: en,
            page: 'recap',
            nouveau: null,
            bestLike: null,
            tendance: null,
            ambience: null
        }
    }

    componentDidMount() {
        document.querySelector("body").classList.add("explorer");
        let bg = document.querySelector('.img_cover');
        bg.style.backgroundImage = `url(${background})`;
        bg.style.height = "";
        bg.style.display = "block";
        let url = import.meta.env.VITE_BACKEND_URL + "/explorer/get";
        axios.get(url).then(res => {
            this.setState({
                header: Object.values(res.data.header),
                weekImage: res.data.weekImage,
                nouveau: res.data.nouveau,
                bestLike: res.data.bestLike,
                tendance: res.data.tendance,
                ambience: res.data.ambience
            })
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        }
        if (screen.width < 1280) {
            let carrousel = document.getElementById('header_explorer');
            carrousel.style.marginLeft = "0px"
            this.interval = setInterval(() => {
                if (carrousel.style.marginLeft === "-1000px") {
                    carrousel.style.marginLeft = "0px"
                } else {
                    carrousel.style.marginLeft = parseInt(carrousel.style.marginLeft) - 250 + 'px';
                }
            }, 4000);
        }
    }

    componentWillUnmount() {
        // Assurez-vous de nettoyer votre setInterval lorsque le composant est démonté
        clearInterval(this.interval);
    }

    handleChangePage = (page) => {
        this.setState({page},() => {

        });
    }

    render() {
        const {weekImage} = this.state;
        return (
            <>
                <div className="headerpage">
                    <h1>explorer</h1>
                    <div className="header_explorer" id="header_explorer">
                        {
                            this.state.header !== null &&
                            this.state.header.map((value, index) => (
                                <div key={index} className="block">
                                    {
                                        index == 0 &&
                                        <a href={`/page-de-profil/${value.id}`}>
                                            <div className="img">
                                                <img
                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + "/week/" + weekImage[0].url}
                                                    alt=""/>
                                                <div className="titre">
                                                    <p>{value.nom_artiste}</p>
                                                </div>
                                            </div>
                                            <div className="soustitle">
                                                <p>{this.state.trad.pDescriptionPlaces}</p>
                                            </div>
                                        </a>

                                    }
                                    {
                                        index == 1 &&
                                        <a href={`/playlist/${value.id}`}>
                                            <div className="img">
                                                <img
                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + "/week/" + weekImage[1].url}
                                                    alt=""/>
                                                <div className="titre">
                                                    <p>{value.name}</p>
                                                    <p>par {value.user.name}</p>
                                                </div>
                                            </div>
                                            <div className="soustitle">
                                                <p>{this.state.trad.pDescriptionPlaces1}</p>
                                            </div>
                                        </a>
                                    }
                                    {
                                        index == 2 &&
                                        <a href={`/place/${value.id}`}>
                                            <div className="img">
                                                <img
                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + "/week/" + weekImage[2].url}
                                                    alt=""/>
                                                <div className="titre">
                                                    <p>{value.name}</p>
                                                    <p>#{value.city}</p>
                                                </div>
                                            </div>
                                            <div className="soustitle">
                                                <p>{this.state.trad.pDescriptionPlaces2}</p>
                                            </div>
                                        </a>
                                    }
                                    {
                                        index == 3 &&
                                        <a href="">
                                            <div className="img">
                                                <img
                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + "/week/" + weekImage[2].url}
                                                    alt=""/>
                                                <div className="titre">
                                                    <p>{value.name}</p>
                                                </div>
                                            </div>
                                            <div className="soustitle">
                                                <p>{this.state.trad.pDescriptionPlaces3}</p>
                                            </div>
                                        </a>
                                    }
                                    {
                                        index == 4 &&
                                        <a href={`/video/${value.token}`}>
                                            <div className="img">
                                                <img
                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + "/week/" + weekImage[4].url}
                                                    alt=""/>
                                                <div className="titre">
                                                    <p className="light">{value.title}</p>
                                                    <p>{value.utilisateur.nom_artiste}</p>
                                                </div>
                                            </div>
                                            <div className="soustitle">
                                                <p>{this.state.trad.pDescriptionPlaces4}</p>
                                            </div>
                                        </a>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="contentMenu">
                    <div className="menu">
                        <div onClick={() => this.handleChangePage('recap')}
                             className={`link ${this.state.page === "recap" ? "active" : ""}`}>
                            <img src={recap} alt=""/>
                            <p>{this.state.trad.creatorText1}</p>
                        </div>
                        <div onClick={() => this.handleChangePage('new')}
                             className={`link ${this.state.page === "new" ? "active" : ""}`}>
                            <img src={news} alt=""/>
                            <p>{this.state.trad.nouveautes}</p>
                        </div>
                        <div onClick={() => this.handleChangePage('tend')}
                             className={`link ${this.state.page === "tend" ? "active" : ""}`}>
                            <img src={tens} alt=""/>
                            <p>{this.state.trad.tendances}</p>
                        </div>
                        <div onClick={() => this.handleChangePage('coeur')}
                             className={`link ${this.state.page === "coeur" ? "active" : ""}`}>
                            <img src={head} alt=""/>
                            <p>{this.state.trad.coeur}</p>
                        </div>
                    </div>
                </div>
                <div className="contentpage">
                    {
                        this.state.page === "recap" &&
                        <div className="list">
                            <h2>Nouveautés ></h2>
                            <div className="block_map">
                                {
                                    this.state.nouveau &&
                                    this.state.nouveau.videos &&
                                    this.state.nouveau.videos.map((value, index) => (
                                        <div className="block_video" key={index}>
                                            <div className="img">
                                                <a href="">
                                                    <img
                                                        src={value.miniature !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : vignette4}
                                                        alt=""/>
                                                    <p className="time">{value.time}</p>

                                                </a>
                                            </div>
                                            <div className="info_video">
                                                <p className="title">{value.title}</p>
                                                {
                                                    value.utilisateur &&
                                                    value.utilisateur.nom_artiste &&
                                                    <p className="creator">{value.utilisateur.nom_artiste}</p>
                                                }
                                                <p className="view"><img src={eye} alt=""/> 200</p>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    this.state.nouveau &&
                                    this.state.nouveau.creators &&
                                    <div className="block_creator">
                                        <a href={`/page-de-profil/${this.state.nouveau.creators.id}`}>
                                            <div className="img">
                                                <img
                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + this.state.nouveau.creators.personal.avatar}
                                                    alt=""/>
                                            </div>
                                            <div className="titre">
                                                <p>{this.state.nouveau.creators.nom_artiste}</p>
                                            </div>
                                        </a>
                                    </div>

                                }
                                {
                                    this.state.nouveau &&
                                    this.state.nouveau.playlist &&
                                    <div className="block_playlist">
                                        <div className="left">
                                            <a href={`/playlist/${this.state.nouveau.playlist.id}`}>
                                                <img
                                                    src={this.state.nouveau.playlist.image !== null ? import.meta.env.VITE_BACKEND_URL_IMG + this.state.nouveau.playlist.image : playlistDefault}
                                                    alt=""/>
                                                {
                                                    this.state.nouveau.playlist.public === 1 &&
                                                    <img className="cadena" src={cadena} alt=""/>
                                                }
                                            </a>
                                        </div>
                                        <div className="right">
                                            <p>{this.state.nouveau.playlist.name}</p>
                                            <p>par {this.state.nouveau.playlist.user.name}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.nouveau &&
                                    this.state.nouveau.place &&
                                    <div className="block_playlist">
                                        <div className="left">
                                            <a href={`/place/${this.state.nouveau.place.id}`}>
                                                <img
                                                    src={this.state.nouveau.place !== null ? import.meta.env.VITE_BACKEND_URL_IMG + this.state.nouveau.place.image_place : playlistDefault}
                                                    alt=""/>
                                            </a>
                                        </div>
                                        <div className="right">
                                            <p>{this.state.nouveau.place.name}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="ambiance">
                                <h2>Ambiances ></h2>
                            </div>
                            <div className="tendances">
                                <h2>Tendances ></h2>
                                <div className="block_tendance">
                                    <div className="block">
                                        <a href="">
                                            <img src={tendance1} alt=""/>
                                            <p>Top 10<br/>
                                                Creators</p>
                                        </a>
                                    </div>
                                    <div className="block">
                                        <a href="">
                                            <img src={tendance5} alt=""/>
                                            <p>Top 10<br/>
                                                Playlists</p>
                                        </a>
                                    </div>
                                    <div className="block">
                                        <a href="">
                                            <img src={tendance3} alt=""/>
                                            <p>Top 10<br/>
                                                Places</p>
                                        </a>
                                    </div>
                                    <div className="block">
                                        <a href="">
                                            <img src={tendance4} alt=""/>
                                            <p>Top 10<br/>
                                                Instants</p>
                                        </a>
                                    </div>
                                    <div className="block">
                                        <a href="">
                                            <img src={tendance2} alt=""/>
                                            <p>Top 10<br/>
                                                Vidéos</p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="bestlike">
                                <h2>La communauté a aimé ></h2>
                                <div className="block_map">
                                    {
                                        this.state.bestLike &&
                                        this.state.bestLike.map((value, index) => (
                                            <div className="block_video" key={index}>
                                                <div className="img">
                                                    <a href="">
                                                        <img
                                                            src={value.miniature !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.miniature : vignette4}
                                                            alt=""/>
                                                        <p className="time">{value.time}</p>

                                                    </a>
                                                </div>
                                                <div className="info_video">
                                                    <p className="title">{value.title}</p>
                                                    {
                                                        value.utilisateur &&
                                                        value.utilisateur.nom_artiste &&
                                                        <p className="creator">{value.utilisateur.nom_artiste}</p>
                                                    }
                                                    <p className="view"><img src={eye} alt=""/> 200</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }

}
