import React from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Grid from "@mui/material/Grid";
import MuiSwitch from "../../modules/MuiSwitch";
import NavigateButton from "../../modules/NavigateButton";
import CompteNavMenu from "../../modules/CompteNavMenu";
import changeValidButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
export default class CompteNotif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_publication: true,
      new_com: true,
      new_member: true,
      new_like: true,
      new_com_on_com: true,
      new_artist: true,
      new_chat: true,
      new_follower: true,
      new_email: true,
      new_promo: true,
      new_information: true,
      user:null,
      sendLauch: false,
      lang:'en',
      trad:en,
    };
  }

  async componentDidMount() {
    document.title = "Compte | Notifications | Wonderplace";
    let user = localStorage.getItem('user');
    if (user === null) {
      location.assign('/');
    } else {
      let userConnect = JSON.parse(user);
      if (JSON.parse(localStorage.getItem("user"))) {
        this.setState({
          user: JSON.parse(localStorage.getItem("user")),
        }, () => {
          let lang = this.state.user.user.language;
          if(lang === "fr"){
            this.setState({lang:"fr",trad:fr});
          }
        });
      }
      await this.setState({
        user: userConnect
      }, () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/compte/compteUserLoad";
        axios.post(url, {
          data: "load"
        }, {
          headers: {
            Authorization: 'Bearer ' + userConnect.token
          }
        }).then(res => {
          this.setState({
            new_publication: res.data.notification.commu_publication,
            new_com: res.data.notification.commu_commentaire,
            new_member: res.data.notification.commu_new_membre,
            new_like: res.data.notification.commu_new_like_com,
            new_com_on_com: res.data.notification.commu_new_com,
            new_artist: res.data.notification.commu_new_artiste_suivi,
            new_chat: res.data.notification.commu_new_chat,
            new_follower: res.data.notification.commu_new_follower,
            new_email: res.data.notification.email_info,
            new_promo: res.data.notification.email_promo,
            new_information: res.data.notification.email_rappel,
          })
        }).catch(error => {
          console.log(error);
        })
      })
    }
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };
  handleInscription = () => {
    const {new_publication,new_com,new_member,new_like,new_com_on_com,new_artist,new_chat,new_follower,new_email,new_promo,new_information,user} = this.state;
    let url = import.meta.env.VITE_BACKEND_URL + "/compte/notification/update";
    this.setState({
      sendLauch:true
    },() => {
      axios.post(url,{
        commu_publication:new_publication ,
        commu_commentaire: new_com ,
        commu_new_membre:new_member ,
        commu_new_like_com:new_like ,
        commu_new_com:new_com_on_com ,
        commu_new_artiste_suivi:new_artist ,
        commu_new_chat:new_chat ,
        commu_new_follower:new_follower ,
        email_info:new_email ,
        email_promo:new_promo ,
        email_rappel:new_information ,
      },{
        headers: {
          Authorization: 'Bearer ' + user.token
        }
      }).then(res => {
        this.setState({
          new_publication: res.data.commu_publication,
          new_com: res.data.commu_commentaire,
          new_member: res.data.commu_new_membre,
          new_like: res.data.commu_new_like_com,
          new_com_on_com: res.data.commu_new_com,
          new_artist: res.data.commu_new_artiste_suivi,
          new_chat: res.data.commu_new_chat,
          new_follower: res.data.commu_new_follower,
          new_email: res.data.email_info,
          new_promo: res.data.email_promo,
          new_information: res.data.email_rappel,
          sendLauch:false,
        })
      }).catch(error =>{
        console.log(error);
      })

    })

  }
  render() {
    return (
      <div className="compteNotifGlobal">
        <div className="compteHeader">
          <NavigateButton trad={this.state.trad}/>
          <h1>{this.state.trad.settingsHeading}</h1>
        </div>

        <div className="compteNotifContainer">
          <CompteNavMenu trad={this.state.trad}/>

          <div className="notif_all">
            <div className="notif_push">
              <h2>{this.state.trad.notificationsPush}</h2>

              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {/* <Grid
                    component="label"
                    container
                    alignItems="center"
                    // spacing={5}
                    class="class_test"
                  >
                    <Grid class="description-switch" item>
                      <Grid class="description_text" item>
                        "Nouvelle publication dans la communauté
                      </Grid>
                      <Grid class="mui-switch" item>
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_publication}
                          onChange={this.handleChange}
                          name="new_publication"
                        />
                      </Grid>
                    </Grid>

                    <Grid class="disable-text" item>
                      Désactiver la notification
                    </Grid>
                  </Grid> */}
                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_publication}
                          onChange={this.handleChange}
                          name="new_publication"
                        />
                      }
                      label={`${
                        this.state.new_publication 
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText2}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_com}
                          onChange={this.handleChange}
                          name="new_com"
                        />
                      }
                      label={`${
                        this.state.new_com
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText3}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_member}
                          onChange={this.handleChange}
                          name="new_member"
                        />
                      }
                      label={`${
                        this.state.new_member
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText4}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_like}
                          onChange={this.handleChange}
                          name="new_like"
                        />
                      }
                      label={`${
                        this.state.new_like
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText5}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_com_on_com}
                          onChange={this.handleChange}
                          name="new_com_on_com"
                        />
                      }
                      label={`${
                        this.state.new_com_on_com
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText6}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_artist}
                          onChange={this.handleChange}
                          name="new_artist"
                        />
                      }
                      label={`${
                        this.state.new_artist
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText7}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_chat}
                          onChange={this.handleChange}
                          name="new_chat"
                        />
                      }
                      label={`${
                        this.state.new_chat
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText8}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_follower}
                          onChange={this.handleChange}
                          name="new_follower"
                        />
                      }
                      label={`${
                        this.state.new_follower
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>
                </FormGroup>
              </FormControl>
            </div>

            <div className="notif_mail">
              <h2>{this.state.trad.emailNotification}</h2>

              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText9}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_email}
                          onChange={this.handleChange}
                          name="new_email"
                        />
                      }
                      label={`${
                        this.state.new_email
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText10}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_promo}
                          onChange={this.handleChange}
                          name="new_promo"
                        />
                      }
                      label={`${
                        this.state.new_promo
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>

                  <div className="notif">
                    <p className="description_text">{this.state.trad.notifDescriptionText11}</p>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          size="small"
                          checked={this.state.new_information}
                          onChange={this.handleChange}
                          name="new_information"
                        />
                      }
                      label={`${
                        this.state.new_information
                            ? this.state.trad.labelNewInformation
                            : this.state.trad.labelNewInformation2
                      }`}
                    />
                  </div>
                </FormGroup>
              </FormControl>
            </div>

            <div className="form_submit">
              {
                this.state.sendLauch ?
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    :
              <img
                  src={this.state.lang === "fr" ? changeValidButton : changeValidButtonen}
                  alt="changeValidButton"
                  className="changeValidButton"
                  onClick={this.handleInscription}
              />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
