import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const LinearDeterminate = (props) => {
    const [progress, setProgress] = React.useState(props.progress);

    React.useEffect(() => {
        setProgress(props.progress);
    }, [props.progress]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt:3 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="white">{`${Math.round(
                    props.progress,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default LinearDeterminate;