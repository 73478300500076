import React, { Component } from "react";
import axios from "axios";

import VideoPlayer from "../modules/VideoPlayer.jsx";
import connexionButton from "../../../assets/logos/connexionButton.svg";
import accepteButton from "../../../assets/logos/accepter_btn.svg";
import connexionButtonen from "../../../assets/logos/en/connexionButtonen.svg";
import refuserButton from "../../../assets/logos/refuser_btn.svg";
import Connexion from "../modules/Connexion.jsx";
import accept from "../../../assets/logos/en/accept.svg";
import decline from "../../../assets/logos/en/decline.svg";
import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class VideoAccept extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      playlist: null,
      user: null,
      openConnexion: false,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }

    document.title = "Upload Video | Wonderplace";
    let user = localStorage.getItem("user");
    if (user !== null) {
      this.setState({
        user: JSON.parse(user),
      });
    }
    let path = document.location.pathname;
    path = path.split("/");
    const url = import.meta.env.VITE_BACKEND_URL + "/simple/getVideo";
    axios
      .post(url, {
        id: parseInt(path[3]),
      })
      .then((res) => {
        this.setState({ video: res.data }, () => {
          let playlist = [
            {
              isPlay: true,
              src:
                import.meta.env.VITE_BACKEND_URL_IMG + res.data.path_nocompres,
              type: "video/mp4",
              poster: import.meta.env.VITE_BACKEND_URL_IMG + res.data.miniature,

              artistPhoto:
                import.meta.env.VITE_BACKEND_URL_IMG + res.data.perso.avatar,
              artistName: res.data.title,
              artistTitle: res.data.perso.name,
              artistDescription: res.data.infos.upload_artistWord,
              artistMoreDetails: "Plus d’informations…",

              hasLiked: false,
              isLiked: 0,
              isCommented: 0,
              isBoorkmarked: 0,
              isFollowed: 0,
              genreIcon: [],
            },
          ];
          this.setState({ playlist });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleCloseModal = () => {
    this.setState({
      openConnexion: false,
    });
  };
  handleOpenModalConnexion = () => {
    this.setState({
      openConnexion: true,
    });
  };
  handleAcceptRefuse = (accept) => {
    const { user, video } = this.state;

    if (user !== null) {
      let url = import.meta.env.VITE_BACKEND_URL + "/compte/accept/video";
      axios
        .post(
          url,
          {
            accept,
            idVideo: video.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.state.user.token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (accept) {
              localStorage.setItem(
                "message",
                "success:Merci, d'avoir accepté."
              );
            } else {
              localStorage.setItem("message", "info:Merci, d'avoir refusée.");
            }
            location.assign("/gestion-de-ma-page");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        openConnexion: true,
      });
    }
  };
  render() {
    return (
      <>
        <div className="homepageGlobal connexion_createur_inscription">
          <>
            <div className="block_infos_accept">
              {this.state.video !== null && (
                <p>{this.state.video.perso.name} {this.state.trad.titleVideoAccept} </p>
              )}
            </div>
            {this.state.playlist !== null &&
              this.state.playlist.map((value, index) => (
                <VideoPlayer
                  indexVideo={index}
                  id={`video_${index}`}
                  key={index}
                  video={value}
                  infos={true}
                  handleCallBackPlay={this.handleCallBackPlay}
                />
              ))}
            <div className="block_action_accept">
              <img
                  onClick={() => this.handleAcceptRefuse(true)}
                  src={this.state.lang === "fr" ? accepteButton : accept}
                  alt=""
              />
              <img
                  onClick={() => this.handleAcceptRefuse(false)}
                  src={this.state.lang === "fr" ? refuserButton : decline}
                  alt=""
              />
              {this.state.user === null && (
                  <img
                      onClick={this.handleOpenModalConnexion}
                      src={this.state.lang === "fr" ? connexionButton : connexionButtonen}
                      alt=""
                  />
              )}
            </div>
          </>
        </div>
        {this.state.openConnexion && (
            <Connexion handleCloseModal={this.handleCloseModal}/>
        )}
      </>
    );
  }
}
