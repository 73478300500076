import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "../../modules/MuiSwitch";
import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/changeValidButton.svg";
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";
import uploadVideoIcon from "../../../../assets/logos/noun-upload-3778990.svg";
import vignette1 from "../../../../assets/images/vignette1.jpg";
import {bannerHeight} from "../../modules/Helper.jsx";
import MenuUpload from "../../modules/MenuUpload.jsx";
import VideoUpload from "../../modules/VideoUpload.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class UploadVideo_part5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marginTopValue: "18rem", // Valeur initiale de margin-top de la div "left" pour que l'element image soit respnsive
      sendLauch: false,
      user: null,
      artistChoiceOptions:[],

      allow_NFT_Creation: false,
      upload_artistChoiceOptions: "",

      lang:'en',
      trad:en,
    };
  }
  componentDidMount() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }

    document.title = "Upload Video - NFT | Wonderplace";
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    bannerHeight();
    if (localStorage.getItem('storageType')) {
      let user = localStorage.getItem("user");
      if (user !== null) {
        this.setState({
          user: JSON.parse(user),
        }, () => {
          let video = localStorage.getItem('currentVideo');
          video = JSON.parse(video);
          let urlVideo = import.meta.env.VITE_BACKEND_URL + "/creator/getVideo";
          axios.post(urlVideo,{
            id:video.id,
          },{
            headers: {
              Authorization: "Bearer " + this.state.user.token,
            },
          }).then( res => {
            this.setState({
              video: res.data,
            })
          }).catch(err => {
            console.log(err);
          })
          let url = import.meta.env.VITE_BACKEND_URL + "/upload/getArtiste";
          axios.get(url, {
            headers: {
              Authorization: "Bearer " + this.state.user.token,
            },
          }).then(res => {
            this.setState({artistChoiceOptions:res.data});
          }).catch(err => {
            console.log(err);
          })
        });
      } else {
        location.assign("/");
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  // Adapte "MarginTop" de la div "left"  afin de descendre en mobile
  handleResize = () => {
    const resolution = window.innerWidth;

    // Vérifier si la résolution est inférieure ou égale à 1280 pixels
    if (resolution < 1280) {
      // Calculer la nouvelle valeur "top" en fonction de la résolution
      const newMarginTopValue = 18 + resolution / 100; // Par exemple

      // Mettre à jour la valeur "MarginTop" dans le state
      this.setState({
        marginTopValue: newMarginTopValue + "rem",
      });
    } else {
      this.setState({
        marginTopValue: 0 + "rem",
      });
    }
  };

  handleOptionChange_artistChoiceOptions = (value) => {
    this.setState({ upload_artistChoiceOptions: value });
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };

  handleUploadContinue = (e) => {
    e.preventDefault();
    let {allow_NFT_Creation, upload_artistChoiceOptions} = this.state;
    const url = import.meta.env.VITE_BACKEND_URL + "/upload/savePart5";
    axios.post(url,{
      "upload_artistChoiceOptions" : upload_artistChoiceOptions,
      "allow_NFT_Creation" : allow_NFT_Creation === false ? 1 : 0,
    },{
      headers: {
        Authorization: "Bearer " + this.state.user.token,
      },
    }).then(res => {

    }).catch(err => {
      console.log(err);
    })
  };

  render() {
    return (
      <div className="uploadVideoGlobal_Part5">
        <div className="uploadHeader">
          <h1>{this.state.trad.creatorUploadVideo}</h1>
          <MenuUpload active={5} />
        </div>

        <div className="uploadContainer">
          <section
            className="left"
            style={{ marginTop: this.state.marginTopValue }}
          >
            <div className="notif">
              <p className="description_text">{this.state.trad.nftDescriptionText}</p>
              <div className="switchContainer">
                <span className="yesNotif">{this.state.trad.spanYesNotif}</span>
                <FormControlLabel
                  control={
                    <MuiSwitch
                      size="small"
                      checked={this.state.allow_NFT_Creation}
                      onChange={this.handleChange}
                      name="allow_NFT_Creation"
                    />
                  }
                />
                <span className="noNotif">{this.state.trad.spanNoNotif}</span>
              </div>
            </div>

            <div className="upload_TextBloc">
              <h3>{this.state.trad.uploadTextBloc}</h3>
              <p>{this.state.trad.uploadTextBloc2}</p>
            </div>

            <div className="upload_TextBloc">
              <h3>{this.state.trad.uploadTextBloc3}</h3>
              <p>{this.state.trad.uploadTextBloc4}</p>
            </div>

            <div className="upload_Select">
              <label htmlFor="upload_artistChoice">{this.state.trad.uploadArtistChoice}</label>
              <select
                id="upload_artistChoice"
                name="upload_artistChoice"
                value={this.state.upload_artistChoiceOptions}
                onChange={(e) => this.handleOptionChange_artistChoiceOptions(e.target.value)}
              >
                <option value="default">{ this.state.trad.selectOtherArtiste }</option>
                {
                  this.state.artistChoiceOptions[0] &&
                  this.state.artistChoiceOptions.map((option) => (
                    <option
                      name="upload_artistChoice_option"
                      key={option.id}
                      value={option.id}
                    >
                      {option.nom_artiste}
                    </option>
                ))
                }
              </select>
            </div>
          </section>

          <section className="right">
            <div className="upload_VignetteSection">
              <div className="upload_VignetteText">
                {
                    this.state.video &&
                    <div className="vignette">
                      <VideoUpload
                          indexVideo={0}
                          upload={false}
                          id={this.state.video.id}
                          video={import.meta.env.VITE_BACKEND_URL_IMG + this.state.video.path_nocompres}
                      />
                    </div>
                }
              </div>
            </div>
          </section>
        </div>

        <div className="navButtons">
          <NavigateBackButton />

          <div className="form_submit_container">
            {this.state.sendLauch ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <div className="form_submit">
                  <img
                      src={this.state.lang === "fr" ? continuerButton : changeValidButtonen}
                      alt="continuer"
                      className="continuer"
                      onClick={this.handleUploadContinue}
                  />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}