import React from "react";
import continuerButton from "../../../../assets/logos/continuerButton.svg";
import continuerButtonen from "../../../../assets/logos/en/continuerButtonen.svg";
import axios from "axios";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
export default class EmailForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }
    handleInscription = () => {
        const {email} = this.state;
        //oo@oo.fr
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/forgotPassword"
        axios.post(url, {
            email,
        }).then(res => {
            if (res.data) {
                this.setState({
                    messageModal: this.state.trad.messageForgetPassword,
                    openModal: true,
                });
            }
        }).catch(err => {
            this.setState({
                messageModal: this.state.trad.messageForgetPassword1,
                openModal: true,
            });
        })
    }
    handleCloseModal = () => {
        location.assign('/');
    }

    render() {
        return (
            <>
                {this.state.openModal && (
                    <div className="modal">
                        <div className="block mini">
                            <p>{this.state.messageModal}</p>
                            <a href="" onClick={(e) => this.handleCloseModal(e)}>
                                OK
                            </a>
                        </div>
                    </div>
                )}
                <div className="inscritionNextParts">
                    <div className="inscritionNextPart_1">
                        <h1>{this.state.trad.passwordForget}</h1>
                        <h2>{this.state.trad.passwordForget1}</h2>
                        <div className="nameBlock">
                            <label htmlFor="userName">{this.state.trad.passwordForget2}*</label>
                            <input value={this.state.email} type="text"
                                   onChange={(e) => this.setState({email: e.target.value})}/>
                        </div>
                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? continuerButton : continuerButtonen}
                                alt="continuer"
                                className="continuer"
                                onClick={this.handleInscription}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

}