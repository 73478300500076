import React from 'react'

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class AidesFAQ extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            lang:'en',
            trad:en,

        }
    }

    componentDidMount(){
        document.title = "FAQ | Wonderplace";
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    render(){
        return(
            <h1>{this.state.trad.faqTitle}</h1>
        )
    }
}