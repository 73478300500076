import React, {useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";


import HeaderMobileNav from "./HeaderMobileNav";
import StorageSelection from "../modules/StorageSelection.jsx";

import wonderplaceBetaHeader from "../../../assets/logos/wonderplaceBetaHeader.svg";


import loupe from "../../../assets/logos/loupe.svg";
import maison from "../../../assets/logos/maison.svg";
import world from "../../../assets/logos/world.svg";
import people from "../../../assets/logos/people.svg";
import cloche from "../../../assets/logos/cloche.svg";
import panier from "../../../assets/logos/panier.svg";
import connexionButton from "../../../assets/logos/connexionButton.svg";
import connexionButtonen from "../../../assets/logos/en/connexionButtonen.svg";
import inscriptionButton from "../../../assets/logos/inscriptionButton.svg";
import inscriptionButtonen from "../../../assets/logos/en/inscriptionButtonen.svg";
import connectWallet from "../../../assets/logos/connectWallet.svg";
import connectWalleten from "../../../assets/logos/en/connectWalleten.svg";
import uploadVideo from "../../../assets/logos/uploadVideo.svg";
import uploaden from "../../../assets/logos/en/uploaden.svg";
import Connexion from "../modules/Connexion.jsx";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: "",
            openConnexion:false,
            user: null,
            message: "",
            style: "",
            reload:false,
            lang:'en',
            trad:en,
        };
    }

    async componentDidMount() {
        if (location.search === "?login=true") {
            this.setState(
                {
                    message: "Votre compte a bien été créé, Vous pouvez vous connecter.",
                    style: "success",
                },
                () => {
                    this.handleOpenModalConnexion();
                }
            );
        }else if(location.search === "?login=false"){
            this.handleOpenModalConnexion();
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }

                let links = document.querySelectorAll('.linkUploadVideo');
                if (localStorage.getItem('editvideo')) {
                    let urlPath = location.pathname;
                    if (urlPath !== "/gestion-de-ma-page/upload-video" && urlPath !== "/gestion-de-ma-page/upload-video-part2" && urlPath !== "/gestion-de-ma-page/upload-video-part3" && urlPath !== "/gestion-de-ma-page/upload-video-part4" && urlPath !== "/gestion-de-ma-page/upload-video-part5") {
                        localStorage.removeItem('editvideo');
                        localStorage.removeItem('currentVideo');
                    }
                }
            });
        }
    }
    searchResult = () => {
        let {searchInput} = this.state;
        console.log(searchInput);
    };
    handleOpenModalConnexion = () => {
        this.setState({
            openConnexion: true,
        });
    };
    handleConnexion = async () => {
        const {email, password, reste} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/connexion";
        axios
            .post(url, {
                email,
                password,
                reste,
            })
            .then((res) => {
                if (res.data.message) {
                    this.setState({
                        message: res.data.message,
                        style: "warning",
                    });
                } else {
                    this.setState({user: res.data}, () => {
                        localStorage.setItem("user", JSON.stringify(res.data));
                        this.handleCloseModal();
                        location.assign("/");
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    handleCloseModal = () => {
        this.setState({
            openConnexion: false,
            email: "",
            password: "",
        });
    };
    handleLogout = (e) => {
        e.preventDefault();
        let url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/deconnexion";
        axios
            .post(
                url,
                {
                    message: "logout",
                },
                {
                    headers: {
                        Authorization: "Bearer " + this.state.user.token,
                    },
                }
            )
            .then((res) => {
                if (res.data.message === "Deconnexion") {
                    localStorage.removeItem("user");
                    if(localStorage.getItem('link')){
                        location.assign(localStorage.getItem('link'));
                    }else{
                        location.assign("/");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    handleOpenMenuUser = (e) => {
        e.preventDefault();
        let menuUser = document.getElementById("nav_sub");
        menuUser.style.display = "block";
    };
    handleCloseMenuUser = (e) => {
        e.preventDefault();
        let menuUser = document.getElementById("nav_sub");
        menuUser.style.display = "none";
    }
    handleOpenConnexion = () => {
        this.setState({openConnexion: true});
    }
    render() {
        return (
            this.state.trad !== null &&
            <>
                {this.state.openConnexion && (
                    <Connexion handleCloseModal={this.handleCloseModal}/>
                )}
                <div className="headerGlobal">
                    <a href="/" className="wonderplaceBetaHeaderLogo">
                        <img src={wonderplaceBetaHeader} alt="wonderplaceBetaHeader"/>
                    </a>
                    <div className="elementsHeader">


                        <div className="headerSearchbar">
                            <input
                                className="searchbar"
                                type="text"
                                placeholder="Search here"
                                value={this.state.searchInput}
                                onChange={(e) => this.setState({searchInput: e.target.value})}
                            />
                            <img
                                src={loupe}
                                onClick={this.searchResult}
                                className="loupeLogo"
                                alt="loupeLogo"
                            />
                        </div>
                        <div className="iconsHeader">
                            <Link to="/">
                                <img src={maison} className="maisonLogo" alt="maisonLogo"/>
                            </Link>
                            <Link to="/explorer">
                                <img src={world} className="worldLogo" alt="worldLogo"/>
                            </Link>
                            <Link to="/mes-places">
                                <img src={people} className="peopleLogo" alt="peopleLogo"/>
                            </Link>
                            <Link to="/">
                                <img src={cloche} className="clocheLogo" alt="clocheLogo"/>
                            </Link>
                            <Link to="/">
                                <img src={panier} className="panierLogo" alt="panierLogo"/>
                            </Link>
                        </div>

                        <div className="headerButtons">
                            {this.state.user !== null ? (
                                <>
                                    {
                                        this.state.user.user.creator === 1 ?
                                            <a href="/gestion-de-ma-page" onMouseEnter={(e) => this.handleOpenMenuUser(e)}>
                                                {
                                                    this.state.user.user.avatar !== null && this.state.user.user.avatar !== undefined  ?
                                                        <img
                                                            className="iconUser"
                                                            src={
                                                                import.meta.env.VITE_BACKEND_URL_IMG +
                                                                this.state.user.user.avatar
                                                            }
                                                            alt={this.state.user.user.name}
                                                        />
                                                        :
                                                        <p className="icon_juste_letter">W</p>

                                                }
                                            </a>
                                        :
                                                <a href="/ma-page" onMouseEnter={(e) => this.handleOpenMenuUser(e)}>
                                                    {
                                                        this.state.user.user.avatar !== null && this.state.user.user.avatar !== undefined  ?
                                                            <img
                                                                className="iconUser"
                                                                src={
                                                                    import.meta.env.VITE_BACKEND_URL_IMG +
                                                                    this.state.user.user.avatar
                                                                }
                                                                alt={this.state.user.user.name}
                                                            />
                                                            :
                                                            <p className="icon_juste_letter">W</p>

                                                    }
                                                </a>
                                    }

                                    <div className="nav_sub" onMouseLeave={(e) => this.handleCloseMenuUser(e)} id="nav_sub">
                                        <>

                                            <a href="/ma-page">{this.state.trad.headerNavSub}</a>
                                            <br/>
                                        </>
                                        {this.state.user.user.creator === 1 && (
                                            <>
                                                <a href="/gestion-de-ma-page">{this.state.trad.headerNavSub4}</a>
                                                <br/>
                                            </>
                                        )}

                                        <a href="/compte">{this.state.trad.headerNavSub2}</a>
                                        <br/>
                                        <a href="#" onClick={(e) => this.handleLogout(e)}>
                                            {this.state.trad.headerNavSub3}
                                        </a>
                                    </div>
                                    {this.state.user.user.creator ? (
                                        <a href="/gestion-de-ma-page/upload-video">
                                            <img
                                                src={this.state.lang === "fr" ? uploadVideo : uploaden}
                                                className={`uploadVideoLogo ${this.state.lang === "fr" ? "" : "en"}`}
                                                alt="uploadVideoLogo"
                                            />
                                        </a>
                                    ) : (
                                        <a href="/mon-wallet">
                                            <img
                                                src={this.state.lang === "fr" ? connectWallet : connectWalleten}
                                                className="connectWalletLogo"
                                                alt="connectWalletLogo"
                                            />
                                        </a>
                                    )}
                                </>
                            ) : (
                                <>

                                    <img
                                        src={this.state.lang === "fr" ? connexionButton : connexionButtonen}
                                        className="connexionButtonLogo"
                                        alt="connexionButtonLogo"
                                        onClick={this.handleOpenModalConnexion}
                                    />

                                    <a href="/inscription">
                                        <img
                                            src={this.state.lang === "fr" ? inscriptionButton : inscriptionButtonen}
                                            className="inscriptionButtonLogo"
                                            alt="inscriptionButtonLogo"
                                        />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="headerMobileNav">
                        <HeaderMobileNav trad={this.state.trad} lang={this.state.lang} handleOpenConnexion={this.handleOpenConnexion} user={this.state.user}/>
                    </div>
                </div>

                <StorageSelection trad={this.state.trad} />
            </>
        );
    }
}
