import React from "react";
import {Routes, Route, useParams} from "react-router-dom";
import HomePage from "./js/components/HomePage";

import AidesFAQ from "./js/components/AidesFAQ";
import Inscription from "./js/components/Inscription";
import InscriptionNext from "./js/components/InscriptionNext";
import ConfirmationInscription from "./js/components/ConfirmationInscription";
import Compte from "./js/components/compte_pages/Compte";
import CompteGeneral from "./js/components/compte_pages/CompteGeneral";
import ComptePassword from "./js/components/compte_pages/ComptePassword";
import CompteNotif from "./js/components/compte_pages/CompteNotif";
import CompteSecurity from "./js/components/compte_pages/CompteSecurity";
import CompteConfidentialite from "./js/components/compte_pages/CompteConfidentialite";
import ErreurPage from "./js/components/ErreurPage.jsx";
import PageCreator from "./js/components/creator/PageCreator.jsx";
import UploadVideo from "./js/components/creator/UploadVideo.jsx";
import UploadVideo_part2 from "./js/components/creator/UploadVideo_part2.jsx";
import UploadVideo_part3 from "./js/components/creator/UploadVideo_part3.jsx";
import UploadVideo_part4 from "./js/components/creator/UploadVideo_part4.jsx";
import UploadVideo_part5 from "./js/components/creator/UploadVideo_part5.jsx";
import UploadVideo_part6 from "./js/components/creator/UploadVideo_part6.jsx";
import UploadVideo_part7 from "./js/components/creator/UploadVideo_part7.jsx";
import VideoAccept from "./js/components/VideoAccept.jsx";
import Mapage from './js/components/utilisateur/Mapage.jsx';
import Wallet from './js/components/Wallet.jsx';
import Myplaces from './js/components/places/Myplaces.jsx';
import Oneplace from "./js/components/places/Oneplace.jsx";
import VideoSolo from "./js/components/VideoSolo";
import Explorer from "./js/components/places/Explorer.jsx";
import PageOther from './js/components/PageOther.jsx';
import Contact from "./js/components/Contact.jsx";
import EmailForgotPassword from "./js/components/Password/EmailForgotPassword.jsx";
import EmailForgotPasswordChange from "./js/components/Password/EmailForgotPasswordChange.jsx";
import PlaylistPlayer from "./js/components/PlaylistPlayer.jsx";
import Shuffle from "./js/components/Shuffle.jsx";
import PlacePlayListPlayer from "./js/components/places/PlacePlaylistPlayer.jsx";
import PlacesList from "./js/components/places/PlacesList.jsx";

export default class AllRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Routes>
                <Route path="/" element={<HomePage />}/>
                <Route path="/aidesfaq" element={<AidesFAQ />}/>
                <Route path="/erreur" element={<ErreurPage />}/>
                <Route path="/mot-de-passe-oublie" element={<EmailForgotPassword />}/>
                <Route path="/reinitialiser/:token" element={<EmailForgotPasswordChange />}/>
                <Route path="/inscription/*" element={<Inscription />}/>
                <Route path="/inscription/information/*" element={<InscriptionNext />}/>
                <Route path="/confirmation-inscription/:token" element={<ConfirmationInscription />}/>
                <Route path="/compte/*" element={<Compte />}/>
                <Route path="/compte/general/*" element={<CompteGeneral />}/>
                <Route path="/compte/password/*" element={<ComptePassword />}/>
                <Route path="/compte/notifications/*" element={<CompteNotif />}/>
                <Route path="/compte/security/*" element={<CompteSecurity />}/>
                <Route path="/compte/confidentialite/*" element={<CompteConfidentialite />}/>
                <Route path="/gestion-de-ma-page" element={<PageCreator />}/>
                <Route path={"/gestion-de-ma-page/upload-video"} element={<UploadVideo />}/>
                <Route path={"/gestion-de-ma-page/upload-video-part2"} element={<UploadVideo_part2 />}/>
                <Route path={"/gestion-de-ma-page/upload-video-part3"} element={<UploadVideo_part3 />}/>
                <Route path={"/gestion-de-ma-page/upload-video-part4"} element={<UploadVideo_part4 />}/>
                <Route path={"/gestion-de-ma-page/upload-video-part5"} element={<UploadVideo_part5 />}/>
                <Route path={"/gestion-de-ma-page/upload-video-part6"} element={<UploadVideo_part6 />}/>
                <Route path={"/gestion-de-ma-page/upload-video-part7"} element={<UploadVideo_part7 />}/>
                <Route path={"/acceptation/video/:id"} element={<VideoAccept />}/>
                <Route path={"/ma-page"} element={<Mapage />}/>
                <Route path={"/page-de-profil/:id"} element={<PageOther />}/>
                <Route path={"/mon-wallet"} element={<Wallet />}/>
                <Route path={"/mes-places"} element={<Myplaces />}/>
                <Route path={"/place/:id"} element={<Oneplace />}/>
                <Route path={"/video/:id"} element={<VideoSolo />}/>
                <Route path={"/explorer"} element={<Explorer />}/>
                <Route path={"/nouvelles-places"} element={<PlacesList />}/>
                <Route path={"/contact/:type/:id"} element={<Contact />}/>
                <Route path={"/playlist/:id"} element={<PlaylistPlayer />} />
                <Route path={"/playlist/reposts/:id"} element={<PlaylistPlayer />} />
                <Route path={"/playlist/like/:id"} element={<PlaylistPlayer />} />
                <Route path={"/place/playlist/:id"} element={<PlacePlayListPlayer />} />

                <Route path={"/shuffle/:id"} element={<Shuffle />} />
            </Routes>
        );
    }
}
