import { useNavigate } from "react-router-dom";
import retourButton from "../../../assets/logos/retourButton.svg";

export default function ReturnBackButton() {
  const navigate = useNavigate();

  return (
    <button className="retour" onClick={() => navigate(-1)}>
      <img
        src={retourButton}
        className="retourButtonButton"
        alt="retourButtonButton"
      />
    </button>
  );
}
