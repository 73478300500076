import React, {Component} from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "./MuiSwitch.jsx";

import ajouterButton from "../../../assets/logos/ajouter_btn.svg";
import ajouter_btn from "../../../assets/logos/en/ajouter_btn.svg";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import playlistImg from "../../../assets/images/playlist_sample.png";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';
export default class ModalPlayList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playList_name:"",
            playList_desc:"",
            playList_public:false,
            playList_image:playlistImg,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if(localStorage.getItem('storageType')){
            let user = localStorage.getItem("user");
            if (user !== null) {
                if (JSON.parse(localStorage.getItem("user"))) {
                    this.setState({
                        user: JSON.parse(localStorage.getItem("user")),
                    }, () => {
                        let lang = this.state.user.user.language;
                        if(lang === "fr"){
                            this.setState({lang:"fr",trad:fr});
                        }
                    });
                }
            } else {
                location.assign("/");
            }
        }else{
            let user = sessionStorage.getItem("user");
            if (user !== null) {
                this.setState({
                    user: JSON.parse(user),
                });
            } else {
                location.assign("/");
            }
        }
    }


    handleChangePlayLIstPrivate = (e) => {
        this.setState({
            playList_public:e.target.checked
        })
    }

    handleSavePlayList = () => {
        let {playList_name, playList_desc, playList_public, playList_image} = this.state;
        if(playList_image === "/src/assets/images/playlist_sample.png"){
            playList_image = null;
        }
        const url = import.meta.env.VITE_BACKEND_URL + "/playlist/save";
        axios.post(url,{
            name:playList_name,
            description:playList_desc,
            public:playList_public,
            image:playList_image,
        },{
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            console.log(res.data);
            this.props.handlePlayListRefresh(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <div className="modal">
                <div className="block">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    <h2>{this.state.trad.pAddPlaylist}</h2>
                    <div className="form_connexion">
                        <div className="form_control">
                            <label htmlFor="password">{this.state.trad.labelNamePlaylist}</label>
                            <input
                                type="text"
                                name="playList_name"
                                value={this.state.playList_name}
                                onChange={(e) =>
                                    this.setState({playList_name: e.target.value})
                                }
                            />
                        </div>
                        <div className="form_control">
                            <label htmlFor="password">{this.state.trad.labelDescriptionPlaylist}</label>
                            <textarea name="playList_desc" defaultValue={this.state.playList_desc}
                                      onChange={(e) => this.setState({playList_desc: e.target.value})}></textarea>
                        </div>
                        <div className="form_control">
                            <FormControlLabel
                                control={
                                    <MuiSwitch
                                        size="small"
                                        checked={this.state.playList_public}
                                        onChange={this.handleChangePlayLIstPrivate}
                                        name="new_member"
                                    />
                                }
                                label={this.state.trad.labelPlaylistPrivate}
                            />
                        </div>
                        <div className="form_control">
                            <img src={this.state.playList_image} alt=""/>
                            <p>{this.state.trad.pAddPlace}</p>
                        </div>
                        <div className="form_submit">
                            <img src={this.state.lang === "fr" ? ajouterButton : ajouter_btn} alt="" onClick={this.handleSavePlayList}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}