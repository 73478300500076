import React, {Component} from 'react';
import axios from "axios";
import VideoPlayer from "../../modules/VideoPlayer.jsx";
import SmallModal from "../../modules/SmallModal.jsx";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import VideoPlayerFull from "../../modules/VideoPlayerFull.jsx";

export default class Reposts extends React.Component{
    constructor(props) {
        super(props);
        this.state= {
            placeId: this.props.placeId,
            user: this.props.user,
            fondateur: this.props.fondateur,
            page:1,
            videos:[],
            full:false,
            indexVideo: 0,
            currentTime: 0,
            playVideoId: 0,
            openModalBug:false,
            openModalContenu:false,
            currentIdVideo:null,
            flashMessage:"",
            send:null,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        const {placeId} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/places/reposts?page=" + this.state.page;
        let artistInfos = [];
        axios.post(url,{
            places_id:placeId,
        },{
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            if(res.data.data[0]){
                res.data.data.map((value, index) => {
                    let video = {};
                    let mini = value.miniature;
                    video.id = value.id;
                    video.isPlay =  false;
                    video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
                    video.type = "video/mp4";
                    if (value.miniature != null) {
                        video.poster = import.meta.env.VITE_BACKEND_URL_IMG + mini;
                    }
                    video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
                    video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
                    video.artistTitle = value.utilisateur.nom_artiste;
                    video.artistDescription = value.utilisateur.biographie;
                    video.artistMoreDetails = "Plus d’informations…";
                    video.worldArtiste = value.info && value.info.upload_artistWord ? value.info.upload_artistWord.replace(/\n/g, '<br />') : "";
                    video.otherInfo = value.info && value.info.upload_otherInfos ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
                    video.droit = value.droits ? value.droits : [];
                    video.hasLiked = value.hasLiked;
                    video.hasDisLiked = value.hasDisLiked;
                    video.isLiked = value.isLiked;
                    video.title = value.title;
                    video.isCommented = value.isCom;
                    video.isBoorkmarked = value.book;
                    video.isBooked = value.booked;
                    video.isFollowed = value.share;
                    video.genreIcon = [];
                    video.token= value.token;
                    video.userId = value.utilisateur_id;
                    //video.videosToBuy = []
                    video.videosToBuy = [
                        {startTime: 0, endTime: 5, label: "Disponible"},
                        {startTime: 20, endTime: 25, label: "Disponible"},
                        {startTime: 50, endTime: 55, label: "Disponible"},
                    ];
                    //video.videosBought = []
                    video.videosBought = [
                        {startTime: 40, endTime: 45, label: "Indisponible"},
                        {startTime: 130, endTime: 135, label: "Indisponible"},
                    ];
                    artistInfos.push(video);
                });
                this.setState({
                    videos: artistInfos,
                });
            }
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }
    handleCallBackPlay = (id) => {
        const {videos} = this.state;
        const updatedVideos = videos.map((value, index) => ({
            ...value,
            isPlay: index === id,
        }));
        this.setState({videos: updatedVideos});
    };
    handleCallBackFullScreen = (value, indexVideo, time) => {
        if (value) {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)}, () => {

            });
        } else {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)}, () => {
                setTimeout(() => {
                    let video = document.getElementById('video_' + indexVideo);
                    const heightVideo = video.getBoundingClientRect().top - 50;
                    window.scroll(0, heightVideo);
                }, 50)
            });
        }
    }
    handleFullScreenChangeVideo = (index) => {
        this.setState({indexVideo: index});
    }
    callbackIndexVideo = (indexVideo) => {
        let {videos, page,placeId} = this.state;
        let nb = videos.length - 2;
        if (indexVideo == nb) {
            page++;
            let url = import.meta.env.VITE_BACKEND_URL + "/places/reposts?page=" + page;
            axios.post(url,{
                places_id:placeId,
            },{
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                },
            }).then(res => {
                if (res.data[0]) {
                    videos[indexVideo].isPlay = true;
                    res.data.map((value, index) => {
                        let video = {};
                        let mini = value.miniature;
                        video.id = value.id;
                        video.isPlay = false;
                        video.src = import.meta.env.VITE_BACKEND_URL_IMG + value.path_nocompres;
                        video.type = "video/mp4";
                        if (value.miniature != null) {
                            video.poster = import.meta.env.VITE_BACKEND_URL_IMG + mini;
                        }
                        video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + value.utilisateur.avatar;
                        video.artistName = `${value.utilisateur.lastname !== null ? value.utilisateur.lastname : ""} ${value.utilisateur.firstname !== null ? value.utilisateur.firstname : ""}`;
                        video.artistTitle = value.utilisateur.nom_artiste;
                        video.artistDescription = value.utilisateur.biographie;
                        video.artistMoreDetails = "Plus d’informations…";
                        video.worldArtiste = value.info && value.info.upload_artistWord ? value.info.upload_artistWord.replace(/\n/g, '<br />') : "";
                        video.otherInfo = value.info && value.info.upload_otherInfos ? value.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
                        video.droit = value.droits ? value.droits : [];
                        video.hasLiked = value.hasLiked;
                        video.hasDisLiked = value.hasDisLiked;
                        video.isLiked = value.isLiked;
                        video.title = value.title;
                        video.isCommented = value.isCom;
                        video.isBoorkmarked = value.book;
                        video.isBooked = value.booked;
                        video.isFollowed = value.share;
                        video.genreIcon = [];
                        video.token= value.token;
                        video.userId = value.utilisateur_id;
                        //video.videosToBuy = []
                        video.videosToBuy = [
                            {startTime: 0, endTime: 5, label: this.state.trad.pDescriptionPlaces20},
                            {startTime: 20, endTime: 25, label: this.state.trad.pDescriptionPlaces20},
                            {startTime: 50, endTime: 55, label: this.state.trad.pDescriptionPlaces20},
                        ];
                        //video.videosBought = []
                        video.videosBought = [
                            {startTime: 40, endTime: 45, label: this.state.trad.pDescriptionPlaces21},
                            {startTime: 130, endTime: 135, label: this.state.trad.pDescriptionPlaces21},
                        ];
                        videos.push(video);
                    });
                    this.setState(prevState => {
                        const newVideos = prevState.videos.map((video, index) => {
                            return {...video, isPlay: index === indexVideo};
                        });

                        return {
                            page,
                            videos: newVideos,
                            indexVideo: indexVideo,
                            nbIndexVideo: prevState.nbIndexVideo + 3,
                        };
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    callBackFreshPage = () => {
        window.location.reload(true);
    }

    handleSendBug = (type,id) => {
        let send = [];
        if (type === "bug") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: false, openModalBug: true});
        } else if (type === "contenu") {
            send.push(type);
            send.push("video");
            send.push(id);
            this.setState({send, openModalContenu: true, openModalBug: false});
        }
        if(type === "bug"){
            this.setState({send,currentIdVideo:id,openModalBug:true});
        }else{
            this.setState({send,currentIdVideo:id,openModalContenu:true});
        }
    }
    callbackSend = (message) => {
        const {send, user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/send";
        axios.post(url, {
            send,
            message: message,
            user
        }).then(res => {
            if (res.data) {
                this.handleCloseModal();
                this.setState({
                    typeFlash: 'success',
                    flashMessage: this.state.trad.messageCompte1,
                    send: null
                },() =>{
                    setTimeout(() => {
                        this.setState({flashMessage: ""})
                    },3000)
                });
            }
        }).catch(err => {
            this.setState({
                openModalContenu: false,
                openModalBug: false,
                typeFlash: 'warning',
                flashMessage: this.state.trad.messageCompte2
            },() =>{
                setTimeout(() => {
                    this.setState({flashMessage: ""})
                },3000)
            });
        })
    }
    handleCloseModal = () => {
        this.setState({send:null,currentIdVideo:null,openModalBug:false,openModalContenu:false});
    }

    render(){
        return(
            <>
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                <div className="content">
                    <div className={`homepageGlobal ${this.state.videos[0] ? '' : 'empty'}`}>
                        {this.state.full ?
                            <div className="fullscreen">
                                <VideoPlayerFull
                                    indexVideo={this.state.indexVideo}
                                    id={this.state.indexVideo}
                                    key={this.state.indexVideo}
                                    video={this.state.videos[this.state.indexVideo]}
                                    infos={false}
                                    handleCallBackPlay={this.handleCallBackPlay}
                                    handleCallBackFullScreen={this.handleCallBackFullScreen}
                                    handleFullScreenChangeVideo={this.handleFullScreenChangeVideo}
                                    currentTime={this.state.currentTime}
                                />
                            </div>
                            :
                            this.state.videos[0] ?
                                this.state.videos.map((value, index) => (
                                    <VideoPlayer
                                        indexVideo={index}
                                        id={`video_${index}`}
                                        key={index}
                                        video={value}
                                        trad={this.state.trad}
                                        videosToBuy={value}
                                        videosBought={value}
                                        infos={false}
                                        handleCallBackPlay={this.handleCallBackPlay}
                                        handleCallBackFullScreen={this.handleCallBackFullScreen}
                                        currentTime={this.state.currentTime}
                                        callbackIndexVideo={this.callbackIndexVideo}
                                        callBackFreshPage={this.callBackFreshPage}
                                        handleSendBug={this.handleSendBug}
                                        user={this.state.user}
                                        full={this.state.full}
                                    />
                                ))
                                :
                                <div className="loading-wave">
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                </div>
                        }
                    </div>
                </div>
                {
                    this.state.openModalBug &&
                    <SmallModal title="Avertir d’un bug" label="Décrire le bug" handleCloseModal={this.handleCloseModal}
                                callbackSend={this.callbackSend}/>
                }
                {
                    this.state.openModalContenu &&
                    <SmallModal title="SIGNALER UN CONTENU" label="Décrire le signalement"
                                handleCloseModal={this.handleCloseModal} callbackSend={this.callbackSend}/>
                }
            </>
        )
    }

}