import React, {Component} from 'react';
import axios from "axios";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import loupe from "../../../assets/logos/loupe.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class FollowerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            inviteUser: null,
            inviteUserFull: null,
            placeID: this.props.placeId,
            count: 0,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        let url = import.meta.env.VITE_BACKEND_URL + "/places/invite";
        axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            let count = res.data.length;
            this.setState({inviteUser: res.data, inviteUserFull: res.data, count});
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleSearch = (value, type) => {
        const {inviteUserFull} = this.state;
        if (type !== "name") {
            let select = document.getElementById('searchSelect');
            let creator = select.value;
            let newInvite = null;
            if (creator === "creator") {
                newInvite = inviteUserFull.filter(iuf => iuf.utilisateur.creator_verif === 1);
            } else if (creator === "auditor") {
                newInvite = inviteUserFull.filter(iuf => iuf.utilisateur.creator_verif === 0);
            } else {
                newInvite = inviteUserFull;
            }
            this.setState({inviteUser: newInvite})
        } else {
            let text = document.getElementById('searchText');
            let name = text.value !== null ? text.value : "";
            let newInvite = inviteUserFull.filter(iuf => iuf.utilisateur.name.indexOf(name) !== -1);
            this.setState({inviteUser: newInvite})
        }
    }
    handleLinkPage = (id) => {
        this.props.handleCloseMenu();
        let url = `/page-de-profil/${id}`;
        location.assign(url);
    }

    render() {
        return (
            <>
                <div className="modal invite">
                    <div className="block">
                        <div className="closeModal">
                            <img
                                className="crossIcon"
                                src={crossIcon}
                                alt="crossIcon"
                                onClick={this.props.handleCloseMenu}
                            />
                        </div>
                        <div className="form_connexion">
                            <div className="blockFollow">
                                <div className="block_social" style={{cursor: "pointer"}}>
                                    <p className="number">{this.state.count}</p>
                                    <p className="label">{this.state.trad.creatorFollowers}</p>
                                </div>
                            </div>
                            <div className="search">
                                <div className="form_control"
                                     onChange={(e) => this.handleSearch(e.target.value, 'name')}>
                                    <span className="at">@</span>
                                    <input id="searchText" type="text"/>
                                    <img src={loupe} alt=""/>
                                </div>
                                <div className="form_control">
                                    <select name="" id="searchSelect"
                                            onChange={(e) => this.handleSearch(e.target.value, 'creator')}>
                                        <option value="tous">{this.state.trad.labelFollow5}</option>
                                        <option value="auditor">{this.state.trad.pDescriptionUtilisateur7}</option>
                                        <option value="creator">{this.state.trad.creator}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="liste">
                                {
                                    this.state.inviteUser !== null &&
                                    this.state.inviteUser.map((value, index) => {
                                        return (
                                            <div key={index} className={'line'}
                                                 onClick={() => this.handleLinkPage(value.utilisateur.id)}>
                                                <div className="left">
                                                    <img
                                                        src={`${import.meta.env.VITE_BACKEND_URL_IMG}${value.perso.avatar}`}
                                                        alt=""/>
                                                </div>
                                                <div className="right">
                                                    <p>{value.perso.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}