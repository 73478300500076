import React, {useEffect, useRef, useState} from "react";
import videojs from "video.js";
import VideoJSFull from "./VideoJSFull.jsx";
import "video.js/dist/video-js.css";

import videoLikeIcon from "../../../assets/logos/videoLikeIcon.svg";
import videoLikeFullIcon from "../../../assets/logos/videoLikeFullIcon.svg";
import videoUnlikeIcon from "../../../assets/logos/videoUnlikeIcon.svg";
import videoCommentIcon from "../../../assets/logos/videoCommentIcon.svg";
import videoBookmarkIcon from "../../../assets/logos/videoBookmarkIcon.svg";
import videoFollowIcon from "../../../assets/logos/videoFollowIcon.svg";
import videoPlusIcon from "../../../assets/logos/videoPlusIcon.svg";
import arrowPlayer from '../../../assets/logos/player/playerArrowIcon.svg';

// genreIcons pour test en dur //
import genreSombreIcon from "../../../assets/samples/genreSombreIcon.svg";

//////////////////////////////////////////////////////////////////////////////////
let timer;
const VideoPlayerFull = (props) => {

    const playerRef = useRef(null);
    const [video, setVideo] = useState(props.video);
    const [id, setId] = useState(props.id);
    const [indexVideo, setIndexVideo] = useState(props.indexVideo);
    const [refresh, setRefresh] = useState(false);
    const [currentTime, setCurrentTime] = useState(props.currentTime);
    const [user, setUser] = useState(props.user);
    const [message, setMessage] = useState(false);
    const [styleMessage, setStyleMessage] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    // Player Footer Variables //
    const artistPhoto = video.artistPhoto;
    const artistName = video.artistName;
    const artistTitle = video.artistTitle;
    const artistDescription = video.artistDescription;
    const artistMoreDetails = video.artistMoreDetails;
    const userId = video.userId;


    // Player Footer States //
    const [hasLiked, setHasLiked] = useState(video.hasLiked);
    const [isLiked, setIsLiked] = useState(video.isLiked);
    const [isCommented, setIsCommented] = useState(video.isCommented);
    const [isBoorkmarked, setIsBoorkmarked] = useState(video.isBoorkmarked);
    const [isFollowed, setIsFollowed] = useState(video.isFollowed);
    const [genreIcon, setGenreIcon] = useState([video.genreIcon]);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        muted: false,
        aspectRatio: "16:9",
        currentTime: currentTime,
        controlBar: {
            remainingTimeDisplay: false,
            disablePictureInPicture: true,
            timeDivider: true,
            children: [
                "playToggle",
                "volumePanel",
                "progressControl",
                "currentTimeDisplay",
                "timeDivider",
                "durationDisplay",
                //"fullscreenToggle",
                // "restartButton",  //// RestartButton is custom, the order is done in VideoJS.jsx with the index
                //  "nextVideoButton",  //// NextVideoButton is custom, the order is done in VideoJS.jsx with the index
            ],
        },
        sources: [video],
        poster: video.poster,
    };

    // Player Fonctions //
    const handleInteraction = () => {

    }

    useEffect(() => {
        setVideo(props.video);
        videoJsOptions.sources = video;
        setRefresh(true);

        document.addEventListener('mousemove', () => {
            clearTimeout(timer);
            let footerVideo = document.querySelector('.videoFooter');
            footerVideo.style.opacity = 1;
            timer = setTimeout(() => {
                footerVideo.style.opacity = 0;
            }, 2500);
        })
        document.addEventListener('touchstart', () => {
            clearTimeout(timer);
            let footerVideo = document.querySelector('.videoFooter');
            footerVideo.style.opacity = 1;
            timer = setTimeout(() => {
                footerVideo.style.opacity = 0;
            }, 2500);
        })


        if (props.video.isPlay) {
            handlePlay();
        } else {
            handlePause();
        }
    }, [video, refresh, playerRef, props.video.isPlay]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        player.on("waiting", () => {
            //videojs.log("player is waiting");
        });

        player.on("dispose", () => {
            //videojs.log("player will dispose");
        });
    };

    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play().catch(function (erreur) {

            });
        }
    };

    const handlePause = () => {
        if (playerRef.current) {
            // videojs.log(playerRef.current);
            playerRef.current.pause();
        }
    };

    const handleCallback = (id) => {
        let newId = parseInt(id) + 1;
        setIndexVideo(newId);
        props.handleCallBackPlay(newId);
        // setRefresh(true);
    };


    // Player FooterIcons //

    const handleLike = () => {
        if (user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/like/likevideo";
            axios.post(url, {
                id: video.id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + user.token
                }
            }).then(res => {
                setIsLiked(res.data.like);
                setHasLiked(res.data.hasLiked)
            }).catch(err => {
                console.log(err);
            })
        }else{
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0,'like');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };

    const handleUnlike = () => {
        if (user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/like/dislikevideo";
            axios.post(url, {
                id: video.id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + user.token
                }
            }).then(res => {
                setIsLiked(res.data.like);
                setHasLiked(res.data.hasLiked)
                setHasDisLiked(res.data.hasDisLiked)
            }).catch(err => {
                console.log(err);
            })
        }else{
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0,'like');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }

    };

    const handleComment = () => {
        if (user !== null) {
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0, 'comm');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setIsCommented((prevCount) => prevCount + 1);
        }else{
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0,'like');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };

    const handleBookmark = () => {
        if (user !== null) {
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0, 'book');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setIsCommented((prevCount) => prevCount + 1);
        }else{
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0,'like');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };

    const handleFollow = () => {
        if (user !== null) {
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0, 'follow');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setIsCommented((prevCount) => prevCount + 1);
        }else{
            const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
            if (isFullscreen) {
                // Stopper le mode plein écran
                try {
                    if (document.fullscreenElement && document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                        document.webkitExitFullscreen();
                    } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                        document.msExitFullscreen();
                    }
                    // Revenir à l'orientation par défaut
                    const isDesktop = window.innerWidth <= 1280;
                    if (isDesktop) {
                        if (screen.orientation && screen.orientation.unlock) {
                            screen.orientation.unlock();
                        }
                    }
                    props.handleCallBackFullScreen(false, indexVideo, 0,'like');
                } catch (error) {
                    console.log("Failed to exit fullscreen mode", error);
                }
            }
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };

    const handlePlus = () => {
        setGenreIcon((prevIcons) => [
            ...prevIcons,
            <img
                src={genreSombreIcon}
                alt="Video Genre Sombre Icon"
                className="genreIcon"
                key={`genreIcon${prevIcons.length}`}
            />,
        ]);
    };
    const handleFullScreenChangeVideo = (direction) => {
        if (direction == "prev") {
            if (indexVideo !== 0) {
                let newID = indexVideo - 1;
                props.handleFullScreenChangeVideo(newID)
            }
        } else {
            let newID = indexVideo + 1;
            props.handleFullScreenChangeVideo(newID)
        }
    }

    return (
        <div className="videoPlayerGlobal" id={id}>
            <div className="videoPlayerContainer">
                <VideoJSFull
                    options={videoJsOptions}
                    handleCallback={handleCallback}
                    handleCallBackFullScreen={(props.handleCallBackFullScreen)}
                    indexVideo={indexVideo}
                    onReady={handlePlayerReady}
                    id={id}
                />
                <div className="block_other_video">
                    {
                        indexVideo !== 0 &&
                        <div className="btn reverse prev" onClick={() => handleFullScreenChangeVideo('prev')}>
                            <img src={arrowPlayer} alt=""/>
                        </div>
                    }
                    <div className="btn next" onClick={() => handleFullScreenChangeVideo('next')}>
                        <img src={arrowPlayer} alt=""/>
                    </div>
                </div>

                <div className="videoFooter">
                    <div className="artistDetailsContainer">
                        <a href={`/page-de-profil/${userId}`}>
                            <img
                                className="artistPhoto"
                                src={artistPhoto}
                                alt="artistPhoto"
                            ></img>
                        </a>
                        <div className="artistInfosContainer">
                            <div className="artistInfos">
                                <h3 className="artistName">{artistName} &nbsp;— &nbsp; {artistTitle} .&nbsp;.&nbsp;.</h3>
                            </div>

                            <p className="artistDescription">{artistDescription}</p>
                            <p className="artistMoreDetails">{artistMoreDetails}</p>
                        </div>
                    </div>

                    <div className="videoIconsContainer">
                        <div className="videoIcons">
                            <div className="video_icon">
                                <label>{isLiked}</label>
                                <img
                                    src={hasLiked ? videoLikeFullIcon : videoLikeIcon}
                                    alt="Video Like Icon"
                                    className="videoLikeIcon"
                                    onClick={handleLike}
                                />
                            </div>

                            <div className="video_icon">
                                <img
                                    src={videoUnlikeIcon}
                                    alt="Video Unlike Icon"
                                    className="videoUnlikeIcon"
                                    onClick={handleUnlike}
                                />
                            </div>

                            <div className="video_icon">
                                <label>{isCommented}</label>
                                <img
                                    src={videoCommentIcon}
                                    alt="Video Comment Icon"
                                    className="videoCommentIcon"
                                    onClick={handleComment}
                                />
                            </div>

                            <div className="video_icon">
                                <label>{isBoorkmarked}</label>
                                <img
                                    src={videoBookmarkIcon}
                                    alt="Video Bookmark Icon"
                                    className="videoBookmarkIcon"
                                    onClick={handleBookmark}
                                />
                            </div>

                            <div className="video_icon">
                                <label>{isFollowed}</label>
                                <img
                                    src={videoFollowIcon}
                                    alt="Video Follow Icon"
                                    className="videoFollowIcon"
                                    onClick={handleFollow}
                                />
                            </div>
                        </div>
                        {/*
                        <div className="videoGenresContainer">
                            <div className="videoGenres">
                                {genreIcon.map((icon, index) => (
                                    <li className="video_genre_icon" key={index}>
                                        {icon}
                                    </li>
                                ))}

                                <div className="video_genre_icon">
                                    <img
                                        src={videoPlusIcon}
                                        alt="Video Plus Icon"
                                        className="videoPlusIcon"
                                        onClick={handlePlus}
                                    />
                                </div>
                            </div>

                        </div>
*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayerFull;
