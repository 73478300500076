import React, {Component} from 'react';
import axios from "axios";

import addPlalist from "../../../../assets/logos/addPlaylist.svg";
import cadena from "../../../../assets/logos/cadena.svg";
import playlistDefault from "../../../../assets/images/5.png";
import playlistReposte from "../../../../assets/images/2.png";
import AddPlaylist from "../../modules/AddPlaylist.jsx";
import EditPlaylist from "../../modules/EditPlaylist.jsx";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import ajouter_btn from '../../../../assets/logos/en/ajouter_btn.svg'

export default class PlaylistPlace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddPlaylist: false,
            openEdit: false,
            playlists: [],
            placeId: this.props.placeId,
            place: this.props.place,
            user: this.props.user,
            fondateur: this.props.fondateur,
            currentPlaylist:null,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        let url = import.meta.env.VITE_BACKEND_URL + "/places/playlist";
        axios.post(url,{
            places_id:this.state.placeId,
        },{
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            if(res.data[0]){
                this.setState({playlists: res.data});
            }
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    handleOpenAddPlaylist = () => {
        this.setState({openAddPlaylist: true});
    }
    handleCloseModal = () => {
        this.setState({openAddPlaylist: false, openEdit: false});
    }
    handleAddPlaylist = (playlists) => {
        this.setState({playlists: playlists, openEdit: false, openAddPlaylist: false});
    }

    handleOpenModifierPlaylist = (index) => {
        const {playlists} = this.state;
        this.setState({currentPlaylist:playlists[index],openEdit:true})
    }

    render() {
        return (
            <>
                {
                    this.state.openAddPlaylist &&
                    <AddPlaylist handleCloseModal={this.handleCloseModal} handleAddPlaylist={this.handleAddPlaylist}
                                 user={this.state.user} places={this.state.placeId}/>
                }
                {
                    this.state.openEdit &&
                    <EditPlaylist handleCloseModal={this.handleCloseModal} handleAddPlaylist={this.handleAddPlaylist}
                                  user={this.state.user} currentPlaylist={this.state.currentPlaylist}
                                  places={this.state.placeId}/>
                }
                {
                    this.state.fondateur === this.state.user.user.id &&
                    <div className="addPlaylist">
                        <img src={this.state.lang === "fr" ? addPlalist : ajouter_btn}  alt="" onClick={this.handleOpenAddPlaylist}/>
                    </div>
                }
                <div className="myPlaylist">
                    {
                        this.state.playlists[0] ?
                            this.state.playlists.map((value, index) => (
                                <div key={index} className="block_playlist">
                                    <div className="left">
                                        <a href={`/place/playlist/${value.id}`}>
                                            <img
                                                src={value.image !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image : playlistDefault}
                                                alt=""/>
                                        </a>
                                    </div>
                                    <div className="right">
                                        <h2>{value.name}</h2>
                                        {
                                            this.state.place.user.id === value.utilisateur_id ?
                                                <p>{this.state.trad.pDescriptionPlaces17}</p>
                                                :
                                                <p>{this.state.trad.pDescriptionPlaces18} {this.state.place.user.name}</p>
                                        }
                                        {
                                            this.state.user.user.id == value.utilisateur_id &&
                                            <p className="playlistModif"
                                               onClick={() => this.handleOpenModifierPlaylist(index)}>{this.state.trad.pDescriptionPlaces15}</p>
                                        }
                                    </div>
                                </div>
                            ))
                            :
                            <div className="loading-wave2">
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                                <div className="loading-bar"></div>
                            </div>
                    }
                </div>
            </>
        )
    }
}