import React from "react";
import axios from "axios";

export default class ErreurPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      type: "",
      spinner: false,
      textSpinner: this.state.trad.messageErreurPage,
    };
  }
  componentDidMount() {
    if (location.search === "?type=confirmation_compte") {
      this.setState({
        message:
        this.state.trad.messageErreurPage1,
        type: "confirm_compte",
      });
    }
  }

  handleSendMail = () => {
    let currentUser = JSON.parse(localStorage.getItem("inscription"));
    let url =
        import.meta.env.VITE_BACKEND_URL +
        "/utilisateur/reload/confirmation-compte";
    this.setState({ spinner: true });
    axios
        .post(url, {
          token_confirm: currentUser.token_confirm,
        })
        .then((res) => {
          if (res.data === 1) {
            this.setState({ spinner: false });
          } else {
            this.setState({
              textSpinner:
              this.state.trad.messageErreurPage2,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            textSpinner: this.state.trad.messageErreurPage3,
          });
        });
  };

  render() {
    return (
        <>
          <h1>{this.state.message}</h1>
          {this.state.type === "confirm_compte" && this.state.spinner ? (
              <>
                <p className="spinner">{this.state.textSpinner}</p>
              </>
          ) : (
              <>
                <p className="btn btn_envoie" onClick={this.handleSendMail}>
                  {this.state.trad.pMailConfirmation}
                </p>
              </>
          )}
        </>
    );
  }
}
