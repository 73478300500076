import axios from "axios";
import React from "react";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class ConfirmationInscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: false,
            lang: 'en',
            trad: en,
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        }

        const url = import.meta.env.VITE_BACKEND_URL + "/confirmation-de-votre-compte/" + window.location.href.split('/')[4];
        axios.get(url).then(res => {
            if (res.data == false) {
                this.setState({view: false})
            } else {
                this.setState({view: true})

            }
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <>
                {
                    this.state.view ?
                        <h1>{this.state.trad.pConfirmInscription}</h1>
                        :
                        <h1>{this.state.trad.pConfirmInscription1}</h1>
                }
            </>
        )
    }
}