import React, {Component} from 'react';
import axios from "axios";

import send from "../../../../assets/logos/send_email.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

import { LinkPreview } from '@dhaiwat10/react-link-preview';

export default class Filactu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            messages:[],
            message:"",
            placeId:this.props.placeId,
            user:this.props.user,
            fondateur:this.props.fondateur,

            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        const url = import.meta.env.VITE_BACKEND_URL + "/places/loadMessageFil/"+this.state.placeId;
        axios.get(url).then(res => {
            this.setState({messages: res.data});
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }
    sendMessage = () => {
        const {message,placeId} = this.state;
        if(message != ""){
            const url = import.meta.env.VITE_BACKEND_URL + "/places/addmessagefill";
            axios.post(url,{
                message:message,
                places_id:placeId,
            },{
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                },
            }).then(res => {
                this.setState({messages:res.data,message:""});
            }).catch(err => {
                console.log(err);
            })
        }
    }

    render(){
        return(
            <>
                <div className="block_message">
                    {
                        this.state.messages.map((value,index) =>(
                            <div key={index} className="line">
                                <div className="left">
                                    <img src={import.meta.env.VITE_BACKEND_URL_IMG + value.user.avatar} alt=""/>
                                </div>
                                <div className="right">
                                    <p className="qui">{value.user.name} <span>{this.state.fondateur == value.user.utilisateur_id ? this.state.trad.pDescriptionPlaces5 : ""}</span></p>
                                    {/*
                                        value.lien !== null &&
                                        <LinkPreview url={`${location.origin}/video/${value.lien}`}/>
                                    */}
                                    <p className="messagePlace">
                                        {value.message}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    this.state.user != null &&
                    <div className="block_sendMessage">
                        <textarea name="message" value={this.state.message} onChange={(e) => this.setState({message:e.target.value})}></textarea>
                        <img src={send} onClick={this.sendMessage} alt=""/>
                    </div>
                }
            </>
        )
    }
}