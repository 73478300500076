import React from "react";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import axios from "axios";

import add from "../../../assets/logos/ajouter_btn.svg";
import cadena from "../../../assets/logos/cadena.svg";
import replace from "../../../assets/images/5.png"
import addPlaylist from "../../../assets/images/2.png"
import ajouter_btn_en from "../../../assets/logos/en/ajouter_btn.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class BookMarke extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentaire: "",
            commentaires: [],
            idvideo: this.props.idvideo,
            user: this.props.user,
            message: "",
            style: "",
            myPlaces: [],
            myPlaylist: [],
            myPlalistSave: [],
            selectPlaylist: [],
            selectPlace: [],
            selectIndexPlaylist: [],
            selectIndexPlace: null,
            is_place: false,
            lang: 'en',
            trad: en,
            newNamePlaylist: '',
            newNamePlace: '',
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr});
                }
            });
        }
        if (this.state.user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/places/allplacesuser/";
            axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + this.state.user.token
                }
            }).then(res => {
                this.setState({myPlaces: res.data});
            }).catch(err => {
                console.log(err);
            })
            let url2 = import.meta.env.VITE_BACKEND_URL + "/playlist/get/";
            axios.get(url2, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + this.state.user.token
                }
            }).then(res => {
                res.data.map((value, index) => {
                    value.videos.map(v => {
                        if (v.videos_id === this.state.idvideo) {
                            let tab = [];
                            tab.push(value);
                            let tab2 = [];
                            tab2.push(index);
                            this.setState({selectPlaylist: tab, selectIndexPlaylist: tab2});
                        }
                    })
                })
                this.setState({myPlaylist: res.data, myPlalistSave: res.data});
            }).catch(err => {
                console.log(err);
            })

        }
    }
    handlePost = () => {
        const {selectIndexPlace, selectPlaylist, idvideo, is_place, newNamePlaylist, newNamePlace} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/playlistvideo/addplaylist";
        console.log(selectIndexPlace, selectPlaylist, newNamePlaylist, newNamePlace)
        if(selectIndexPlace !== null || selectPlaylist[0] || newNamePlaylist !== "" || newNamePlace !== ""){
            axios.post(url, {
                newNamePlaylist,
                newNamePlace,
                selectPlaylist,
                selectIndexPlace,
                idvideo,
                is_place,
            }, {
                headers: {
                    Authorization: "Bearer " + this.state.user.token,
                },
            }).then(res => {
                if (res.data) {
                    this.props.handleCloseModal();
                }
            }).catch(err => {
                console.log(err)
            });
        }else{
            this.setState({message: this.state.trad.selecteBoomarkeplaylistname, style: "warning"},() => {
                setTimeout(() => {
                    this.setState({message:"",style:""});
                },2000)
            });
        }
    }
    handlePlacesSelect = (index) => {
        if (index === "newPlace") {
            this.setState({selectIndexPlace: index,is_place: true,myPlaylist:[] })
        } else {
            if (index === "default") {
                this.setState({myPlaylist: this.state.myPlalistSave, is_place: false});
            } else {
                this.setState({
                    selectIndexPlace: index,
                }, () => {
                    let url = import.meta.env.VITE_BACKEND_URL + "/places/playlist";
                    axios.post(url, {
                        places_id: this.state.selectIndexPlace
                    }, {
                        headers: {
                            Authorization: "Bearer " + this.state.user.token,
                        },
                    }).then(res => {
                        this.setState({myPlaylist: res.data, is_place: true});
                    }).catch(err => {
                        console.log(err);
                    })
                })
            }
        }
    }
    handleChoicePlaylist = (index) => {
        let {myPlaylist, selectIndexPlaylist, selectPlaylist} = this.state;
        if (selectIndexPlaylist.includes(index)) {
            let tab2 = selectIndexPlaylist.filter(it => it !== index);
            let tab = selectPlaylist.filter(item => item.id !== myPlaylist[index].id);
            this.setState({selectPlaylist: tab, selectIndexPlaylist: tab2});
        } else {
            selectIndexPlaylist.push(index);
            selectPlaylist.push(myPlaylist[index]);
            this.setState({selectPlaylist: selectPlaylist, selectIndexPlaylist: selectIndexPlaylist});
        }
    }
    handleAddNewPlaylist = (value) => {
        console.log()
        this.setState({newNamePlaylist: value});
    }

    render() {
        return (
            <div className="modal">
                <div className="block">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    {this.state.message !== "" && (
                        <div className={`message ${this.state.style}`}>
                            <p>{this.state.message}</p>
                        </div>
                    )}
                    <h2>{this.state.trad.pSave}</h2>
                    <div className="form_connexion commentaireBlock">
                        <div className="form_control">
                            <label htmlFor="">{this.state.trad.labelPlace}</label>
                            <select name="places" onChange={(e) => this.handlePlacesSelect(e.target.value)}>
                                <option value="default">{this.state.trad.optionPlace}</option>
                                <option value="newPlace">{this.state.trad.newPlace}</option>
                                {
                                    this.state.myPlaces.map((value, index) => (
                                        <option key={index} value={value.id}>{value.name} #{value.city}</option>
                                    ))
                                }
                            </select>
                        </div>
                        {
                            this.state.selectIndexPlace === "newPlace" &&
                            <div className="form_control">
                                <input value={this.state.newNamePlace} type="text" className="input_newplace" onChange={(e) => this.setState({newNamePlace: e.target.value})}/>
                            </div>
                        }
                        <div className="form_control">
                            {
                                this.state.selectIndexPlace !== null ?
                                    <label htmlFor="">{this.state.trad.labelPlace1}</label>
                                    :
                                    <label htmlFor="">{this.state.trad.labelPlace2}</label>
                            }
                        </div>
                        <div className="myBookmarke">
                            {
                                <>
                                    <div className={`line_playlist `}>
                                        <div className="left">
                                            <img className="image" src={addPlaylist} alt=""/>
                                        </div>
                                        <div className="right">
                                            <input value={this.state.newNamePlaylist}
                                                   placeholder={this.state.trad.newPlaylist} type="text"
                                                   className="input_newplaylist"
                                                   onChange={(e) => this.handleAddNewPlaylist(e.target.value)}/>
                                        </div>
                                    </div>
                                    {
                                        this.state.myPlaylist &&
                                        this.state.myPlaylist.map((value, index) => (
                                            <div key={index}
                                                 className={`line_playlist ${this.state.selectIndexPlaylist.includes(index) ? "choice" : ""}`}
                                                 onClick={() => this.handleChoicePlaylist(index)}>
                                                <div className="left">
                                                    {
                                                        value.playlist ?
                                                            value.playlist.image !== null ?
                                                                <img className="image"
                                                                     src={import.meta.env.VITE_BACKEND_URL_IMG + value.playlist.image}
                                                                     alt=""/>
                                                                :
                                                                <img className="image" src={replace} alt=""/>
                                                            :
                                                            value.image !== null ?
                                                            <img className="image"
                                                                 src={import.meta.env.VITE_BACKEND_URL_IMG + value.image}
                                                                 alt=""/>
                                                                :
                                                                <img className="image" src={replace} alt=""/>
                                                    }

                                                    {
                                                        value.playlist &&
                                                        value.playlist.public === 1 &&
                                                        <img className="cadena" src={cadena} alt=""/>
                                                    }
                                                </div>
                                                <div className="right">
                                                    {
                                                        value.playlist ?
                                                            <h3>{value.playlist.name}</h3>
                                                            :
                                                            <h3>{value.name}</h3>

                                                    }
                                                    {
                                                        value.playlist ?
                                                            this.state.user.user.id === value.utilisateurs_id ?
                                                                <p>{this.state.trad.pDescriptionUtilisateur15}</p>
                                                                :
                                                                <p>{this.state.trad.pDescriptionPlaces18} {value.user.name}</p>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? add : ajouter_btn_en}
                                alt="se connecter"
                                className="seconnecter"
                                onClick={this.handlePost}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}