import React, {Component} from 'react';
import crossIcon from "../../../../assets/logos/crossIcon.svg";
import loupe from "../../../../assets/logos/loupe.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class ModalList extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            modalType:this.props.modalType,
            followers:this.props.followers,
            suivis:this.props.suivis,
            places:this.props.places,
            listFollow:this.props.listFollow,
            listFollowFull:this.props.listFollow,
            listFullPlaces:this.props.listFullPlaces,
            listFullPlacesFull:this.props.listFullPlaces,
            listSuivi:this.props.listSuivi,
            listSuiviFull:this.props.listSuivi,
            liste:null,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        if(this.state.modalType === "follow"){
            this.setState({liste:this.state.listFollow});
        }else if(this.state.modalType === "suivis"){
            this.setState({liste:this.state.listSuivi});
        }else if(this.state.modalType === "place"){
            this.setState({liste:this.state.listFullPlaces});
        }
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }
    handleChangeList = (value) => {
        this.setState({modalType:value},() => {
            if(value === "follow"){
                this.setState({liste:this.state.listFollowFull});
            }else if(value === "suivis"){
                this.setState({liste:this.state.listSuiviFull});
            }else{
                this.setState({liste:this.state.listFullPlacesFull});
            }
        });
    }
    handleLinkPage = (index) => {
        const {modalType,liste} = this.state;
        if(modalType === "place"){
            const url = `/place/${liste[index].id}`;
            location.assign(url);
        }else {
            const url = `/page-de-profil/${liste[index].id}`;
            location.assign(url);
        }
    }

    handleSearch = (value,type) => {

    }

    render(){
        return(
            <div className="modal invite">
                <div className="block">
                    <div className="closeModal">
                        <img
                            className="crossIcon"
                            src={crossIcon}
                            alt="crossIcon"
                            onClick={this.props.handleCloseModal}
                        />
                    </div>
                    <div className="form_connexion">
                        <div className="blockFollow full">
                            <div
                                className={`block_social ${this.state.modalType === "follow" ? "open" : ""}`}
                                onClick={() => this.handleChangeList('follow')}
                            >
                                <p className="number">{this.state.followers}</p>
                                <p className="label">{this.state.trad.creatorFollowers}</p>
                            </div>
                            <div
                                className={`block_social ${this.state.modalType === "suivis" ? "open" : ""}`}
                                onClick={() => this.handleChangeList('suivis')}
                            >
                                <p className="number">{this.state.suivis}</p>
                                <p className="label">{this.state.trad.creatorFollow}</p>
                            </div>
                            <div
                                className={`block_social ${this.state.modalType === "place" ? "open" : ""}`}
                                onClick={() => this.handleChangeList('place')}
                            >
                                <p className="number">{this.state.places}</p>
                                <p className="label">{this.state.trad.headerNavSub7}</p>
                            </div>
                        </div>
                        <div className={`search ${this.state.modalType === "place" ? "listePlace" :""}`}>
                            {
                                this.state.modalType === "place" ?
                                    <div className="form_control"
                                         onChange={(e) => this.handleSearch(e.target.value, 'name')}>
                                        <span className="at">R</span>
                                        <input id="searchText" type="text"/>
                                        <img src={loupe} alt=""/>
                                    </div>
                                    :
                                    <>
                                        <div className="form_control"
                                             onChange={(e) => this.handleSearch(e.target.value, 'name')}>
                                            <span className="at">@</span>
                                            <input id="searchText" type="text"/>
                                            <img src={loupe} alt=""/>
                                        </div>
                                        <div className="form_control">
                                            <select name="" id="searchSelect" onChange={(e) => this.handleSearch(e.target.value, 'creator')}>
                                                <option value="tous">Tous</option>
                                                <option value="auditor">{this.state.trad.pDescriptionUtilisateur7}</option>
                                                <option value="creator">{this.state.trad.creator}</option>
                                            </select>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="liste">
                            {
                                this.state.liste !== null &&
                                this.state.liste.map((value, index) => {
                                    return (
                                        <div key={index} className={`line ${this.state.modalType === "place" ? "placesList" : ""}`} onClick={() => this.handleLinkPage(index)}>
                                            <div className="left">
                                                <img src={`${import.meta.env.VITE_BACKEND_URL_IMG}${value.personal ? value.personal.avatar : value.image_place}`} alt=""/>
                                            </div>
                                            <div className="right">

                                                 <p>{value.personal ? value.personal.name : value.name}</p>
                                                {
                                                    !value.personal &&
                                                    <p>#{value.city} {value.country}</p>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}