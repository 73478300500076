import React from "react";
import axios from "axios";

import VideoPlayerSolo from "../modules/VideoPlayerSolo.jsx";
import VideoPlayerFullSolo from "../modules/VideoPlayerFullSolo.jsx";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class VideoSolo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: null,
            videosToBuy: [],
            videosBought: [],
            playVideoId: 0,
            user: null,
            page: 1,
            full: false,
            indexVideo: 0,
            currentTime: 0,
            lang:'en',
            trad:en,
            openModal: false,
            openModalCom: false,
            openModalBook: false,
            openModalFollow: false,
        }
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
        document.querySelector("body").classList.add("home");
        document.querySelector(".img_cover").style.display = "none";
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            })
        }
        let token = location.pathname.split('/');
        token = token[2];
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/getSoloVideo";
        axios.post(url, {
            token: token,
        }).then(res => {

            let video = {};
            let mini = res.data.miniature;
            video.id = res.data.id;
            video.isPlay = false;
            video.src = import.meta.env.VITE_BACKEND_URL_IMG + res.data.path_nocompres;
            video.type = "video/mp4";
            if (res.data.miniature != null) {
                video.poster = import.meta.env.VITE_BACKEND_URL_IMG + mini;
            }
            video.artistPhoto = import.meta.env.VITE_BACKEND_URL_IMG + res.data.utilisateur.avatar;
            video.artistName = `${res.data.utilisateur.lastname !== null ? res.data.utilisateur.lastname : ""} ${res.data.utilisateur.firstname !== null ? res.data.utilisateur.firstname : ""}`;
            video.worldArtiste = res.data.info && res.data.info.upload_artistWord ? res.data.info.upload_artistWord.replace(/\n/g, '<br />') : "";
            video.otherInfo = res.data.info && res.data.info.upload_otherInfos ? res.data.info.upload_otherInfos.replace(/\n/g, '<br />') : "";
            video.artistTitle = res.data.utilisateur.nom_artiste;
            video.artistDescription = res.data.utilisateur.biographie;
            video.artistMoreDetails = "Plus d’informations…";
            video.hasLiked = res.data.hasLiked;
            video.hasDisLiked = res.data.hasDisLiked;
            video.isLiked = res.data.isLiked;
            video.title = res.data.title;
            video.isCommented = res.data.isCom;
            video.isBoorkmarked = res.data.book;
            video.isBooked = res.data.booked;
            video.isFollowed = res.data.share;
            video.genreIcon = [];
            video.token = res.data.token;
            video.userId = res.data.utilisateur_id;
            //video.videosToBuy = []
            video.videosToBuy = [
                {startTime: 0, endTime: 5, label: this.state.trad.pDescriptionPlaces20},
                {startTime: 20, endTime: 25, label: this.state.trad.pDescriptionPlaces20},
                {startTime: 50, endTime: 55, label: this.state.trad.pDescriptionPlaces20},
            ];
            //video.videosBought = []
            video.videosBought = [
                {startTime: 40, endTime: 45, label: this.state.trad.pDescriptionPlaces21},
                {startTime: 130, endTime: 135, label: this.state.trad.pDescriptionPlaces21},
            ];
            this.setState({video: video});

        }).catch(err => {
            console.log(err);
        })
        localStorage.setItem("link", location.href);
    }

    handleCallBackFullScreen = (value, indexVideo, time,type = null) => {
        if (value) {
            this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)});
        } else {
            if (type === "like") {
                this.setState({openModal: true}, () => {
                    this.setState({full: value, indexVideo: indexVideo, currentTime: parseInt(time)}, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                });
            } else if (type === "comm") {
                this.setState({openModalCom: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }else if (type === "book") {
                this.setState({openModalBook: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }else if (type === "follow") {
                this.setState({openModalFollow: true}, () => {
                    this.setState({
                        full: value,
                        indexVideo: indexVideo,
                        currentTime: parseInt(time)
                    }, () => {
                        setTimeout(() => {
                            let video = document.getElementById('video_' + indexVideo);
                            const heightVideo = video.getBoundingClientRect().top - 50;
                            //console.log(heightVideo)
                            window.scroll(0, heightVideo);
                        }, 100)
                    });
                })
            }
        }
    }
    callBackFreshPage = () => {
        window.location.reload(true);
    }

    render() {
        return (
            <>
                <div className="content">
                    <div className="img_cover"></div>
                    <div className={`home homepageGlobal `}>
                        {this.state.full ?
                            <div className="fullscreen">
                                <VideoPlayerFullSolo
                                    indexVideo={0}
                                    id={`video_0`}
                                    key={this.state.indexVideo}
                                    video={this.state.video}
                                    infos={false}
                                    handleCallBackPlay={this.handleCallBackPlay}
                                    handleCallBackFullScreen={this.handleCallBackFullScreen}
                                    handleFullScreenChangeVideo={this.handleFullScreenChangeVideo}
                                    currentTime={this.state.currentTime}
                                    user={this.state.user}
                                />
                            </div>
                            :
                            this.state.video !== null ?
                                <VideoPlayerSolo
                                    indexVideo={0}
                                    id={`video_0`}
                                    video={this.state.video}
                                    videosToBuy={this.state.video}
                                    videosBought={this.state.video}
                                    handleCallBackFullScreen={this.handleCallBackFullScreen}
                                    callBackFreshPage={this.callBackFreshPage}
                                    infos={false}
                                    currentTime={this.state.currentTime}
                                    user={this.state.user}
                                    trad={this.state.trad}
                                    openModal={this.state.openModal}
                                    openModalCom={this.state.openModalCom}
                                    openModalBook={this.state.openModalBook}
                                    openModalFollow={this.state.openModalFollow}
                                />
                                :
                                <>
                                    <h1>{this.state.trad.pDescriptionPlaces22}</h1>
                                    <div className="loading-wave">
                                        <div className="loading-bar"></div>
                                        <div className="loading-bar"></div>
                                        <div className="loading-bar"></div>
                                        <div className="loading-bar"></div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }


}