import React, {Component} from 'react';
import axios from "axios";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import dot from "../../../../assets/logos/dot_horizontal.svg";
import playlist from "../../../../assets/images/1.png";

import background from "../../../../assets/backgrounds/backgroundCompte.jpg"

export default class PlacesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            places: [],
            placesFull: [],
            lang: 'en',
            trad: en,
            user:null,
        }
    }

    componentDidMount() {
        let bg = document.querySelector('.img_cover');
        bg.style.backgroundImage = `url(${background})`;
        bg.style.height = "";
        bg.style.display = "block";
        document.querySelector("body").classList.add("explorerListPlaces");
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if (lang === "fr") {
                    this.setState({lang: "fr", trad: fr}, () => {
                    });
                }
                const url = import.meta.env.VITE_BACKEND_URL + '/explorer/places';
                axios.get(url, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer ' + this.state.user.token
                    }
                }).then(res => {
                    console.log(res.data);
                    this.setState({places: res.data, placesFull: res.data});
                }).catch(err => {
                    console.log(err);
                })
            });
        }
    }
    handleSearch = (value) => {
        const {placesFull} = this.state;
        let newPlace = placesFull.filter(pf => pf.name.toLowerCase().includes(value.toLowerCase()));
        this.setState({places:newPlace});
    }

    render() {
        return (
            <>
                <h1>{this.state.trad.explorerPlaceTitle}</h1>
                <div className="form-controll">
                    <input type="text" placeholder="Rechercher par nom" onChange={(e) => this.handleSearch(e.target.value)} />
                </div>
                <div className="playlists">
                    {
                        this.state.places[0] &&
                        this.state.places.map((value, index) => (
                            <div className={`playlist`} key={index}>
                                <div className="menu">
                                    <img onClick={() => this.handleOpenMenuDot(index)} src={dot} alt=""/>
                                    <ul id={`menu_playlist_${index}`}>
                                        <li onClick={() => this.handleLink("bug", index)}>{this.state.trad.pDescriptionUtilisateur3}</li>
                                        <li onClick={() => this.handleLink("contenu", index)}>{this.state.trad.pDescriptionUtilisateur4}</li>
                                        <li onClick={() => this.handleLink("save", index)}>{this.state.trad.pDescriptionUtilisateur5}</li>
                                        <li onClick={() => this.handleLink("share", index)}>{this.state.trad.pDescriptionUtilisateur6}</li>
                                    </ul>
                                </div>
                                <div className="img">
                                    <img
                                        src={value.image_place !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.image_place : playlist}
                                        alt=""/>
                                </div>
                                <div className="text">
                                    <a href={`/place/${value.id}`}>
                                        <p>{value.name}</p>
                                        <p>{this.state.trad.pDescriptionPlaces18} {value.personal.name}</p>
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }
}
