import { useState,useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./resources/js/components/Header";
import Footer from "./resources/js/components/Footer";
import AllRoutes from "./resources/AllRoutes";


function App() {


    useEffect(() => {

        let path = document.location.pathname;
        path = path.split('/');
        if(path[1] !== "gestion-de-ma-page" && path[1] !== "mes-places"){
            document.querySelector('.img_cover').style.backgroundImage = "none";
        }
    });



  return (
    <div className="App">
      <Router>
        <Header />
        <div className="content">
            <div className="img_cover"></div>
          <AllRoutes />
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
