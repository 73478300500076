import React from "react";

import NavigateButton from "../../modules/NavigateButton";
import CompteNavMenu from "../../modules/CompteNavMenu";

import changeValidButton from "../../../../assets/logos/changeValidButton.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';
import changeValidButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";

export default class CompteSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_number: "",
      verify_phone_number: "",
      email: "",
      verify_email: "",
      lat:null,
      long:null,
      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    document.title = "Compte | Security | Wonderplace";
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          lat:position.coords.latitude,
          long:position.coords.longitude,
        })
      });
    }
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }
  }

  render() {
    return (
      <div className="compteSecurityGlobal">
        <div className="compteHeader">
          <NavigateButton trad={this.state.trad}/>
          <h1>{this.state.trad.settingsHeading}</h1>
        </div>

        <div className="compteSecurityContainer">
          <CompteNavMenu trad={this.state.trad}/>

          <div className="security">
            <div className="account_security">
              <h2>{this.state.trad.accountSecurity}</h2>

              <div className="form_control">
                <label htmlFor="phone_number">{this.state.trad.labelPhoneNumber}</label>
                <input
                  type="tel"
                  id="phone_number"
                  name="phone_number"
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  value={this.state.phone_number} /////// A FAIRE  //////
                  onChange={
                    (e) => this.setState({ phone_number: e.target.value }) /////// A FAIRE  //////
                  }
                />
              </div>

              <div className="form_control">
                <label htmlFor="verify_phone_number">{this.state.trad.labelVerifyPhoneNumber}</label>
                <input
                  type="tel"
                  id="verify_phone_number"
                  name="verify_phone_number"
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  value={this.state.verify_phone_number} /////// A FAIRE  //////
                  onChange={
                    (e) =>
                      this.setState({ verify_phone_number: e.target.value }) /////// A FAIRE  //////
                  }
                />
              </div>

              <div className="form_control">
                <label htmlFor="email">{this.state.trad.labelEmail}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.email} /////// A FAIRE  //////
                  onChange={(e) => this.setState({ email: e.target.value })} /////// A FAIRE  //////
                />
              </div>

              <div className="form_control">
                <label htmlFor="verify_email">{this.state.trad.labelVerifyEmail}</label>
                <input
                  type="email"
                  id="verify_email"
                  name="verify_email"
                  value={this.state.verify_email} /////// A FAIRE  //////
                  onChange={(e) =>
                    this.setState({ verify_email: e.target.value })
                  } /////// A FAIRE  //////
                />
              </div>
            </div>

            <div className="account_activity">
              <h2>{this.state.trad.accountActivity}</h2>
              {
                this.state.lat !== null ?
                    <p>{this.state.trad.latitude}: {this.state.lat}, Longitude: {this.state.long}</p>
                    :
                    <p>{this.state.trad.messageLocation}</p>
              }
            </div>

            <div className="form_submit">
              <img
                  src={this.state.lang === "fr" ? changeValidButton : changeValidButtonen}
                alt="changeValidButton"
                className="changeValidButton"
                onClick={() => this.handleInscription(2)} /////// A FAIRE  //////
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
