import React, {Component} from 'react';
import dot from "../../../../assets/logos/dot_horizontal.svg";
import playlist from "../../../../assets/images/2.png";
import chevron from "../../../../assets/logos/chevron.svg";
import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';


export default class Creators extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            creators:this.props.creators,
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }

    render(){
        return(
            <div className="playlists">
                <h2>{this.state.trad.pDescriptionUtilisateur} <img style={{marginLeft: "8px"}} src={chevron} alt=""/></h2>
                {
                    this.state.creators.map((value,index) => (
                        <div className={`playlist`} key={index}>

                            <div className="img">
                                <img src={value.image_place !== null ? import.meta.env.VITE_BACKEND_URL_IMG + value.perso.avatar : playlist }
                                     alt=""/>
                            </div>
                            <div className="text creatorsFav">
                                <a href={`/page-de-profil/${value.id}`}>
                                    <p>{value.nom_artiste}</p>
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

}