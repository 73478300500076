import { Link, Routes, Route, useNavigate } from "react-router-dom";
import retourArrow from "../../../assets/logos/retourArrow.svg";

export default function ReturnButton() {
  const navigate = useNavigate();

  return (
    <div>
      <button className="retour" onClick={() => navigate(-1)}>
        <img
          src={retourArrow}
          className="retourArrowButton"
          alt="retourArrowButton"
        />
        <label>RETOUR</label>
      </button>
      {/* <button onClick={() => navigate(1)}>Go forward</button> */}

      {/* <div> */}
      {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav> */}

      {/* 👇️ Wrap your Route components in a Routes component */}
      {/* <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
        </Routes> */}
      {/* </div> */}
    </div>
  );
}

// function Home() {
//   return <h2>Home</h2>;
// }

// function About() {
//   return <h2>About</h2>;
// }
