import React, {Component} from 'react';
import axios from "axios";
import {bannerSelect} from "../../modules/Helper.jsx";
import {Link} from "react-router-dom";

import play_compte from "../../../../assets/logos/play_compte.png";
import uploadVideo from "../../../../assets/logos/uploadVideo.svg";
import edit from "../../../../assets/logos/edit.svg";

import repac from "../../../../assets/logos/repac.svg";
import reposts from "../../../../assets/logos/reposts.svg";
import video from "../../../../assets/logos/video.svg";
import playlist from "../../../../assets/logos/playlist.svg";
import instant from "../../../../assets/logos/instant.svg";
import eye from "../../../../assets/logos/eye.svg";
import trash from "../../../../assets/logos/trash.svg";
import crossIcon from "../../../../assets/logos/crossIcon.svg";
import background from "../../../../assets/backgrounds/backgroundCompte.jpg";
import vignette from "../../../../assets/images/vignette1.jpg";
import Reposts from "../utilisateur/reposts.jsx";
import Playlist from "../utilisateur/Playlist.jsx";
import EpingleUser from "../../modules/EpingleUser.jsx";
import LastLike from "../utilisateur/LastLike.jsx";
import RecapPlaylist from "../utilisateur/RecapPlaylist.jsx";
import RepostsLine from "../utilisateur/RepostsLine.jsx";
import LastPlaces from "../utilisateur/LastPlaces.jsx";
import Creators from "../utilisateur/Creators.jsx";
import SmallModal from "../../modules/SmallModal.jsx";
import ModalList from "../utilisateur/ModalList.jsx";
import upload from "../../../../assets/logos/en/uploaden.svg";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

const trashStyle = {
    width: 'auto',
    height: '20px',
    cursor: "pointer",
};

export default class PageCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: "",
            cover: "",
            userName: "",
            artisteName: "",
            biographie: "",
            selectedOption: "",
            creator_mod: false,
            creator_verif: false,
            styleImageBackGround: null,
            height: screen.height + 2,


            followers: 0,
            suivis: 0,
            places: 0,
            reposts: 0,
            instants: 0,

            type: "recap",
            videos: null,
            flashMessage: "",
            typeFlash: "",
            currentVideo:null,

            //MODAL
            currentPlaylist: null,
            modalListFollow:false,
            modalType:"",

            lang:'en',
            trad:en,
        }
    }

    async componentDidMount() {
        document.title = "Ma page | Wonderplace";
        document.querySelector("body").classList.add("pageother");
        if (localStorage.getItem('storageType')) {
            if (localStorage.getItem('message')) {
                let message = localStorage.getItem('message');
                message = message.split(':');
                this.setState({
                    flashMessage: message[1],
                    typeFlash: message[0],
                }, () => {
                    localStorage.removeItem('message');
                    setTimeout(() => {
                        this.setState({
                            flashMessage: "",
                            typeFlash: "",
                        });
                    },2000);
                })
            }
            let user = localStorage.getItem('user');
            if (user !== null) {
                let userConnect = JSON.parse(user);
                if (JSON.parse(localStorage.getItem("user"))) {
                    this.setState({
                        user: JSON.parse(localStorage.getItem("user")),
                    }, () => {
                        let lang = this.state.user.user.language;
                        if(lang === "fr"){
                            this.setState({lang:"fr",trad:fr});
                        }
                    });
                }
                await this.setState({
                    user: userConnect,
                }, () => {
                    this.getData();
                })
            } else {
                location.assign('/');
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize = () => {
        this.setState({
            height: screen.height + 2
        })
    }
    getData = () => {
        let {user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/compte/compteUserLoad";
        axios.post(url, {
            data: "load",
        }, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(res => {
            if(res.data.personalisation !== null){
                this.setState({
                    avatar: res.data.personalisation.avatar,
                    cover: res.data.personalisation.cover,
                    userName: res.data.name,
                    biographie: res.data.personalisation.biographie,
                    selectedOption: res.data.personalisation.lang,
                    name: res.data.name,
                    creator_mod: res.data.creator === 1 ? true : false,
                    creator_verif: res.data.creator_verif === 1 ? true : false,
                    artisteName: res.data.nom_artiste,
                    playlists: res.data.playlists,
                    shares: res.data.share,
                    epingles: res.data.epingle,
                    likevideo: res.data.likeVideo,
                    suivie: res.data.folow !== null ? true : false,
                    suivis: res.data.suivis,
                    followers: res.data.follows,
                    places: res.data.places,
                    reposts: res.data.reposts,
                    lastPlaces: res.data.lastPlaces,
                    lastCreators: res.data.lastCreators,
                    instants: 0,
                    listSuivi:res.data.listSuivi,
                    listFollow:res.data.listFollow,
                    listFullPlaces:res.data.listFullPlaces,
                }, () => {
                    let urlImage = "";
                    if(res.data.personalisation.cover !== null){
                        urlImage = `url(${import.meta.env.VITE_BACKEND_URL_IMG + res.data.personalisation.cover})`;
                    }else{
                        urlImage = `url(${background})`;
                    }
                    let content = document.querySelector('.content');
                    bannerSelect(urlImage);
                    content.classList.add('pageCreateur');
                    user.user.creator = res.data.creator;
                    user.user.creator_verif = res.data.creator_verif;
                    this.setState({
                        user,
                        styleImageBackGround:urlImage
                    }, () => {
                        localStorage.setItem('user', JSON.stringify(user));
                    })

                })
            }else{
                location.assign('/inscription/information')
            }
        }).catch(error => {
            console.log(error);
        })
    }
    handleOpenBoard = (type) => {
        this.setState({type}, () => {
            if (type === "video") {
                const url = import.meta.env.VITE_BACKEND_URL + "/creator/video";
                axios.get(url, {
                    headers: {
                        Authorization: "Bearer " + this.state.user.token,
                    },
                }).then(res => {
                    this.setState({videos: res.data});
                }).catch(err => {
                    console.log(err);
                })
            }
        });
    }
    handleEditVideo = async (index) => {
        let video = this.state.videos[index];
        await localStorage.setItem('currentVideo', JSON.stringify(video));
        await localStorage.setItem('editvideo', 'editvideo');
        location.assign('/gestion-de-ma-page/upload-video');
    }
    handleOpenModalDelete = (index) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        const {videos} = this.state;
        this.setState({currentVideo:videos[index]});
    }
    handleCloseModalDelete = () =>{
        this.setState({currentVideo:null,});
    }
    handleDeleteVideo = () => {
        const {currentVideo} = this.state;
        const url = import.meta.env.VITE_BACKEND_URL + "/creator/delete";
        axios.post(url, {
            id: currentVideo.id
            }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: 'Bearer ' + this.state.user.token
                },
        }).then(res => {
            this.setState({videos: res.data,currentVideo:null,});
        }).catch(err => {
            console.log(err)
        })
    }
    handleCloseModal = () => {
        this.setState({modalListFollow:false});
    }
    handleOpenList = (type) => {
        if (type === "follow" || type === "suivis" || type === "place") {
            this.setState({modalListFollow:true,modalType:type});
        }else{
            this.setState({type});
        }
    }

    render() {
        return (
            <>
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                {
                    this.state.styleImageBackGround !== null &&
                    <>
                        <div className="block_presentation">
                            <div className="block_header_creator">
                                <div className="block_img">
                                    <img className="avatar_creator square"
                                         src={import.meta.env.VITE_BACKEND_URL_IMG + this.state.user.user.avatar}
                                         alt=""/>
                                    <div className="play_block">
                                        <a href={`/shuffle/${this.state.user.user.id}`}>
                                            <img src={play_compte} alt=""/>
                                            <p>{this.state.trad.creatorReadRandomly}</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="block_info">
                                    <div className="line_1">
                                        <div className="left">
                                            <h2>{this.state.artisteName}</h2>
                                        </div>
                                        <div className="right">
                                            <Link to="/gestion-de-ma-page/upload-video">
                                                <img
                                                    src={this.state.lang === "fr" ? uploadVideo : upload}
                                                    className="uploadVideoLogo"
                                                    alt="uploadVideoLogo"
                                                />
                                            </Link>
                                            <Link to="/compte">
                                                <img src={edit} alt=""/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="line_2">
                                        <div className="social_wonder">
                                            <p onClick={() => this.handleOpenList('follow')}>{this.state.followers}<br/><span>{this.state.trad.creatorFollowers}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('suivis')}>{this.state.suivis}<br/><span>{this.state.trad.creatorFollow}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('place')}>{this.state.places}<br/><span>{this.state.trad.headerNavSub7}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('reposts')}>{this.state.reposts}<br/><span>{this.state.trad.creatorRepost}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('instant')}>{this.state.instants}<br/><span>{this.state.trad.creatorMoments}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="line_3">
                                        <h2>{this.state.trad.creator}</h2>
                                        <p>{this.state.biographie}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_content_page">
                            <nav>
                                <ul>
                                    <li className={this.state.type === "recap" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('recap')}><img src={repac} alt=""/>
                                        <p>{this.state.trad.creatorText1}</p></li>
                                    <li className={this.state.type === "video" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('video')}><img src={video} alt=""/>
                                        <p>{this.state.trad.creatorText2}</p></li>
                                    <li className={this.state.type === "reposts" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('reposts')}><img src={reposts} alt=""/>
                                        <p>{this.state.trad.creatorRepost}</p></li>
                                    <li className={this.state.type === "playlist" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('playlist')}><img src={playlist} alt=""/>
                                        <p>{this.state.trad.creatorText3}</p></li>
                                    <li className={this.state.type === "instant" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('instant')}><img src={instant} alt=""/>
                                        <p>{this.state.trad.creatorMoments}</p></li>
                                </ul>
                            </nav>
                        </div>
                        <div className={`board ${this.state.type}`}>
                            {
                                this.state.type === "recap" &&
                                <>
                                    {
                                        this.state.epingles[0] &&
                                        <EpingleUser callBackEpingle={this.callBackEpingle} epingles={this.state.epingles}
                                                     user={this.state.user}/>
                                    }
                                    {
                                        this.state.likevideo[0] &&
                                        <LastLike id={this.state.id} videos={this.state.likevideo}/>
                                    }
                                    {
                                        this.state.playlists[0] &&
                                        <RecapPlaylist callBackPlaylist={this.callBackPlaylist}
                                                       playlists={this.state.playlists}/>
                                    }
                                    {
                                        this.state.shares[0] &&
                                        <RepostsLine shares={this.state.shares}/>
                                    }
                                    {
                                        this.state.lastPlaces[0] &&
                                        <LastPlaces lastPlaces={this.state.lastPlaces}/>
                                    }
                                    {
                                        this.state.lastCreators[0] &&
                                        <Creators creators={this.state.lastCreators}/>
                                    }
                                </>
                            }
                            {
                                this.state.type === "video" &&
                                <div className="list_video">
                                    {
                                        this.state.videos !== null ?
                                            this.state.videos.map((value, index) => (
                                                <div className="block_video" key={index}>
                                                    <div className="img" onClick={() => this.handleEditVideo(index)}>
                                                        {
                                                            value.miniature !== null ?
                                                                <img
                                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + value.miniature}
                                                                    alt=""/>
                                                                :
                                                                <img src={vignette} alt=""/>
                                                        }
                                                        {
                                                            !value.complete &&
                                                            <div className="no_complete">
                                                                <p>{this.state.trad.creatorText4}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="info_video">
                                                        <p> {value.title} <span><img
                                                            onClick={() => this.handleOpenModalDelete(index)}
                                                            style={trashStyle}
                                                            src={trash} alt=""/> <img src={eye}
                                                                                      alt=""/>{value.id}</span>
                                                        </p>
                                                        <p>{this.state.artisteName}</p>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className="loading-wave2">
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                    }
                                </div>
                            }
                            {
                                this.state.type === "reposts" &&
                                <Reposts user={this.state.user} id={this.state.user.user.id}/>
                            }
                            {
                            this.state.type == "playlist" &&
                                <Playlist user={this.state.user}/>
                            }
                            {
                                this.state.type == "instant" &&
                                <p>{this.state.trad.creatorText5}</p>
                            }
                        </div>
                    </>
                }
                {
                    this.state.currentVideo !== null &&
                    <div className="modal_small">
                        <div className="block">
                            <p>{this.state.trad.creatorText5}<br/> {this.state.currentVideo.title}</p>
                            <p>{this.state.trad.creatorText7}</p>
                            <div className="action">
                                <button onClick={this.handleCloseModalDelete} >{this.state.trad.creatorText8}</button>
                                <button onClick={this.handleDeleteVideo}>{this.state.trad.creatorText9}</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.modalListFollow &&
                    <ModalList
                        handleCloseModal={this.handleCloseModal}
                        followers={this.state.followers}
                        listFollow={this.state.listFollow}
                        listFullPlaces={this.state.listFullPlaces}
                        places={this.state.places}
                        listSuivi={this.state.listSuivi}
                        suivis={this.state.suivis}
                        modalType={this.state.modalType}
                    />
                }
            </>
        );
    }
}

